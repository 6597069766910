/**
 * Rosterfy Helper
 **/

/**
 * API Connect
 *
 * Make a call to the API
 * 
 * @param   {String} endpoint       The Ortto API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * 
 * @return  {Object}                {response, status}
 *
    import { rosterfy } from '../helpers/scout'

    scoutApi('endpoint', 'POST', bodyObject).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */

    async function rosterfyApi(endpoint, method, body) {
        const options = {
            method: method ? method : 'GET'
        };
        
        if (body) {
            let bodyString = body;
            if (typeof body === 'object') {
            bodyString = JSON.stringify(body);
            }
            options.body = bodyString;
        }
        
        const parseJson = async response => {
            const text = await response.text();
            try {
            const json = JSON.parse(text);
            return json;
            } catch (err) {
            return text;
            }
        };
        
        const encodedEndpoint = Buffer.from(endpoint).toString('base64');
        
        return await fetch(
            `${process.env.LAMBDA_PATH}rosterfy?endpoint=${encodedEndpoint}`,
            options
        ).then(async res => ({ response: await parseJson(res), status: res.status }));
    }
          
    async function getFutureEvents() {

        const date = new Date();
        const dateToday = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
        // const dateToday = `${date.getFullYear()}-3-${date.getDate()}`; // for testing only
        
        const filter1 = `filter[0][attr]=end_timestamp&filter[0][operator]=>=&filter[0][value]=${dateToday}`;
        // const filter2 = `filter[1][attr]=end_timestamp&filter[1][operator]=>=&filter[1][value]=${dateToday}`;

        // https://rosterfyapiv2.docs.apiary.io/#introduction/example-api-requests
        return rosterfyApi(`/event?sortBy=start_timestamp&sortOrder=asc&${filter1}`).then(response => {
            
            const eventList = response?.response.map((vEvent) => {
                return({
                name: vEvent.object.name,
                id: vEvent.object.id,
                url: `https://usuvteam.rosterfy.co/portal/event/${vEvent.object.id}/view`,
                description: vEvent.object.description,
                start_timestamp: vEvent.object.start_timestamp,
                end_timestamp: vEvent.object.end_timestamp,
                timezone: vEvent.object.timezone,
                address: vEvent.object.address,
                address_text: vEvent.nice?.address_oneline,
                message: vEvent.nice?.apply?.message,
                image: vEvent.object.grid_image,
                // shifts: shiftList[index],
                })
            });

            return eventList;

        });
    }

    async function getShiftList(eventId, userId) {
        // https://rosterfyapiv2.docs.apiary.io/#introduction/example-api-requests
        return rosterfyApi(`/event/${eventId}/shift`).then(async response => {

            const shiftIdListPromise = response.response.map(
                (shiftResponseObject) => checkUserStatus(eventId, shiftResponseObject.object.id, userId)
            );

            const shiftStatusList = await Promise.all(shiftIdListPromise).then((shiftStatusResponses) => {
                return shiftStatusResponses;
            });

            const shiftList = response?.response.map((shiftResponseObject, index) => {
                return {
                    shift_id: shiftResponseObject.object.id,
                    event_id: eventId,
                    date: shiftResponseObject.nice.start_timestamp__date,
                    start_timestamp: shiftResponseObject.nice.start_timestamp__time,
                    end_timestamp: shiftResponseObject.nice.end_timestamp__time,
                    requirements: shiftResponseObject.object.shift_requirements,
                    meeting_point: shiftResponseObject.object.meeting_point,
                    status: shiftStatusList[index],
                    start_meta_timestamp: shiftResponseObject.object.start_timestamp,
                }
            })

            try {
                shiftList.sort((a, b) => {
                    // possible line failure
                    return new Date(a.start_meta_timestamp) - new Date(b.start_meta_timestamp);
                })
                return shiftList
            } catch {
                return shiftList;
            }
        });
    }

    // Problematic function - Dom; 
    // Im noticing slow responses from Rosterfy with parallel request to the SHIFT endpoint
    // Possible reason for transferring this to a build process 
    async function getFutureEventsWithShifts() {

        const date = new Date();
        const dateToday = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
        // const dateToday = `2022-6-${date.getDate()}`; // testing
        
        // https://rosterfyapiv2.docs.apiary.io/#introduction/example-api-requests
        return rosterfyApi(`/event?sortBy=start_timestamp&sortOrder=asc&filter[0][attr]=start_timestamp&filter[0][operator]=>=&filter[0][value]=${dateToday}`).then(response => {

            const shiftPromises = response?.response.map(event => getShiftList(event.object.id));
            return Promise.all(shiftPromises).then((shiftResponses) => {
                
                // construct shifts - parallel request to rosterfy
                const shiftList = shiftResponses.map((shiftResponse) => {
                    return shiftResponse.response.map((shiftResponseObject) => {
                        console.log(shiftResponseObject);
                        return {
                            id: shiftResponseObject.object.id,
                            // event_id: vEvent.object.id,
                            start_timestamp: shiftResponseObject.nice.start_timestamp__time,
                            end_timestamp: shiftResponseObject.nice.end_timestamp__time,
                            requirements: shiftResponseObject.object.shift_requirements,
                            meeting_point: shiftResponseObject.object.meeting_point,
                        }
                    })
                })

                //construct events
                const eventList = response?.response.map((vEvent, index) => {
                    return({
                    name: vEvent.object.name,
                    id: vEvent.object.id,
                    url: `https://usuvteam.rosterfy.co/portal/event/${vEvent.object.id}/view`,
                    description: vEvent.object.description,
                    start_timestamp: vEvent.object.start_timestamp,
                    end_timestamp: vEvent.object.end_timestamp,
                    timezone: vEvent.object.timezone,
                    address: vEvent.object.address,
                    address_text: vEvent.nice?.address_oneline,
                    message: vEvent.nice?.apply?.message,
                    image: vEvent.object.grid_image,
                    shifts: shiftList[index],
                    })
                });

                return eventList;
            })

        });
    }

    async function applyUser(eventId, shiftId, userId) {
        // https://rosterfyapiv2.docs.apiary.io/#introduction/example-api-requests
        return rosterfyApi(`/event/${eventId}/shift/${shiftId}/user`, 'POST', {
            user_id: userId,
            event_shift_status_id: 1,
        }).then(response => {
            if(response?.response.error) return false;
            else return true;
        }).catch(() => {
            return false;
        });
    }

    async function searchUser(studentId, email) {
        
        // https://rosterfyapiv2.docs.apiary.io/#introduction/example-api-requests
        // return rosterfyApi(`/user?filter[0][attr]=student_number&filter[0][operator]==&filter[0][value]=${studentId}`).then(response => {
        //     return response;
        //     // console.log(response);
        // });

        const searchEmail = rosterfyApi(`/user?filter[0][attr]=email&filter[0][operator]==&filter[0][value]=${email}`);
        const searchUsuMemberId = rosterfyApi(`/user?filter[0][attr]=what_is_your_usu_member_number&filter[0][operator]==&filter[0][value]=${studentId}`);

        const rosterfyUser = await Promise.all([searchEmail, searchUsuMemberId]).then((response) => {
            if(response[0]?.response.length === 1) return response[0];
            if(response[1]?.response.length === 1) return response[1];

            return undefined;
        }).catch(() => {
            return undefined;
        })

        return rosterfyUser

        // const x = rosterfyApi(`/user?filter[0][attr]=email&filter[0][operator]==&filter[0][value]=${email}`).then(response => {
        //     return response;
        //     // console.log(response);
        // });

        // return rosterfyApi(`/user?filter[0][attr]=what_is_your_usu_member_number&filter[0][operator]==&filter[0][value]=${studentId}`).then(response => {
        //     return response;
        //     // console.log(response);
        // });
    }

    async function checkUserStatus(eventId, shiftId, userId) {
        return rosterfyApi(`/event/${eventId}/shift/${shiftId}/user?filter[0][attr]=user_id&filter[0][operator]==&filter[0][value]=${userId}`).then(response => {
            if( Array.isArray(response?.response)) {
                if(response?.response.length === 0) return 0; // can apply
                else return response?.response[0].object.event_shift_status_id;
            }
        });
    }

    async function getShiftDetails(eventId, shiftId) {
        return rosterfyApi(`/event/${eventId}/shift/${shiftId}`).then(response => {
            return response;
        });
    }

    async function getUserSchedule(userId) {
        // const dateToday = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
        // const dateToday = `${date.getFullYear()}-3-${date.getDate()}`;

        return rosterfyApi(`event/shift/user?filter[0][attr]=user_id&filter[0][operator]==&filter[0][value]=${userId}`).then(async response => {
            const shiftList = response.response.map((userShiftObject) => {
                return {
                    status: userShiftObject.object.event_shift_status_id,
                    shift_id: userShiftObject.object.event_shift_id,
                    event_id: userShiftObject.nice.event_id,
                    event_name: userShiftObject.nice.event_name,
                    shift_name: userShiftObject.nice.shift_name,
                }
            });

            // get shift details - not included in the api
            const promiseShiftList = shiftList.map((shift) => getShiftDetails(shift.event_id, shift.shift_id));
            const shiftDetailList = await Promise.all(promiseShiftList).then((shiftResponses) => {
                const shiftDetails = shiftResponses.map((shiftResponseObject, index) => {
                    return {
                        date: shiftResponseObject.response.nice.start_timestamp__date,
                        start_timestamp: shiftResponseObject.response.nice.start_timestamp__time,
                        end_timestamp: shiftResponseObject.response.nice.end_timestamp__time,
                        requirements: shiftResponseObject.response.object.shift_requirements,
                        meeting_point: shiftResponseObject.response.object.meeting_point,
                        start_meta_timestamp: shiftResponseObject.response.object.start_timestamp,
                        ...shiftList[index], // append shift user details
                    }
                });
                return shiftDetails;
            })
            return shiftDetailList;
        });
    }

    const mapShiftStatus = (status) => {
        switch (status) {
          case 0:
            return 'Apply';
          case 1:
            return 'Applied';
          case 2:
            return 'Pending';
          case 3:
            return 'Confirmed';
          case 4:
            return 'Attended';
          case 6:
            return 'Rejected';
          default:
            return 'Invalid';
        }
    }
  
    export { 
        rosterfyApi,
        getFutureEvents,
        getShiftList,
        getFutureEventsWithShifts,
        applyUser,
        searchUser,
        checkUserStatus,
        getUserSchedule,
        mapShiftStatus,
    };
          