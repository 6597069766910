import { Link, navigate } from "gatsby";
import { get } from "lodash";
// import parse from 'html-react-parser';
import React, { useCallback, useContext, useMemo, useState } from "react";
import Button from "../../../../../components/atoms/Button/Button";
import FormInputField from "../../../../../components/atoms/FormInputField/FormInputField";
import AccountPageWrapper from "../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper";
import DataTable from "../../../../../components/organisms/DataTable/DataTable";
import AuthContext from "../../../../../context/AuthProvider";
import { toast } from "react-toastify";
import { persistLocation } from "../../../../../helpers/general";
import { getProfile } from "../../../../../helpers/usuapi";
import { authClubExec, wpApi } from "../../../../../helpers/wordpress";
import * as styles from '../../usu.module.css';

const ClubExecs = ({location}) => {
    const auth = useContext(AuthContext);
    const memberNumber = auth && get(auth, 'state.usu.MemberNumber');
    const [isAuthed, setAuthed] = useState(null);
    const [club, setClub] = useState(null);
    const [execEmail, setExecEmail] = useState(get(auth, 'state.email'));
    const [execs, setExecs] = useState(null);
    const [rawExecs, setRawExecs] = useState(null);
    const [updating, setUpdating] = useState(false);

    const defineExecList = useCallback((list) => {
        const clubExecs = list.map(exec => {
            if (exec.member_number === memberNumber && exec.email !== '') {
                setExecEmail(exec.email);
            }
            return getProfile(exec.member_number).then(profile => {
                if (String(profile.status).startsWith("2") && Array.isArray(profile.response)) {
                    const person = profile.response[0];
                    return {
                        member_number: exec.member_number,
                        name: `${person.FirstName} ${person.LastName}`,
                        position: exec.position,
                        email: exec.email || person.Email,
                        mobile: person.Mobile,
                        start_date: exec.start_date
                    }
                } else {
                    return {
                        member_number: exec.member_number,
                        name: "Unknown",
                        position: exec.position,
                        email: exec.email,
                        mobile: '',
                        start_date: exec.start_date
                    }
                }
            });
        });

        Promise.all(clubExecs).then(profiles => {
            setClub(get(location, 'state.clubId'))
            setExecs(profiles);
        })
    }, [location, memberNumber]);

    useMemo(() => {
        if (isAuthed === null || (isAuthed && club && club !== get(location, 'state.clubId'))) {
            setExecs(null);
            authClubExec(memberNumber, get(location, 'state.clubId')).then(authed => {
                if (String(authed.status).startsWith("2") && authed.response) {
                    setAuthed(true);
                    setRawExecs(authed.response.acf.club_execs);
                    defineExecList(authed.response.acf.club_execs);
                } else {
                    setAuthed(false);
                }
            });
        }
    }, [isAuthed, memberNumber, location, club, defineExecList]);

    const processEmailChange = async () => {
        setUpdating(true);
        const newExecs = [...rawExecs];
        const execIndex = newExecs.findIndex(e => e.member_number === memberNumber);
        newExecs[execIndex].email = execEmail;
        const updateClub = await wpApi(`clubs/${club}`, 'POST', {
            fields: {
                club_execs: newExecs,
            }
        });
        setUpdating(false);
        if (String(updateClub.status).startsWith('2') && updateClub.response) {
            toast.success('Email updated successfully.');
            defineExecList(updateClub.response.acf.club_execs);
        } else {
            toast.error('An unexpected error has occurred.\nPlease try again.');
        }
    }

    return (
        <>
            {(execs) ? (
                <>
                    <div className={`${styles.root}`}>
                        {/* Club Execs */}
                        <div className={styles.section}>
                            <h6>Set your club contact email</h6>
                            <div className="grid grid-7525 mt-4">
                                <FormInputField
                                    value={execEmail}
                                    handleChange={(id, value) => setExecEmail(value)}
                                    id="execEmail" 
                                    disabled={updating}
                                />
                                <div><Button level="primary" type="button" size="small" disabled={updating} fullWidth={true} onClick={() => processEmailChange()}>{updating ? 'Changing...' : 'Change'}</Button></div>
                            </div>
                        </div>
                        <DataTable
                            tableData={execs}
                            allowExport={true}
                            headingKeys={[
                                { label: 'Member Number', data_key: 'member_number', sortable: true },
                                { label: 'Name', data_key: 'name', sortable: true },
                                { label: 'Position', data_key: 'position', sortable: true },
                                { label: 'Email', data_key: 'email', sortable: true },
                                { label: 'Mobile', data_key: 'mobile' },
                                { label: 'Start Date', data_key: 'start_date' },
                            ]}
                        />
                    </div>
                </>
            ) : (
                <div className={styles.root}>
                    {/* Fetching clubs */}
                    {((isAuthed === null && execs === null) || (isAuthed === true && execs === null)) && (
                        <div>Fetching your club execs</div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === true && execs === false) && (
                        <div>An error occurred. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === false) && (
                        <div>You are not authorised to manage this club. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                </div>
            )}
        </>
    )
}

const ClubManagementExecs = ({location}) => {
    const persistedLocation = persistLocation(location);
    
    const backToManagementPage = () =>{
        navigate(`/account/my-usu/clubs/manage`,{
            state: persistedLocation.state
        })
    }

    return (
        <AccountPageWrapper metaTitle="Account - Club Management" title="Club Executives" breadcrumbTitle="Club Execs" breadcrumbs={[{ link: '/account/my-usu/clubs/', label: 'My USU' }, { onClick: () => backToManagementPage(), label: get(persistedLocation, 'state.clubTitle', '')}]} menuType="clubManage" location={persistedLocation}>
            <ClubExecs location={persistedLocation} />
        </AccountPageWrapper>

    )
}

export default ClubManagementExecs