import React, { useContext, useMemo, useState } from "react";
import { Link, navigate } from "gatsby";
import { get } from "lodash";
import AccountPageWrapper from "../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper";
import AuthContext from "../../../../../context/AuthProvider";
import { persistLocation } from "../../../../../helpers/general";
import MeetingDetail from "../../../../../components/organisms/MeetingDetail/MeetingDetail";
import { wpApi, authClubExec } from "../../../../../helpers/wordpress";

import * as styles from '../../usu.module.css';

const GeneralMeeting = ({location}) => {
    const auth = useContext(AuthContext);
    const memberNumber = auth && get(auth, 'state.usu.MemberNumber');
    const [isAuthed, setAuthed] = useState(null);
    const [club, setClub] = useState(null);
    const [history, setHistory] = useState(null);

    useMemo(() => {
        const fetchHistory = async () => {
            const data = await wpApi(`igm_agm_history/${location.state.historyId}`);
            if (String(data.status).startsWith("2") && data.response) {
                setHistory(data.response);
            }
        }

        if (isAuthed === null || (isAuthed && club  && club.id !== get(location, 'state.clubId'))) {
            setHistory(null)
            authClubExec(memberNumber, get(location, 'state.clubId')).then(authed => {
                if (String(authed.status).startsWith("2") && authed.response) {
                    setAuthed(true);
                    setClub(authed.response);
                    fetchHistory();
                } else {
                    setAuthed(false);
                }
            });
        }
    }, [isAuthed, memberNumber, location ,club]);

    return (
        <>
            {(history) ? (
                <MeetingDetail history={history} />
            ) : (
                <div className={styles.root}>
                    {/* Fetching club preferences */}
                    {((isAuthed === null && history === null) || (isAuthed === true && history === null)) && (
                        <div>Fetching historical data</div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === true && club === false) && (
                        <div>An error occurred. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === false) && (
                        <div>You are not authorised to manage this club. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                </div>
            )}
        </>
    )
}

const ClubManagementPreferences = ({location}) => {
    const persistedLocation = persistLocation(location);
    
    const backToManagementPage = () =>{
        navigate(`/account/my-usu/clubs/manage`,{
            state: persistedLocation.state
        })
    }

    return (
        <AccountPageWrapper bgRaw metaTitle="Account - Club Management" title="Viewing General Meeting" breadcrumbTitle="Viewing General Meeting" breadcrumbs={[{ link: '/account/my-usu/clubs/', label: 'My USU' }, { onClick: () => backToManagementPage(), label: get(persistedLocation, 'state.clubTitle', '')}]} menuType="clubManage" location={persistedLocation}>
            <GeneralMeeting location={persistedLocation} />
        </AccountPageWrapper>

    )
}

export default ClubManagementPreferences