import React, { useCallback, useContext, useMemo, useState } from "react";
import { Link, navigate } from "gatsby";
import { get } from "lodash"; // random
import { toast } from "react-toastify";
// import parse from 'html-react-parser';
import AuthContext from "../../../../../context/AuthProvider";
import Button from "../../../../../components/atoms/Button/Button";
import AccountPageWrapper from "../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper";
import FormInputField from "../../../../../components/atoms/FormInputField/FormInputField";
// import Radio from "../../../../../components/atoms/Radio/Radio";
import Icon from "../../../../../components/atoms/Icon/Icon";
import Loader from "../../../../../components/atoms/Loader/Loader";
import { authClubExec, uploadMedia, wpApi } from '../../../../../helpers/wordpress';
import { generateQR, getBase64, persistLocation } from '../../../../../helpers/general';
import { success } from '../../../../../helpers/toast';
import { getPerson, updatePerson } from '../../../../../helpers/ortto';

import * as styles from '../../usu.module.css';
import SelectField from "../../../../../components/atoms/SelectField/SelectField";

const RunGM = ({location}) => {
    const auth = useContext(AuthContext);
    const memberNumber = auth && get(auth, 'state.usu.MemberNumber');
    const [isAuthed, setAuthed] = useState(null);
    const [viewType, setViewType] = useState(null);
    const [club, setClub] = useState(null);
    const [roles, setRoles] = useState(null);
    const [activeRole, setActiveRole] = useState(null);
    // const [votingOpen, setVotingOpen] = useState(false);
    const [assigningOpen, setAssigningOpen] = useState(false);
    const [calculatingResults, setCalculatingResults] = useState(false);
    const [isROSet, setIsROSet] = useState(null);
    const [roId, setRoId] = useState(null);
    const [history, setHistory] = useState(null);
    const [roleNominations, setRoleNominations] = useState(null);
    const [existingNominations, setExistingNominations] = useState({});
    const [existingClubExecs, setExistingClubExecs] = useState([]);
    const [nomineeData, setNomineeData] = useState([]);
    const [newNominee, setNewNominee] = useState(null);
    // const [voterCount, setVoterCount] = useState(0);
    // const [voteResults, setVoteResults] = useState({});
    const [tieSelection, setTieSelection] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedFile, setSelectedFile] = useState({});
    const [metaFileName, setMetaFileName] = useState({});

    const coreRoles = useMemo(() => (['President', 'Secretary', 'Treasurer']), []);

    const compileData = useCallback((data) => {
        setLoading(true);
        const execPositions = get(data, 'acf.club_execs', []);
        const position = execPositions.findLast(p => p.member_number === location.state.memberNumber);

        if (position && position.position === 'Returning Officer') {
            setViewType('returningOfficer');
            setRoId(location.state.memberNumber);
            const clubRoles = get(data, 'acf.club_roles', []);
            setRoles(clubRoles);
            setActiveRole(0);
            return wpApi(`igm_agm_history/${data.acf.current_igmagm_history_record.ID}`).then(hist => {
                if (String(hist.status).startsWith("2") && hist.response) {
                    setHistory(hist.response);
                    // Get existing nominations in the case of being halfway through event
                    const _existingNominations = hist.response.acf.nominations;
                    let _existingNominationsObj = {};
                    if (_existingNominations && _existingNominations !== '') {
                        const obj = JSON.parse(_existingNominations);
                        _existingNominationsObj = { ...obj };
                        setExistingNominations(_existingNominationsObj);
                    }
                    // Get voted in execs in the case of being halfway through the event
                    const _votedExecs = hist.response.acf.new_club_execs;
                    if (_votedExecs && _votedExecs.length > 0) {
                        setExistingClubExecs(_votedExecs);
                        // We need to "fast forward" the data to the place the meeting is up to
                        const _activeRole = clubRoles.find((role, rIndex) => {
                            const hasNominations = (role.role in _existingNominationsObj);
                            const hasExec = _votedExecs.find(e => e.position === role.role);
                            if (hasNominations && hasExec) {
                                return false;
                            }else if (hasNominations && !hasExec) {
                                setActiveRole(rIndex);
                                // setVotingOpen(true);
                                setAssigningOpen(true);
                                return true;
                            } else if (!hasNominations && !hasExec) {
                                setActiveRole(rIndex);
                                return true;
                            }
            
                            return false;
                        });

                        if (!_activeRole) {
                            setActiveRole('complete');
                        }
                    } else if (Object.keys(_existingNominationsObj).length > 0) {
                        // setVotingOpen(true);
                        setAssigningOpen(true);
                    }

                    const asyncCalls = [];

                    // Get Nominations
                    asyncCalls.push(new Promise(res => {
                        wpApi(`igm_agm_nominations?event=${hist.response.acf.event.ID}`).then(nominations => {
                            if (String(nominations.status).startsWith("2") && nominations.response.length > 0) {
                                const noms = {};
                                const nums = [];
                                nominations.response.map(n => {
                                    nums.push(n.acf.member_number);
                                    try {
                                        const selfNoms = JSON.parse(n.acf.roles);
                                        Object.keys(selfNoms).map(sn => {
                                            // Remove candidates if already holding a core role
                                            const isCoreRole = coreRoles.indexOf(sn) > -1;
                                            const holdsCore = _votedExecs && _votedExecs.find(ve => ve.member_number === n.acf.member_number);
                                            const isCurrentRO = location.state.memberNumber === n.acf.member_number;
                                            // console.log(location.state.memberNumber, n.acf.member_number);
                                            
                                            if ((isCoreRole && holdsCore) || isCurrentRO) return false;

                                            if (selfNoms[sn] === 'yes') {
                                                if (!(sn in noms)) {
                                                    noms[sn] = [];
                                                }
                                                noms[sn].push({memberNumber: n.acf.member_number, nominationRecord: n.id, nominationObj: n.acf.roles});
                                            }
                                            return true;
                                        })
                                    } catch (e) {
                                        // ignore
                                        // console.log(e);
                                    }

                                    return true;
                                });
                                
                                getPerson(['first', 'last', 'MemberNumber', 'tags'], nums, 'str:cm:membernumber').then(people => {
                                    if (String(people.status).startsWith('2') && people.response.contacts) {
                                        setNomineeData(people.response.contacts);
                                        setRoleNominations(noms);
                                        res(true);
                                    }
                                });
                            } else {
                                // No nominations found
                                setNomineeData([]);
                                setRoleNominations(null);
                                res(true);
                            }
                        });
                    }));

                    // Get checkins
                    // asyncCalls.push(new Promise(res => {
                    //     wpApi(`event_checkins?event=${hist.response.acf.event.ID}`).then(checkIns => {
                    //         if (String(checkIns.status).startsWith("2") && checkIns.response.length > 0) {
                    //             setVoterCount(checkIns.response.length);
                    //         } else {
                    //             setVoterCount(0);
                    //         }

                    //         res(true);
                    //     });
                    // }));

                    // Get votes
                    // asyncCalls.push(new Promise(res => {
                    //     wpApi(`ign_votes?event=${hist.response.acf.event.ID}`).then(votes => {
                    //         const voteDetails = {};
                    //         clubRoles.map(r => {
                    //             voteDetails[r.role] = {total: 0, votes: {}};
                    //             return true;
                    //         });
                    //         if (String(votes.status).startsWith("2") && votes.response.length > 0) {
                    //             votes.response.map(v => {
                    //                 // console.log(v);
                    //                 const votesCast = JSON.parse(v.acf.votes);
                    //                 Object.keys(votesCast).map(vc => {
                    //                     if (Array.isArray(votesCast[vc])) {
                    //                         // console.log(votesCast[vc]);
                    //                         let totalCount = voteDetails[vc].total;
                    //                         voteDetails[vc].votes[v.acf.member_number] = votesCast[vc];
                    //                         voteDetails[vc].total = totalCount+1;
                    //                     } else {
                    //                         let abstainCount = 'abstain' in voteDetails[vc].votes ? voteDetails[vc].votes.abstain : 0;
                    //                         voteDetails[vc].votes.abstain = abstainCount+1;
                    //                     }

                    //                     return true;
                    //                 });

                    //                 return true;
                    //             });
                    //             // console.log(voteDetails);
                    //             setVoteResults(voteDetails);
                    //         } else {
                    //             setVoteResults({});
                    //         }

                    //         res(true);
                    //     });
                    // }));

                    return Promise.all(asyncCalls).then(() => {
                        setLoading(false);
                        return true;
                    })
                }
            })
        } else {
            setViewType('standard');
            const roSet = execPositions.find(p => p.position === 'Returning Officer');
            setIsROSet(roSet);
            if (roSet) {
                setRoId(roSet.member_number);
            }
            return wpApi(`igm_agm_history/${data.acf.current_igmagm_history_record.ID}`).then(hist => {
                if (String(hist.status).startsWith("2") && hist.response) {
                    setHistory(hist.response);
                    // If RO is set on history, use that
                    if (hist.response.acf.returning_officer !== '') {
                        setIsROSet(hist.response.acf.returning_officer);
                    }
                    const clubRoles = get(data, 'acf.club_roles', []);
                    // Get existing nominations in the case of being halfway through event
                    const _existingNominations = hist.response.acf.nominations;
                    let _existingNominationsObj = {};
                    if (_existingNominations && _existingNominations !== '') {
                        const obj = JSON.parse(_existingNominations);
                        _existingNominationsObj = { ...obj };
                    }
                    // Get voted in execs in the case of being halfway through the event
                    const _votedExecs = hist.response.acf.new_club_execs;
                    if (_votedExecs && _votedExecs.length > 0) {
                        // We need to "fast forward" the data to the place the meeting is up to
                        const _activeRole = clubRoles.find((role, rIndex) => {
                            const hasNominations = (role.role in _existingNominationsObj);
                            const hasExec = _votedExecs.find(e => e.position === role.role);
                            if (hasNominations && hasExec) {
                                return false;
                            }else if (hasNominations && !hasExec) {
                                setActiveRole(rIndex);
                                return true;
                            } else if (!hasNominations && !hasExec) {
                                setActiveRole(rIndex);
                                return true;
                            }
            
                            return false;
                        });

                        if (!_activeRole) {
                            setActiveRole('complete');
                        }
                    }
                }
                setLoading(false);
                return true;
            });
        }
    }, [location, coreRoles]);

    useMemo(() => {
        if (isAuthed === null || (isAuthed && club && club.id !== get(location, 'state.clubId'))) {
            setViewType(null);
            authClubExec(memberNumber, get(location, 'state.clubId'), true).then(authed => {
                if (String(authed.status).startsWith("2") && authed.response) {
                    setAuthed(true);
                    setClub(authed.response);
                    compileData(authed.response);
                } else {
                    setAuthed(false);
                }
            });
        }
    }, [isAuthed, memberNumber, location, compileData, club]);

    const openQR = async () => {
        if (typeof window !== 'undefined') {
            const qr = await generateQR({eventId: history.acf.event.ID}, `${process.env.GATSBY_SITE_URL}/account/my-usu/clubs/check-in?c=`);
            const img = new Image();
            img.src = qr;

            const w = window.open("");
            w.document.write(img.outerHTML);
            w.document.close();
        }
    }

    const setRO = async () => {
        setLoading(true);

        const assignRO = async () => {
            const clubExecs = club.acf.club_execs;
            clubExecs.push({
                member_number: roId,
                position: 'Returning Officer'
            });
            const newRO = await getPerson(['email'], roId, 'str:cm:membernumber');
            if (String(newRO.status).startsWith('2') && newRO.response.contacts.length > 0) {
                const ROEmail = newRO.response.contacts[0].fields['str::email'];
                const updateRO = await updatePerson([{
                    email: ROEmail,
                    tags: [`clubro-${club.slug}`]
                }]);

                if (String(updateRO.status).startsWith('2')) {
                    const setClubData = await wpApi(`clubs/${location.state.clubId}`, 'POST', {
                        fields: {
                            club_execs: clubExecs
                        }
                    });

                    const setHistoryData = await wpApi(`igm_agm_history/${club.acf.current_igmagm_history_record.ID}`, 'POST', {
                        fields: {
                            returning_officer: roId
                        }
                    });

                    if (String(setClubData.status).startsWith('2') && String(setHistoryData.status).startsWith('2')) {
                        setClub({...setClubData.response});
                        setHistory({...setHistoryData.response});
                        setIsROSet(true);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        setError("Unable to assign member to role");
                        toast.error("Unable to assign member to role");
                    }
                } else {
                    setLoading(false);
                    setError("Unable to update member record");
                    toast.error("Unable to update member record");
                }
            } else {
                setLoading(false);
                setError("Unable to find member record");
                toast.error("Unable to find member record");
            }
        }

        if (roId === '' || roId === null) {
            setLoading(false);
            setError("Please enter a member number");
            toast.warn("Please enter a member number");
        }

        // Get Nominations
        try {
            wpApi(`igm_agm_nominations?event=${history.acf.event.ID}`).then(nominations => {
                if (String(nominations.status).startsWith("2") && nominations.response.length > 0) {
                    const roNomed = nominations.response.find(n => n.acf.member_number === roId);
                    if (!roNomed) {
                        assignRO();
                    } else {
                        setLoading(false);
                        setError("Member has self nominated and can not be Returning Officer");
                        toast.error("Member has self nominated and can not be Returning Officer");
                    }
                } else {
                    // No nominations found
                    assignRO();
                }
            });
        } catch (e) {
            // Failed to fetch nominations
            setLoading(false);
            setError("An error occurred while validating. Please try again");
            toast.error("An error occurred while validating. Please try again");
        }
    }

    const removeRO = async () => {
        setLoading(true);
        const clubExecs = club.acf.club_execs;
        const ROIndex = clubExecs.findIndex(ce => ce.position === 'Returning Officer');
        const setHistoryObj = await wpApi(`igm_agm_history/${club.acf.current_igmagm_history_record.ID}`, 'POST', {
            fields: {
                returning_officer: ''
            }
        });
        
        if (ROIndex > -1) {
            const currentROMemberNumber = clubExecs[ROIndex].member_number;
            clubExecs.splice(ROIndex, 1);
            const setClubData = await wpApi(`clubs/${location.state.clubId}`, 'POST', {
                fields: {
                    club_execs: clubExecs
                }
            });

            if (String(setClubData.status).startsWith('2') && String(setHistoryObj.status).startsWith('2')) {
                const currentRO = await getPerson(['email'], currentROMemberNumber, 'str:cm:membernumber');
                if (String(currentRO.status).startsWith('2') && currentRO.response.contacts.length > 0) {
                    const ROEmail = currentRO.response.contacts[0].fields['str::email'];
                    const updateRO = await updatePerson([{
                        email: ROEmail,
                        unset_tags: [`clubro-${club.slug}`]
                    }]);

                    if (String(updateRO.status).startsWith('2')) {
                        setClub({...setClubData.response});
                        setHistory({...setHistoryObj.response});
                        setIsROSet(false);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        setError("Unable to update member record");
                    }
                } else {
                    setLoading(false);
                    setError("Unable to find member record");
                }
            } else {
                setLoading(false);
                setError("Unable to unassign member from role");
            }
        }
    }

    const getName = (memberNumber) => {
        // const randomNames = ["!Bob Marley", "!Jen Staky", "!Steve McQueen", "!Allison Zekle", "!Mike Akroyd", "!Uma Bolton"];
        const record = nomineeData ? nomineeData.find(n => n.fields["str:cm:membernumber"] === memberNumber) : false;
        
        if (record) {
            return `${record.fields['str::first']} ${record.fields['str::last']}`;
        } else {
            return 'Unknown'; //`${randomNames[random(0, randomNames.length - 1)]} (${memberNumber})`;
        }
    }

    const removeNominee = (index, role) => {
        const temp = {...roleNominations};
        temp[role].splice(index, 1);
        setRoleNominations(temp);
    }

    const addNominee = async (role) => {
        if (newNominee === '' || newNominee === null) return false;
        if (newNominee === roId) {
            toast.error("Member can not be nominated as they are the acting Returning Officer");
            setNewNominee('');
        } else {
            setLoading(true);
            const memberNumber = newNominee;
            const temp = roleNominations ? {...roleNominations} : {};
            const tempND = nomineeData ? [...nomineeData] : [];
            const record = tempND.find(n => n.fields["str:cm:membernumber"] === memberNumber);
            if (!record) {
                const personRecord = await getPerson(['first', 'last', 'MemberNumber', 'tags'], memberNumber, 'str:cm:membernumber');
                if (String(personRecord.status).startsWith('2') && personRecord.response.contacts && personRecord.response.contacts.length > 0) {
                    tempND.push(personRecord.response.contacts[0]);
                }
            }
            if (!(role in temp)) temp[role] = [];
            const alreadyNominated = temp[role].find(m => m.memberNumber === memberNumber);
            const hasRecord = tempND.find(n => n.fields["str:cm:membernumber"] === memberNumber);
            if (!alreadyNominated && hasRecord) {
                const isMember = hasRecord.fields.tags.find(t => t === `club-${club.slug}`);
                const alreadyHoldsPosition = existingClubExecs.find(m => m.member_number === memberNumber && (coreRoles.indexOf(role) > -1 && coreRoles.indexOf(m.position) > -1));
                if (isMember && !alreadyHoldsPosition) {
                    temp[role].push({memberNumber: memberNumber, nominationRecord: null, nominationObj: null});
                    setRoleNominations(temp);
                    setNomineeData(tempND);
                    setNewNominee('');
                } else if (alreadyHoldsPosition) {
                    // Alert user is already in a core position
                    toast.error("Member already holds a core position");
                    setNewNominee('');
                } else if (!isMember) {
                    // Alert user is not a member of club
                    toast.error("User is not a member of the club and can not be nominated");
                    setNewNominee('');
                }
            } else if (alreadyNominated) {
                // Alert already nominated
                toast.warn("Member is already on the nomination list");
                setNewNominee('');
            } else if (!hasRecord) {
                // Alert number not found
                toast.error("Member number not found");
            }
            setLoading(false);
        }
    }

    const manualAssignRole = async () => {
        // Store nominations in history record to drive voters views
        setLoading(true);

        const newNominations = {...existingNominations};

        newNominations[roles[activeRole].role] = roleNominations[roles[activeRole].role].map(nom => {
            const name = getName(nom.memberNumber);
            return {
                name, 
                member_number: nom.memberNumber,
            }
        });

        const updated = await wpApi(`igm_agm_history/${history.id}`, 'POST', {
            fields: {
                nominations: JSON.stringify(newNominations)
            }
        });

        if (String(updated.status).startsWith('2')) {
            setExistingNominations({...newNominations});
            setAssigningOpen(true);
            setLoading(false);
        }
    }

    // const openVoting = async () => {
    //     // Store nominations in history record to drive voters views
    //     setLoading(true);

    //     const newNominations = {...existingNominations};

    //     newNominations[roles[activeRole].role] = roleNominations[roles[activeRole].role].map(nom => {
    //         const name = getName(nom.memberNumber);
    //         return {
    //             name, 
    //             member_number: nom.memberNumber,
    //         }
    //     });

    //     const updated = await wpApi(`igm_agm_history/${history.id}`, 'POST', {
    //         fields: {
    //             nominations: JSON.stringify(newNominations)
    //         }
    //     });

    //     if (String(updated.status).startsWith('2')) {
    //         setExistingNominations({...newNominations});
    //         setVotingOpen(true);
    //         setLoading(false);
    //     }
    // }

    // const recheck = () => {
    //     compileData(club);
    // }

    // const preferentialVoting = (toWin, positions, origVotes, votes) => {
    //     const voteCount = [];

    //     const getVotedMember = (v, voteNumber) => {
    //         const votedMember = v.find(vm => vm.vote === String(voteNumber));
    //         if (!votedMember) {
    //             return getVotedMember(v, voteNumber + 1);
    //         } else {
    //             return votedMember;
    //         }
    //     }

    //     // Loop through each voters votes, fetching the highest ranked
    //     Object.values(votes).map(v => {
    //         // console.log(v);
    //         // Check for vote of no confidence
    //         if (v.length === 1 && v[0].vote === '0') {
    //             if (voteCount.length === 0) voteCount.push({member_number: v[0].member_number, count: 0, toWin});
    //         } else {
    //             const votedMember = getVotedMember(v, 1);
    //             const vmIndex = voteCount.findIndex(vc => vc.member_number === votedMember.member_number);
    //             if (vmIndex === -1) voteCount.push({member_number: votedMember.member_number, count: 1, toWin});
    //             else voteCount[vmIndex].count++;
    //         }

    //         return true;
    //     });

    //     // Sort candidates from votes highest to lowest
    //     voteCount.sort((a, b) => a.count + b.count);

    //     // Determine if we have a clear winner with enough votes above the quota amount
    //     let success = true;
    //     for (let p = 0; p < positions; p++) {
    //         if (voteCount[p].count < toWin) success = false;
    //     }

    //     if (!success) {
    //         // No one has enough votes to be above the quota, so remove the least voted and try again
    //         let lowest = null;
    //         const removedMemberNumbers = [];
    //         voteCount.slice(0).reverse().map(vc => {
    //             // console.log(vc, lowest, vc.count);
    //             if (lowest === null) lowest = vc.count;
    //             if (vc.count === lowest) removedMemberNumbers.push(vc.member_number);
    //             return true;
    //         });

    //         const newVotes = {...votes};
    //         Object.keys(newVotes).map(voter => {
    //             const _currentVotes = newVotes[voter].filter(v => removedMemberNumbers.indexOf(v.member_number) === -1);
    //             newVotes[voter] = _currentVotes;
    //             return true;
    //         });

    //         // Check if there are any votes left
    //         if (Object.values(newVotes)[0].length > 0) {
    //             return preferentialVoting(toWin, positions, origVotes, newVotes);
    //         } else {
    //             // No one reached quota and all were removed. Return first set of results where highest wins
    //             return preferentialVoting(0, positions, origVotes, origVotes);
    //         }
    //     } else {
    //         return voteCount;
    //     }
    // }

    // const closeVoting = async () => {
    //     setVotingOpen(false);
    //     setCalculatingResults(true);
    //     // ensure we have the latest data
    //     await compileData(club);
    //     setVotingOpen(false);
    //     setLoading(true);
    //     // console.log("Vote Results", voteResults[roles[activeRole].role]);
    //     // console.log("Role", roles[activeRole]);
    //     // console.log("Candidates", existingNominations[roles[activeRole].role]);
    //     const candidates = existingNominations[roles[activeRole].role];
    //     const positions = Number(roles[activeRole].amount);
    //     const total = voteResults[roles[activeRole].role].total;
    //     const votes = voteResults[roles[activeRole].role].votes;
    //     // const total = 0;
    //     // const votes = {
    //     //     // 2251849: [
    //     //     //     {member_number: '1928375', vote: '1'},
    //     //     // ],
    //     //     // 12345678: [
    //     //     //     {member_number: '9876543', vote: '1'},
    //     //     // ],
    //     //     abstain: 3
    //     // }
    //     // Remove abstained count
    //     if ('abstain' in votes) delete votes.abstain;

    //     const votesToWin = candidates.length > 2 ? Math.ceil((total / (positions + 1)) + 1) : Math.ceil(total / 2);
    //     // console.log("Votes to win", votesToWin);

    //     const voteCount = preferentialVoting(votesToWin, positions, votes, votes);

    //     // console.log("End result", voteCount);

    //     if (candidates.length > 1) {
    //         // Ensure votes are in order
    //         voteCount.sort((a, b) => a.count + b.count);

    //         // Filter the winners
    //         const potentialWinners = voteCount.filter((c) => {
    //             if (c.toWin === 0) return true;
    //             if (c.count >= votesToWin) return true;

    //             return false;
    //         });

    //         // Check if we have a tie for a position
    //         let highestVotes = null;
    //         const tieWinners = potentialWinners.filter((c, cI) => {
    //             if (highestVotes === null) {
    //                 highestVotes = c.count;
    //                 return true;
    //             } else if (c.count === highestVotes) return true;

    //             return false;
    //         });

    //         if (tieWinners.length > positions) {
    //             // We have a tie. Output to RO and group to take manual selection.
    //             // RO to select manually.
    //             const data = [...tieWinners];
    //             data[0].tieSelect = true;
    //             setCalculatingResults(data);
    //         } else {
    //             const winners = potentialWinners.filter((c, cI) => {
    //                 if (cI < positions) return true;

    //                 return false;
    //             });

    //             setCalculatingResults(winners);
    //         }
    //     } else {
    //         // Handle vote of confidence
    //         setCalculatingResults([{...voteCount[0], confidence: voteCount[0].count >= votesToWin}]);
    //     }

    //     setLoading(false);
    // }

    const assignVote = async (memberNumber, index, selectId) => {
        const tempResults = calculatingResults ? [...calculatingResults] : [];
        const resultItem = tempResults.findIndex(r => r.index === index);
        const alreadyAssigned = tempResults.find(m => m.member_number === memberNumber);
        if (!alreadyAssigned) {
            if (resultItem > -1) {
                tempResults[resultItem].member_number = memberNumber;
            } else {
                tempResults.push({member_number: memberNumber, index: index});
            }
        } else {
            if (resultItem > -1) {
                tempResults.splice(resultItem, 1);
            }
            toast.warn("Member is already selected elsewhere. Choose someone else.");
            document.getElementById(selectId).value = '';
        }

        setCalculatingResults(tempResults);
    }

    const finaliseVote = async () => {
        // console.log(calculatingResults, roles[activeRole].role, tieSelection);
        setLoading(true);
        if (calculatingResults) {
            const _existingClubExecs = [...existingClubExecs];
            if (tieSelection !== null) {
                const selected = calculatingResults.find(c => c.member_number === tieSelection);
                if (selected) {
                    _existingClubExecs.push({member_number: selected.member_number, position: roles[activeRole].role});
                }
                setTieSelection(null);
            } else {
                calculatingResults.map(w => {
                    // Don't add a role if vote of no confidence is given
                    if ('confidence' in w && w.confidence === false) {
                        _existingClubExecs.push({member_number: 'vote of no confidence', position: roles[activeRole].role});
                    } else {
                        _existingClubExecs.push({member_number: w.member_number, position: roles[activeRole].role});
                    }
                    
                    return true;
                });
            }
            setExistingClubExecs([..._existingClubExecs]);
            const obj = {
                fields: {
                    new_club_execs: _existingClubExecs
                }
            }
            await wpApi(`igm_agm_history/${club.acf.current_igmagm_history_record.ID}`, 'POST', obj);
            setCalculatingResults(false);
            setAssigningOpen(false);
            const nextActiveRole = activeRole + 1;
            if (roles.length > nextActiveRole) setActiveRole(nextActiveRole);
            else setActiveRole('complete');
            await compileData(club);
        } else {
            toast.warn("Please select a member for all positions");
        }
        setLoading(false);
    }

    const skipVote = async () => {
        setLoading(true);
        const _existingClubExecs = [...existingClubExecs];
        _existingClubExecs.push({member_number: 'vote of no confidence', position: roles[activeRole].role});
        setExistingClubExecs([..._existingClubExecs]);
        const obj = {
            fields: {
                new_club_execs: _existingClubExecs
            }
        }
        await wpApi(`igm_agm_history/${club.acf.current_igmagm_history_record.ID}`, 'POST', obj);
        setCalculatingResults(false);
        setAssigningOpen(false);
        const nextActiveRole = activeRole + 1;
        if (roles.length > nextActiveRole) setActiveRole(nextActiveRole);
        else setActiveRole('complete');
        await compileData(club);
        setLoading(false);
    }

    const finaliseGM = async () => {
        const data = {};
        setLoading(true);
        const base64Minutes = await getBase64(selectedFile.minutes);
        const responseMinutes = await uploadMedia(`GM-Minutes-${location.state.clubSlug}.pdf`, base64Minutes, selectedFile.minutes.type);
        const base64Constitution = await getBase64(selectedFile.constitution);
        const responseConstitution = await uploadMedia(`GM-Constitution-${location.state.clubSlug}.pdf`, base64Constitution, selectedFile.constitution.type);

        if(String(responseMinutes.status).startsWith('2') && String(responseConstitution.status).startsWith('2')) {
            data.fields = {};
            data.fields.status = 'pending';
            data.fields.minutes = responseMinutes.response.id;
            data.fields.constitution = responseConstitution.response.id;
            const upload = await wpApi(`igm_agm_history/${club.acf.current_igmagm_history_record.ID}`, 'POST', data);
            
            if (String(upload.status).startsWith('2')) {
                // Remove RO from club exec access list
                const clubExecs = club.acf.club_execs;
                const ROIndex = clubExecs.findIndex(ce => ce.position === 'Returning Officer');
                if (ROIndex && clubExecs[ROIndex]) {
                    const currentROMemberNumber = clubExecs[ROIndex].member_number;
                    clubExecs.splice(ROIndex, 1);
                    const setClubData = await wpApi(`clubs/${location.state.clubId}`, 'POST', {
                        status: club.status === 'ign' ? 'engaged' : 'publish',
                        fields: {
                            club_execs: clubExecs,
                            agm_functionality: false
                        }
                    });

                    if (String(setClubData.status).startsWith('2')) {
                        const currentRO = await getPerson(['email'], currentROMemberNumber, 'str:cm:membernumber');
                        if (String(currentRO.status).startsWith('2') && currentRO.response.contacts.length > 0) {
                            const ROEmail = currentRO.response.contacts[0].fields['str::email'];
                            const updateRO = await updatePerson([{
                                email: ROEmail,
                                unset_tags: [`clubro-${club.slug}`]
                            }]);

                            if (String(updateRO.status).startsWith('2')) {
                                success('Submitted successfully');
                                navigate(`/account/my-usu/clubs`,{
                                    state: location.state
                                })
                            } else {
                                setLoading(false);
                                setError("Unable to update member record");
                            }
                        } else {
                            setLoading(false);
                            setError("Unable to find member record");
                        }
                    } else {
                        setLoading(false);
                        setError("Unable to unassign member from role");
                    }
                } else {
                    success('Submitted successfully');
                    navigate(`/account/my-usu/clubs`,{
                        state: location.state
                    })
                }
            }
        }
    }

    const handleUpload = (e, document) => {
        if(e === undefined) return;
        if (e.target.files[0].type !== 'application/pdf') {
            toast.error("File is not a PDF. Please choose a PDF filetype.");
        } else {
            const tempSelectedFile = {...selectedFile};
            const tempMetaFileName = {...metaFileName};
            tempSelectedFile[document] = e.target.files[0];
            tempMetaFileName[document] = e.target.files[0]?.name;
            setSelectedFile({...tempSelectedFile});
            setMetaFileName({...tempMetaFileName});
        }
    }

    const finaliseDocumentsMarkup = () => (
        <>
            <p>Your meeting has completed. Finalise the meeting by uploading the minutes of the meeting and the updated constitution.</p>
            <div className={styles.filePickerContainer}>
                <div className={styles.fileHelperContainer}>
                    <span className={styles.fileName}>{'minutes' in metaFileName ? metaFileName.minutes : 'Minutes of meeting'}</span>
                    <span className={styles.fileHelper}>Must be PDF and cannot exceed 1MB.</span>
                    {get(history, 'acf.minutes', null) && (
                        <span>Current: <a href={get(history, 'acf.minutes', null)} target="_blank" rel="noopener noreferrer">View</a></span>
                    )}
                </div>
                <label className={styles.filePicker} htmlFor='minutes_file' >
                    <input id="minutes_file" type="file" accept=".pdf,application/pdf" name="minutes_file" onChange={(e) => handleUpload(e, 'minutes')} />
                    Select File
                </label>
            </div>
            <div className={styles.filePickerContainer}>
                <div className={styles.fileHelperContainer}>
                    <span className={styles.fileName}>{'constitution' in metaFileName ? metaFileName.constitution : 'Latest constitution'}</span>
                    <span className={styles.fileHelper}>Must be PDF and cannot exceed 1MB.</span>
                    {get(history, 'acf.constitution', null) && (
                        <span>Current: <a href={get(history, 'acf.constitution', null)} target="_blank" rel="noopener noreferrer">View</a></span>
                    )}
                </div>
                <label className={styles.filePicker} htmlFor='constitution_file' >
                    <input id="constitution_file" type="file" accept=".pdf,application/pdf" name="constitution_file" onChange={(e) => handleUpload(e, 'constitution')} />
                    Select File
                </label>
            </div>
            <Button type="button" level="primary" disabled={loading || !('minutes' in selectedFile) || !('constitution' in selectedFile)} onClick={finaliseGM}>Finalise meeting</Button>
        </>
    )

    return (
        <div className={`${styles.root}`}>
            {(isAuthed) ? (
                <>
                    {(viewType === 'standard' && !isROSet) && (
                        <>
                            {history && (
                                <Button type="span" link={true} onClick={openQR}>Open check-in QR in new window</Button>
                            )}
                            <p>A returning officer needs to be voted for. Once selected, please enter their USU Member Number below:</p>
                            <FormInputField
                                id='returning_officer'
                                type='input'
                                labelName='Returning Officer'
                                placeholder='USU Member Number'
                                handleChange={(id, value) => setRoId(value)}
                                error={error}
                            />
                            <Button type="button" level="primary" disabled={loading} onClick={setRO}>{loading ? 'Assigning...' : 'Assign'}</Button>
                        </>
                    )}

                    {(viewType === 'standard' && isROSet && Number.isInteger(Number(activeRole))) && (
                        <>
                            {history && (
                                <Button type="span" link={true} onClick={openQR}>Open check-in QR in new window</Button>
                            )}
                            <p>Your Returning Officer is now assigned. Please have them login to their account and find your club in the "My Clubs" screen to continue the meeting.</p>
                            <p>Assigned Returning Officer: <strong>{roId}</strong></p>
                            <Button type="span" link={true} onClick={removeRO}>Change Returning Officer</Button>
                        </>
                    )}

                    {(viewType === 'standard' && isROSet && activeRole === 'complete') && (
                        // <>
                        //     <p>Your meeting has completed. Finalise the meeting by uploading the minutes of the meeting and the updated constitution.</p>
                        //     <div className={styles.filePickerContainer}>
                        //         <div className={styles.fileHelperContainer}>
                        //             <span className={styles.fileName}>{'minutes' in metaFileName ? metaFileName.minutes : 'Minutes of meeting'}</span>
                        //             <span className={styles.fileHelper}>Must be PDF and cannot exceed 1MB.</span>
                        //             {get(history, 'acf.minutes', null) && (
                        //                 <span>Current: <a href={get(history, 'acf.minutes', null)} target="_blank" rel="noopener noreferrer">View</a></span>
                        //             )}
                        //         </div>
                        //         <label className={styles.filePicker} htmlFor='minutes_file' >
                        //             <input id="minutes_file" type="file" name="minutes_file" onChange={(e) => handleUpload(e, 'minutes')} />
                        //             Select File
                        //         </label>
                        //     </div>
                        //     <div className={styles.filePickerContainer}>
                        //         <div className={styles.fileHelperContainer}>
                        //             <span className={styles.fileName}>{'constitution' in metaFileName ? metaFileName.constitution : 'Latest constitution'}</span>
                        //             <span className={styles.fileHelper}>Must be PDF and cannot exceed 1MB.</span>
                        //             {get(history, 'acf.constitution', null) && (
                        //                 <span>Current: <a href={get(history, 'acf.constitution', null)} target="_blank" rel="noopener noreferrer">View</a></span>
                        //             )}
                        //         </div>
                        //         <label className={styles.filePicker} htmlFor='constitution_file' >
                        //             <input id="constitution_file" type="file" name="constitution_file" onChange={(e) => handleUpload(e, 'constitution')} />
                        //             Select File
                        //         </label>
                        //     </div>
                        //     <Button type="button" level="primary" disabled={loading || !('minutes' in selectedFile) || !('constitution' in selectedFile)} onClick={finaliseGM}>Finalise meeting</Button>
                        // </>
                        <>
                            {/* <p>Voting process is now concluded. Returning Officer is now finalising the meeting and uploading meeting documents.</p> */}
                            {finaliseDocumentsMarkup()}
                        </>
                    )}

                    {viewType === 'returningOfficer' && (
                        <>
                            <p>Congratulations on being voted to be the Returning Officer for this meeting.</p>
                            <p>Please review the nominees for the next role to be voted for.</p>

                            <div className={styles.nominationsManagement}>
                                <div className={styles.progressStatus}>
                                    <ul>
                                        {(roles && activeRole !== null) && roles.map((role, rIndex) => (
                                            <li key={rIndex} className={`${rIndex === activeRole && styles.current} ${rIndex < activeRole && styles.complete}`}>{role.role}</li>
                                        ))}
                                    </ul>
                                </div>

                                <div className={styles.nominationsDetail}>
                                    {(roles && activeRole !== null) && (
                                        <div className="role">
                                            {/* {(!votingOpen && !calculatingResults && activeRole !== 'complete') && ( */}
                                            {(!assigningOpen && !calculatingResults && activeRole !== 'complete') && (
                                                <>
                                                    Nominations for <b>{roles[activeRole].role}</b> to fill <b>{roles[activeRole].amount} position{roles[activeRole].amount > 1 && 's'}</b>
                                                    
                                                    <div className={styles.nominations}>
                                                        <div>
                                                            {roleNominations && roleNominations[roles[activeRole].role] && roleNominations[roles[activeRole].role].map((nom, nIndex) => (
                                                                <span key={nIndex} className={styles.candidate}>
                                                                    {getName(nom.memberNumber)}
                                                                    <span role="presentation" className={styles.remove} onClick={() => removeNominee(nIndex, roles[activeRole].role)}><Icon symbol="close" /></span>
                                                                </span>
                                                            ))}
                                                        </div>

                                                        <div style={{marginTop: "30px"}}>
                                                            <FormInputField
                                                                id='add_candidate'
                                                                type='input'
                                                                labelName='Add Candidate'
                                                                placeholder='USU Member Number'
                                                                value={newNominee}
                                                                handleChange={(id, value) => setNewNominee(value)}
                                                            />
                                                            <Button type="button" level="primary" size="tinier" disabled={loading} onClick={() => addNominee(roles[activeRole].role)}>{loading ? 'Adding...' : 'Add'}</Button>
                                                        </div>

                                                        <div className={styles.actionCard}>
                                                            <Button type="button" level="secondary" disabled={loading} onClick={() => window.location.reload()}>Reset nominations</Button>
                                                            {!roleNominations || !(roles[activeRole].role in roleNominations) || roleNominations[roles[activeRole].role].length === 0 ? (
                                                                <Button type="button" level="primary" disabled={loading} onClick={() => skipVote()}>Skip assignment</Button> // Skip vote
                                                            ) : (
                                                                // <Button type="button" level="primary" disabled={loading} onClick={() => openVoting()}>Open voting</Button>
                                                                <Button type="button" level="primary" disabled={loading} onClick={() => manualAssignRole()}>Assign Role</Button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {/* {(votingOpen && !calculatingResults && Number.isInteger(Number(activeRole))) && (
                                                <>
                                                    Voting is now open for the role of <b>{roles[activeRole].role}</b> to fill <b>{roles[activeRole].amount} position{roles[activeRole].amount > 1 && 's'}</b>

                                                    <div>
                                                        {roles[activeRole].role in voteResults ? voteResults[roles[activeRole].role].total : 0} vote{(roles[activeRole].role in voteResults && voteResults[roles[activeRole].role].total > 1) && 's'} from {voterCount - (roles[activeRole].role in voteResults ? get(voteResults[roles[activeRole].role], 'votes.abstain', 0) : 0)} {((roles[activeRole].role in voteResults && get(voteResults[roles[activeRole].role], 'votes.abstain', 0) > 0) ? `(${get(voteResults[roles[activeRole].role], 'votes.abstain', 0)} abstained)` : '')} are in.
                                                    </div>

                                                    <p><Button type="span" link={true} onClick={() => recheck()}>Get update</Button></p>
                                                    {(voterCount - (roles[activeRole].role in voteResults ? get(voteResults[roles[activeRole].role], 'votes.abstain', 0) : 0)) === 0 ? (
                                                        <p><Button type="span" link={true} onClick={() => skipVote()}>Close voting and skip role assignment</Button></p>
                                                    ): (
                                                        <p><Button type="span" link={true} onClick={() => closeVoting()}>Close voting and get results</Button></p>
                                                    )}
                                                </>
                                            )} */}

                                            {/* {calculatingResults && (
                                                <>
                                                    {calculatingResults === true ? (
                                                        <p>Calculating results... please wait...</p>
                                                    ) : (
                                                        <>
                                                            <h6>Results for {roles[activeRole].role} vote</h6>
                                                            {calculatingResults.length === 1 && 'confidence' in calculatingResults[0] ? (
                                                                <>
                                                                    <p>With {calculatingResults[0].count} vote{(calculatingResults[0].count > 1 || calculatingResults[0].count === 0) ? 's' : ''}, {getName(calculatingResults[0].member_number)} has {calculatingResults[0].confidence ? 'vote of confidence and is elected.' : 'vote of NO confidence. Position remains unfilled.'}</p>
                                                                    <p><Button type="span" link={true} onClick={() => finaliseVote()}>Continue</Button></p>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {calculatingResults.length > 1 && 'tieSelect' in calculatingResults[0] ? (
                                                                        <>
                                                                            <p>There is a tie between {calculatingResults.length} candidates for the position of {roles[activeRole].role}.</p>
                                                                            <p>Please determine the outcome randomly by a method agreed on by the room.</p>

                                                                            <h6>Candidates in the running for the role:</h6>
                                                                            {calculatingResults.map((candidate, cI) => (
                                                                                <div key={cI} className={styles.vote}>
                                                                                    <Radio id={`${roles[activeRole].role}_${candidate.member_number}`} name={roles[activeRole].role} label={getName(candidate.member_number)} isChecked={tieSelection === candidate.member_number} action={() => setTieSelection(candidate.member_number)} />
                                                                                </div>
                                                                            ))}
                                                                            {tieSelection !== null && (
                                                                                <p><Button type="span" link={true} onClick={() => finaliseVote()}>Set and Continue</Button></p>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {calculatingResults.map((candidate, cI) => (
                                                                                <p key={cI}>With {candidate.count} vote{candidate.count > 1 ? 's' : ''}, {getName(candidate.member_number)} is elected.</p>
                                                                            ))}
                                                                            <p><Button type="span" link={true} onClick={() => finaliseVote()}>Continue</Button></p>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )}

                                                </>
                                            )} */}

                                            {(assigningOpen && Number.isInteger(Number(activeRole))) && (
                                                <>
                                                    <h6>Set results for {roles[activeRole].role} vote</h6>
                                                    {Array.from({length: Number(roles[activeRole].amount)}).map((p, pI) => (
                                                        <div style={{marginTop: "30px"}} key={pI}>
                                                            {/* <FormInputField
                                                                id={`set_${roles[activeRole].role}_${pI}`}
                                                                type='input'
                                                                labelName={`Voted member #${pI + 1}`}
                                                                placeholder='USU Member Number'
                                                                handleChange={(id, value) => assignVote(value, pI)}
                                                            /> */}
                                                            {(existingNominations && existingNominations[roles[activeRole].role]) && (
                                                                <SelectField
                                                                    id={`set_${roles[activeRole].role.replace(' ', '-').toLowerCase()}_${pI}`}
                                                                    label={`Voted member #${pI + 1}`}
                                                                    placeholder='USU Member'
                                                                    handleChange={(id, value) => assignVote(value, pI, `set_${roles[activeRole].role.replace(' ', '-').toLowerCase()}_${pI}`)}
                                                                    data={existingNominations[roles[activeRole].role].map(n => ({value: n.member_number, text: `${n.name} (${n.member_number})`}))}
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                                    <p>
                                                        <Button type="span" link={true} onClick={() => finaliseVote()}>Assign member{Number(roles[activeRole].amount) > 1 && 's'}</Button>
                                                        &nbsp;&nbsp;or&nbsp;&nbsp;
                                                        <Button type="span" link={true} onClick={() => skipVote()}>Set 'Vote of no confidence'</Button>
                                                    </p>
                                                </>
                                            )}

                                            {activeRole === 'complete' && (
                                                // <>
                                                //     <p>Voting process is now concluded. Thank you.</p>
                                                // </>
                                                <>{finaliseDocumentsMarkup()}</>
                                            )}

                                            {loading && (
                                                <div className={styles.cover}>
                                                    <Loader />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    {/* Fetching clubs */}
                    {((isAuthed === null && viewType === null) || (isAuthed === true && viewType === null)) && (
                        <div>Fetching...</div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === true && viewType === false) && (
                        <div>An error occurred. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === false) && (
                        <div>You are not authorised to manage this club. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                </>
            )}
        </div>
    )
}

const ClubManagementIGM = ({location}) => {
    const persistedLocation = persistLocation(location);
    
    const backToManagementPage = () =>{
        navigate(`/account/my-usu/clubs/manage`,{
            state: persistedLocation.state
        })
    }

    return (
        <AccountPageWrapper metaTitle="Account - Club Management" title={`Run Clubs General Meeting`} breadcrumbTitle="Run Clubs General Meeting" breadcrumbs={[{ link: '/account/my-usu/clubs/', label: 'My USU' }, { onClick: () => backToManagementPage(), label: get(persistedLocation, 'state.clubTitle', '')}]} menuType="clubManage" location={persistedLocation}>
            <RunGM location={persistedLocation} />
        </AccountPageWrapper>

    )
}

export default ClubManagementIGM