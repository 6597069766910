import { Link, navigate } from "gatsby";
import { renderToString } from 'react-dom/server';
import { get } from "lodash";
import { decode } from "he";
import { toast } from 'react-toastify';
import Icon from "../../../../../components/atoms/Icon/Icon";
// import parse from 'html-react-parser';
import React, { useCallback, useContext, useMemo, useState } from "react";
import AccountPageWrapper from "../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper";
import DataTable from "../../../../../components/organisms/DataTable/DataTable"
import AuthContext from "../../../../../context/AuthProvider";
import { wpApi, wpAll, authClubExec } from "../../../../../helpers/wordpress";
import { validateEmail, persistLocation } from "../../../../../helpers/general";
import MessageAlert from "../../../../../components/atoms/MessageAlert/MessageAlert";
import Dialog from "../../../../../components/atoms/Dialog/Dialog";
import OrttoEmailTemplate from "../../../../../components/organisms/OrttoEmailTemplate/OrttoEmailTemplate";

import * as styles from '../../usu.module.css';

const Communicate = ({location}) => {
    const auth = useContext(AuthContext);
    const memberNumber = auth && get(auth, 'state.usu.MemberNumber');
    const [isAuthed, setAuthed] = useState(null);
    const [club, setClub] = useState();
    const [isReady, setReady] = useState(true);
    const [clubCommunications, setCommunications] = useState(null);
    const [previewEmail, setPreviewEmail] = useState(false);

    const fetchClubCommunicate = useCallback(async () => {
        const communications = await wpAll(`communications?status=draft,publish&search={${location.state.clubSlug}}`);
        if (String(communications.status).startsWith("2") && communications.response) {
            // console.log(communications.response);
            const emails = communications.response.map(email => {
                // console.log(email);
                const regex = /{.*} (.*)/gm;
                const emailName = regex.exec(email.title.rendered);
                // console.log(emailName);
                const sentDate = email.status === 'publish' ? email.modified : '';
                const recipients = email.status === 'publish' ? '123' : '';
                return {
                    id: email.id,
                    campaign_name: emailName[1],
                    campaign_body: email.content.rendered,
                    campaign_subject: email.acf.subject,
                    campaign_audience: email.acf.audience,
                    sent: sentDate,
                    recipients: recipients,
                    status: <span className={`${styles.pill} ${email.status === 'publish' ? styles.green : styles.grey}`}>{email.status === 'publish' ? 'Sent' : email.status}</span>
                }
            });
            setCommunications(emails);
        } else {
            // Failed fetch of club data
            setCommunications(false);
        }
    }, [location]);

    useMemo(() => {
        if (isAuthed === null || (isAuthed && club && club.id !== get(location, 'state.clubId'))) {
            setCommunications(null)
            authClubExec(memberNumber, get(location, 'state.clubId')).then(authed => {
                if (String(authed.status).startsWith("2") && authed.response) {
                    setAuthed(true);
                    setClub(authed.response);
                    fetchClubCommunicate();
                    if (
                        authed.response.acf.club_preferences.sender_email === '' ||
                        authed.response.acf.club_preferences.sender_name === '' ||
                        !validateEmail(authed.response.acf.club_preferences.sender_email)
                    ) {
                        setReady(false);
                    }
                } else {
                    setAuthed(false);
                }
            });
        }
    }, [isAuthed, memberNumber, location, fetchClubCommunicate , club]);
    
    const handlePreview = (data) => {
        const emailBody = renderToString(<OrttoEmailTemplate logo={club.acf.logo} clubName={decode(club.title.rendered)} content={data.campaign_body} />);
        // console.log(emailBody);
        setPreviewEmail(emailBody);
    }

    const handleEdit = (data) => {
        navigate(`/account/my-usu/clubs/manage/edit-campaign/`,{
            state: {
                ...location.state,
                campaignId: data.id
            }
        });
    }

    const handleDuplicate = (data) => {
        navigate(`/account/my-usu/clubs/manage/edit-campaign/`,{
            state: {
                ...location.state,
                campaignId: null,
                startingPoint: {
                    name: `${data.campaign_name} (Duplicate)`,
                    subject: data.campaign_subject,
                    body: data.campaign_body,
                    audience: data.campaign_audience
                }
            }
        });
    }

    const handleDelete = async (data) => {
        // console.log('handleData', data)
        const deleteEmail = await wpApi(`communications/${data.id}`, 'DELETE');
        if (String(deleteEmail.status).startsWith('2') && deleteEmail.response) {
          toast.success('Email has been deleted.');
          fetchClubCommunicate();
        }
    }

    const handleBulkActions = async (action, data) => {
        // console.log(action, data)
        try {
            if (data.length > 0) {
              if (action === 'delete') {
                const promises = data.map(email =>
                  wpApi(`communications/${email.id}`, 'DELETE')
                );
                await Promise.all(promises);
                toast.success('Selected emails have been deleted.');
                fetchClubCommunicate();
              }
            }
        } catch (error) {
            toast.error(
              `An error has occured. Failed to ${action} selected emails.`
            );
        }
    }

    const createCampaign = () => {
        navigate(`/account/my-usu/clubs/manage/edit-campaign/`,{
            state: {...location.state, ...{campaignId: null}}
        });
    }

    return (
        <>
            {(clubCommunications) ? (
                <>
                    <div className={`${styles.root}`}>
                        {/* Club Edit Details */}
                        {!isReady ? (
                            <MessageAlert type="error" message={(
                                <div className="error"><strong>ACTION NEEDED:</strong> Please visit Club Preferences and set your sender information with valid values. Communications can not work without them.</div>
                            )} />
                        ) : (
                            <>
                                <DataTable 
                                    tableData={clubCommunications} 
                                    bulkActions={[
                                        { label: 'Bulk delete', key: 'delete' }
                                    ]}
                                    handleBulkAction={handleBulkActions}
                                    defaultSortKey="sent"
                                    topActions={[
                                        { label: 'New Campaign', icon: <Icon symbol="fileOther" />, event: (data) => createCampaign(data) }
                                    ]}
                                    headingKeys={[
                                        { label: 'Campaign Name', data_key: 'campaign_name', sortable: true },
                                        { label: 'Sent', data_key: 'sent', sortable: true, format: 'dateTime' },
                                        // { label: 'Recipients', data_key: 'recipients' },
                                        { label: 'Status', data_key: 'status', hidden: true },
                                    ]}
                                    rowActions={[
                                        { label: 'Edit', cta: (data) => handleEdit(data) },
                                        { label: 'Preview', cta: (data) => handlePreview(data) },
                                        { label: 'Duplicate', cta: (data) => handleDuplicate(data) },
                                        { label: 'Delete', cta: (data) => handleDelete(data) }
                                    ]}
                                    bulkAction={true} />

                                <Dialog
                                    size='lg'
                                    title='Preview Email'
                                    hideBtnCancel={true}
                                    onOk={() => setPreviewEmail(false)}
                                    onCancel={() => setPreviewEmail(false)}
                                    open={typeof previewEmail === 'string'}>
                                        <iframe title="Preview" style={{width: "100%", height: "80vh", border: "none"}} srcDoc={previewEmail}></iframe>
                                </Dialog>
                            </>
                        )}
                    </div>
                </>
            ) : (
                <div className={styles.root}>
                    {/* Fetching club communicationss */}
                    {((isAuthed === null && clubCommunications === null) || (isAuthed === true && clubCommunications === null)) && (
                        <div>Fetching your club communications</div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === true && clubCommunications === false) && (
                        <div>An error occurred. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === false) && (
                        <div>You are not authorised to manage this club. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                </div>
            )}
        </>
    )
}

const ClubManagementCommunications = ({location}) => {
    const persistedLocation = persistLocation(location);
    
    const backToManagementPage = () =>{
        navigate(`/account/my-usu/clubs/manage`,{
            state: persistedLocation.state
        })
    }

    return (
        <AccountPageWrapper metaTitle="Account - Club Management" breadcrumbTitle="Club Communications" breadcrumbs={[{ link: '/account/my-usu/clubs/', label: 'My USU' }, { onClick: () => backToManagementPage(), label: get(persistedLocation, 'state.clubTitle', '')}]} menuType="clubManage" location={persistedLocation}>
            <Communicate location={persistedLocation} />
        </AccountPageWrapper>

    )
}

export default ClubManagementCommunications