import React, { useContext, useEffect, useState } from 'react';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';
import { get } from "lodash"
import { decode } from 'he';
import { toast } from 'react-toastify';
import AuthContext from '../../../context/AuthProvider';
import { hasStaffPermissions, extractDate } from '../../../helpers/general';
import { wpApi, wpAll } from '../../../helpers/wordpress';
import AccountPageWrapper from '../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import AccordionCard from '../../../components/atoms/AccordionCard/AccordionCard';
import CurrencyFormatter from '../../../components/atoms/CurrencyFormatter/CurrencyFormatter';
import FormInputField from '../../../components/atoms/FormInputField/FormInputField';
import Button from '../../../components/atoms/Button/Button';
import Checkbox from '../../../components/atoms/Checkbox/Checkbox';
import Icon from '../../../components/atoms/Icon/Icon';

import * as styles from '../manage-screens.module.css';

const AcquittalDetails = ({ location }) => {
    const auth = useContext(AuthContext);
    const memberDetails = auth && get(auth, 'state');
    const [authed, setAuthed] = useState(null);
    const [saving, setSaving] = useState(false);
    const [reviewData, setReviewData] = useState({});
    const [reviewAcquittal, setReviewAcquittal] = useState(false);
    const [openAccordion, setAccordion] = useState(null);

    const {
        allWpGrantType: { nodes: grantTypes },
    } = useStaticQuery(graphql`
        query {
          allWpGrantType {
            nodes {
              name
              id: databaseId
            }
          }
        }
    `);

    useEffect(() => {
        if (authed === null && get(auth, 'state.userChecked') && get(location, 'state.acquittal')) {
            setAuthed(hasStaffPermissions(auth, [2, 3, 4]));
            wpAll(`grants?include=${location.state.acquittal.grants.map(g => g.grant.ID).join(',')}`).then(grantsRes => {
                wpAll(`club_receipts?include=${location.state.acquittal.receipts ? location.state.acquittal.receipts.map(g => g.receipt.ID).join(',') : '0'}`).then(receiptsRes => {
                    setReviewAcquittal({...location.state.acquittal, grants: grantsRes.response, receipts: receiptsRes.response});
                    setReviewData({
                        staffNotes: location.state.acquittal.staffNotes,
                        confirmedSpent: location.state.acquittal.calculatedSpent,
                        receipts: receiptsRes.response.map(r => ({id: r.id, fields: {approved: true, value: r.acf.value}}))
                    })
                });
            });
        } else if (!get(location, 'state.acquittal')) {
            navigate(`/account/manage-finances/`);
        }
    }, [auth, authed, setAuthed, location]);

    const handleReviewChange = (field, value) => {
        const _reviewData = { ...reviewData };
        const _field = field.startsWith('staffNotes-') ? 'staffNotes' : field;
        _reviewData[_field] = value;
        setReviewData(_reviewData);
    }

    const toggleApprovedReceipt = (e) => {
        const receiptId = e.target.value;
        const _reviewData = { ...reviewData };
        const _acquittalData = { ...reviewAcquittal };
        let spent = 0;
        _reviewData.receipts = _reviewData.receipts.map(r => {
          const _r = {...r};
          if (Number(r.id) === Number(receiptId)) {
            _r.fields.approved = !_r.fields.approved;
          }
    
          if (_r.fields.approved) spent += Number(_r.fields.value);
    
          return _r;
        });
        _reviewData.confirmedSpent = spent;
        setReviewData(_reviewData);
        _acquittalData.calculatedSpent = spent;
        setReviewAcquittal(_acquittalData);
    }

    const update = () => {
        const object = { ...reviewAcquittal, ...reviewData };
        updateAcquittalRecord(object);
    }

    const approve = () => {
        const { usu: user } = memberDetails;
        const object = { ...reviewAcquittal, ...reviewData };
        object.status = 'approved';
        object.approvedBy = `${user.FirstName} ${user.LastName} (${user.MemberNumber})`
        updateAcquittalRecord(object);
    }
    
    const reject = () => {
        const object = { ...reviewAcquittal, ...reviewData };
        object.status = 'rejected';
        updateAcquittalRecord(object);
    }

    const updateAcquittalRecord = async (object) => {
        setSaving(true);
        try {
            const { usu: user } = memberDetails;
            const fields = {
                fields: {
                    status: object.status,
                    staff_notes: object.staffNotes,
                    confirmed_spent: object.confirmedSpent,
                    reviewed_by: `${user.FirstName} ${user.LastName} (${user.MemberNumber})`,
                    approved_by: object.approvedBy,
                }
            }
        
            // console.log(object, fields);
        
            const updateResponse = await wpApi(`acquittals/${object.id}`, 'POST', fields);
        
            if (String(updateResponse.status).startsWith('2')) {
                if (['approved', 'rejected'].indexOf(object.status) > -1) {
                    const updateReceipts = object.receipts.map(async r => {
                        const rFields = {
                            fields: {
                                approved: r.fields.approved,
                                reviewed_by: `${user.FirstName} ${user.LastName} (${user.MemberNumber})`,
                            }
                        }
                
                        const updateReceiptResponse = await wpApi(`club_receipts/${r.id}`, 'POST', rFields);
                
                        if (String(updateReceiptResponse.status).startsWith('2')) {
                            return true
                        }
                    })
                
                    Promise.all(updateReceipts).then(() => {
                        toast.success('Acquittal updated!');
                        navigate(`/account/manage-finances/`);
                    });
                } else {
                    toast.success('Acquittal updated!');
                    navigate(`/account/manage-finances/`);
                }
            } else {
                toast.error(
                    'An unexpected error has occurred.\nPlease try again later.'
                );
            }
        } catch (error) {
          toast.error('An unexpected error has occurred.\nPlease try again later.');
        } finally {
          setSaving(false);
        }
    }

    const toggleAccordion = (index) => {
        if (openAccordion === index) {
          setAccordion(null);
        } else {
          setAccordion(index);
        }
    }

    return location.state && location.state.acquittal && reviewAcquittal ? (
        <>
            <AccordionCard title="Details" showContent={reviewAcquittal.status === 'Pending' ? true : false}>
                <div className={`grid grid-50 grid-responsive `} style={{alignItems: 'flex-start', marginBottom: '40px'}}>
                    <div>
                        <div><strong>Club:</strong> {decode(reviewAcquittal.club)}</div>
                        <div><strong>Submitted by:</strong> {reviewAcquittal.submittedBy}</div>
                        <div><strong>Date Submitted: </strong>{extractDate(reviewAcquittal.submittedDate)}</div>
                    </div>
                    
                    <div>
                        <strong>Run down:</strong>
                        <div>
                        <div style={{display: "flex", justifyContent: 'space-between'}}><span>Total provided: </span><span><CurrencyFormatter amount={reviewAcquittal.calculatedReceived} /></span></div>
                        <div style={{display: "flex", justifyContent: 'space-between'}}><span>Total spent: </span><span><CurrencyFormatter amount={reviewAcquittal.calculatedSpent} /></span></div>
                        <hr />
                        <div style={{display: "flex", justifyContent: 'space-between'}}><span>Total remaining: </span><span><CurrencyFormatter amount={reviewAcquittal.calculatedReceived - reviewAcquittal.calculatedSpent} /></span></div>
                        </div>
                    </div>
                </div>
                <div className={`grid grid-50 grid-responsive `} style={{alignItems: 'flex-start', marginBottom: '40px'}}>
                    <div>
                        <strong>Summary:</strong>
                        <div>{reviewAcquittal.summary}</div>
                    </div>
                    <div>
                        <strong>Explanation:</strong>
                        <div>{reviewAcquittal.explanation}</div>
                    </div>
                </div>
            </AccordionCard>
            {(reviewAcquittal.status !== 'Pending' || reviewAcquittal.acquittal !== null) && (
                <AccordionCard title="Grants">
                    {reviewAcquittal.grants.map((g, gI) => (
                        <div key={gI} className={`${styles.accordion} ${openAccordion === gI && styles.open}`}>
                            <div className={styles.grantHandle} role="presentation" onClick={() => toggleAccordion(gI)}>
                            <span>{grantTypes.find(gt => gt.id === g.grant_types[0])?.name} - {extractDate(g.date)} (<CurrencyFormatter amount={g.acf.value_provided} />)</span>
                            <span>{reviewAcquittal.receipts.filter(r => r.acf.grant.ID === g.id).length} receipt{reviewAcquittal.receipts.filter(r => r.acf.grant.ID === g.id).length > 1 && 's'}</span>
                            <span><Icon symbol="caretDown" /></span>
                            </div>
                            <div className={styles.receipts}>
                            {reviewAcquittal.receipts.filter(r => r.acf.grant.ID === g.id).map((r, rI) => (
                                <div key={rI} className={styles.receipt}>
                                <span><Checkbox id={`approvedReceipt${r.id}`} name="approvedReceipt" value={r.id} isChecked={(reviewData && reviewData.receipts) ? reviewData.receipts.find(rd => rd.id === r.id)?.fields.approved === true : false} action={toggleApprovedReceipt} /></span>
                                <span><a href={r.acf.receipt} target="_blank" rel="noreferrer noopener"><img src={r.acf.receipt} alt="receipt" /></a></span>
                                <span>{r.acf.date_of_purchase}: {r.acf.description}</span>
                                <span><CurrencyFormatter amount={r.acf.value} /></span>
                                </div>
                            ))}
                            </div>
                        </div>
                    ))}
                </AccordionCard>
            )}
            <AccordionCard title="Staff controls">
                <section className={styles.spacer}>
                    <div>
                        <strong>Staff notes:</strong>
                        <FormInputField type="textarea" id="staffNotes-aqc" value={reviewData.staffNotes} handleChange={handleReviewChange} />
                    </div>
                    {reviewAcquittal.status === 'Pending' && (
                        <>
                            <div className={styles.split}>
                                <Button
                                    type={'button'}
                                    level='primary'
                                    disabled={saving}
                                    onClick={() => update()}
                                    className={styles.submitButton}>
                                    Update
                                </Button>
                                <Button
                                    type={'button'}
                                    level='primary'
                                    disabled={saving}
                                    onClick={() => approve()}
                                    className={styles.submitButton}>
                                    Approve
                                </Button>
                                <Button
                                    type={'button'}
                                    level='secondary'
                                    disabled={saving}
                                    onClick={() => reject()}
                                    className={styles.submitButton}>
                                Reject
                                </Button>
                            </div>
                        </>
                    )}
                    {reviewAcquittal.status !== 'Pending' && (
                        <Button
                            type={'button'}
                            level='primary'
                            disabled={saving}
                            onClick={() => update()}
                            className={styles.submitButton}>
                            Update
                        </Button>
                    )}
                </section>
            </AccordionCard>
        </>
    ) : (
        <div className={styles.root}>
            {/* Fetching club preferences */}
            {(authed === null && !reviewAcquittal) && (
                <div>Fetching acquittal data</div>
            )}
            {/* No grant found */}
            {(authed === true && get(location, 'state.acquittal') === undefined) && (
                <div>An error occurred. Return back to <Link to="/account/manage-finances/"><u>list</u></Link></div>
            )}
            {/* No grant found */}
            {(authed === false) && (
                <div>You are not authorised to manage this acquittal. Return back to <Link to="/account/manage-finances/"><u>list</u></Link></div>
            )}
        </div>
    );
};

const ClubManagementOutput = ({location}) => {
    const breadcrumbs = [{ link: "/account/manage-finances/", label: "Manage Club Finances"}];
    if (location.state && location.state.fromClubManage) {
        breadcrumbs.push({onClick: () => backToClubFinancePage(), label:`Manage ${decode(location.state.clubName)}`});
    }

    const backToClubFinancePage = () =>{
        navigate(`/account/manage-finances/club-details`,{
            state: location.state
        })
    }

    return (
        <AccountPageWrapper bgRaw metaTitle="Account - USU Management" title="Manage Acquittal" breadcrumbTitle="Manage Acquittal" breadcrumbs={breadcrumbs} location={location}>
            <AcquittalDetails location={location} />
        </AccountPageWrapper>

    )
}

export default ClubManagementOutput

