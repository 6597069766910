import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';
import { get } from 'lodash';
import { decode } from 'he';
import { toast } from 'react-toastify';
import AccountPageWrapper from '../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import DataTable from '../../../components/organisms/DataTable/DataTable';
import AuthContext from '../../../context/AuthProvider';
import { hasStaffPermissions, persistLocation } from '../../../helpers/general';
import { wpApi, wpAll, transformWpEvent } from '../../../helpers/wordpress';
import DialogMatter from '../../../components/atoms/Dialog/Dialog';
import Loader from '../../../components/atoms/Loader/Loader';
import Events from '../../../components/organisms/Events/Events';
import FormInputField from '../../../components/atoms/FormInputField/FormInputField';
import Icon from '../../../components/atoms/Icon/Icon';
import Button from '../../../components/atoms/Button/Button';

import * as styles from '../manage-screens.module.css';

const Manage = ({ location }) => {
  const auth = useContext(AuthContext);
  const [authed, setAuthed] = useState(null);
  const [events, setEvents] = useState(null);
  const [loading, setLoading] = useState(false);
  const [previewEvent, setPreviewEvent] = useState(false);
  const [activeView, setActiveView] = useState('pending');
  const [searchQuery, setSearchQuery] = useState('');
  const [quickSearchList, setQuickSearchList] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [csvLoader, setCSVLoader] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [eventListReady, setEventListReady] = useState(false);

  const [tableHeaders, setTableHeaders] = useState([
    { label: 'Event Name', data_key: 'event_name', sortable: true },
    { label: 'Date of Event', data_key: 'date_from', sortable: true, format: 'date' },
    {
      label: 'Date Submitted',
      data_key: 'date_submitted',
      sortable: true,
      format: 'date'
    },
  ]);

  const { allWpEventCategory, events: eventsQuickSearchData } = useStaticQuery(graphql`
    query {
      allWpEventCategory(filter: {name: {regex: "/USU|Partner/i"}}) {
        nodes {
          id: databaseId
        }
      }

      events: allWpEvent {
        nodes {
          event_name: title
          id: databaseId
          slug
        }
      }
    }
  `);

  useEffect(() => {
    if (!quickSearchList && eventsQuickSearchData) {
        const qsList = eventsQuickSearchData?.nodes.map((event) => event);
        setQuickSearchList(qsList);
    }
  }, [eventsQuickSearchData, quickSearchList])

  const fetchEvents = useCallback(async (_activeView) => {
    let fetch = '';
    if (_activeView === 'USU') {
      const categoryIds = allWpEventCategory?.nodes.map(ec => ec.id).join(',');

      fetch = `?event_categories=${categoryIds}`;
      setTableHeaders([
        { label: 'Event Name', data_key: 'event_name', sortable: true },
        { label: 'Date of Event', data_key: 'date_from', sortable: true, format: 'date' },
        {
          label: 'Date Submitted',
          data_key: 'date_submitted',
          sortable: true,
          format: 'date'
        },
      ])
    } else {
      fetch = '?status=draft&per_page=100';
      setTableHeaders([
        { label: 'Event Name', data_key: 'event_name', sortable: true },
        { label: 'Club', data_key: 'club', sortable: true },
        { label: 'Date of Event', data_key: 'date_from', sortable: true, format: 'date' },
        {
          label: 'Date Submitted',
          data_key: 'date_submitted',
          sortable: true,
          format: 'date'
        },
      ])
    }
    const eventsData = await wpAll(`events${fetch}`);


    if (String(eventsData.status).startsWith('2') && eventsData.response) {
      const eventsObj = eventsData.response.map(event => {
        let eventName = <>{decode(event.title.rendered)}</>;

        if (event.acf.recurring_event) {
          eventName = (
            <div className={styles.eventTitle}>
              {decode(event.title.rendered)}
              <p className={styles.recurring} title='Recurring event'>
                {event.acf.recurring_event}
              </p>
            </div>
          );
        }

        const obj = {
          id: event.id,
          event_name: eventName,
          date_from: event.acf.start_date,
          date_submitted: event.date,
        }

        if (_activeView === 'pending') {
          obj.club = event.acf?.club?.post_title;
        }

        return obj;
      });
      setEvents(eventsObj);
    } else {
      // Failed fetch of club data
      setEvents(false);
    }
    setLoading(false);
  }, [allWpEventCategory]); // location

  useMemo(() => {
    if (authed === null && get(auth, 'state.userChecked')) {
      setLoading(true);
      const isStaff = hasStaffPermissions(auth, [2, 3, 4]);
      setAuthed(isStaff);
      if (isStaff) {
        fetchEvents(activeView);
      }
    }
  }, [auth, authed, activeView, setAuthed, fetchEvents]);

  const triggerViewChange = (view) => {
    if (view !== activeView) {
      setLoading(true);
      setEvents(null);
      setActiveView(view);
      fetchEvents(view);
    }
  }

  const preview = async data => {
    const previewData = await wpApi(`events/${data.id}`);
    if (String(previewData.status).startsWith('2') && previewData.response) {
      // console.log(previewData.response);
      const event = transformWpEvent(previewData.response);
      setPreviewEvent(event);
    }
  };

  const edit = async data => {
    navigate(`/account/manage-events/edit/`, {
      state: {
        ...location.state,
        eventId: data.id,
      },
    });
  };

  const remove = async data => {
    const deleteEvent = await wpApi(`events/${data.id}`, 'DELETE');
    if (String(deleteEvent.status).startsWith('2') && deleteEvent.response) {
      toast.success('Event has been deleted.');
      fetchEvents(activeView);
    }
  };

  const handleBulkAction = async (action, data) => {
    try {
      if (data.length > 0) {
        if (action === 'delete') {
          const promises = data.map(event =>
            wpApi(`events/${event.id}`, 'DELETE')
          );
          await Promise.all(promises);
          toast.success('Events have been deleted.');
          fetchEvents(activeView);
        } else if (action === 'approve') {
          const saveData = {
            status: 'publish',
          };
          const promises = data.map(event =>
            wpApi(`events/${event.id}`, 'POST', saveData)
          );
          await Promise.all(promises);
          toast.success('Events have been approved.');
          fetchEvents(activeView);
        }
      }
    } catch (error) {
      toast.success(
        `An error has occured. Failed to ${action} selected events.`
      );
    }
  };

  const handleEventSearch = (id, value) => {
    setSearchQuery(value);
    actionQuickSearch(value);
  }

  const actionQuickSearch = (value) => {
    if (value.length >= 3) {
      const results = quickSearchList.filter(event => {
        const name = `${event.event_name}`;
        return name.toLowerCase().indexOf(value.toLowerCase()) > -1
      });
      setSearchResults(results);
    }
  }

  const fetchEventsList = () => {
    setCSVLoader(true);
    wpAll('events', 100).then(events => {
      if (String(events.status).startsWith('2') && events.response) {
        console.log(events);
        const _eventList = events.response.map(e => ({
          id: e.id,
          submittedDate: e.date,
          eventDate: e.acf.start_date,
          eventTime: e.acf.start_time,
          status: e.status,
          title: e.title.rendered,
          club: e.acf.club?.post_title || "unassigned",
          execEmail: e.acf.exec_email,
          execNumber: e.acf.exec_member_number,
        }))
        setCSVLoader(false);
        setEventListReady(true);
        JSONDownload(_eventList, 'events-list');
      } else {
        console.log("Event fetch worked with error");
        setCSVLoader(false);
      }
    }).catch((e) => {
      console.log(e);
      console.log("Catch events fetch");
      setCSVLoader(false);
    });
  }

  const JSONDownload = (data) => {
    const jsonData = new Blob([JSON.stringify(data)], {type: 'text/json'});
    const href = URL.createObjectURL(jsonData);
    setEventList(href);
  }

  return (
    <>
      {authed && events ? (
        <div
          className={`${styles.root}`}
          style={{ opacity: loading ? '0.5' : '1' }}>
            <>
              {/* Manage Events */}
              <div>
                <span className={`${styles.tab}${activeView === 'pending' ? ` ${styles.active}` : ''}`} role="presentation" onClick={() => triggerViewChange('pending')}>Pending club events</span> 
                <span className={`${styles.tab}${activeView === 'USU' ? ` ${styles.active}` : ''}`} role="presentation" onClick={() => triggerViewChange('USU')}>USU events</span>
              </div>
              <DataTable
                classes={['withTabs']}
                tableData={events}
                bulkActions={[
                  { label: 'Bulk approve', key: 'approve' },
                  { label: 'Bulk delete', key: 'delete' },
                ]}
                handleBulkAction={handleBulkAction}
                allowExport={true}
                fileName="event-submissions"
                headingKeys={tableHeaders}
                rowActions={[
                  { label: 'Edit', cta: data => edit(data) },
                  { label: 'Preview', cta: data => preview(data) },
                  { label: 'Delete', cta: data => remove(data) },
                ]}
                bulkAction={true}
                topActions={[
                  {
                    label: 'New Event',
                    icon: <Icon symbol='fileOther' />,
                    event: () => navigate(`/account/manage-events/new/`, {
                      state: location.state,
                    }),
                  },
                ]}
              />
              <div>
                <Button type="span" link={true} onClick={fetchEventsList}>Download full event list</Button>
                {csvLoader && (
                  <div className={styles.loaderCover}><span><Loader /><strong>Please wait - fetching</strong></span></div>
                )}
                {eventListReady && (
                    <div className={styles.loaderCover}><a href={eventList} download="event-list.json">View result</a></div>
                )}
              </div>
              {loading && (
                <div className={styles.loaderContainer}>
                  <Loader />
                </div>
              )}
              <DialogMatter
                size='lg'
                title='Preview Event'
                hideBtnCancel={true}
                onOk={() => setPreviewEvent(false)}
                open={typeof previewEvent === 'object'}>
                {previewEvent && <Events event={previewEvent} />}
              </DialogMatter>

              {/**
               * Events search
               **/}
              <h6 className='mb-4 mt-6'>Search for an event</h6>
              <div className={`${styles.root} ${styles.withBg}`}>
                <FormInputField placeholder="Search event name" handleChange={handleEventSearch} />
                <div>
                  {(searchQuery && searchQuery.length >= 3) && (
                    <>
                      {searchResults.length === 0 && (
                        <p>No events found</p>
                      )}

                      {searchResults.length > 0 && (
                        <DataTable
                          tableData={searchResults}
                          headingKeys={[
                            { label: 'Event name', data_key: 'event_name', sortable: true },
                          ]}
                          rowActions={[
                            { label: 'Edit', cta: data => edit(data) },
                            { label: 'Preview', cta: data => preview(data) },
                            { label: 'Delete', cta: data => remove(data) },
                          ]}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
        </div>
      ) : (
        <div className={styles.root}>
          {/* Fetching data */}
          {((authed === null && events === null) ||
            (authed && events === null)) && <div>Fetching data</div>}
          {/* No data found */}
          {authed && events === false && (
            <div>
              An error occurred. Return back to{' '}
              <Link to='/account/'>
                <u>dashboard</u>
              </Link>
            </div>
          )}
          {/* Not authorised */}
          {authed === false && (
            <div>
              You're not authorised to manage events. Return back to{' '}
              <Link to='/account/'>
                <u>dashboard</u>
              </Link>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const USUEventManagementOutput = ({ location }) => {
  const persistedLocation = persistLocation(location);
  return (
    <AccountPageWrapper
      metaTitle='Account - USU Management'
      bgRaw
      title={'Manage Events'}
      breadcrumbTitle='Manage Events'>
      <Manage location={persistedLocation} />
    </AccountPageWrapper>
  )
};

export default USUEventManagementOutput;

