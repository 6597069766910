import { Link, navigate } from 'gatsby';
import { get } from 'lodash';
import { decode } from 'he';
// import parse from 'html-react-parser';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import Icon from '../../../../../components/atoms/Icon/Icon';
import AccountPageWrapper from '../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import DataTable from '../../../../../components/organisms/DataTable/DataTable';
import Dialog from '../../../../../components/atoms/Dialog/Dialog';
import EventPreview from '../../../../../components/organisms/Events/Events';
import AuthContext from '../../../../../context/AuthProvider';
import { persistLocation } from '../../../../../helpers/general';
import { wpAll, authClubExec, transformWpEvent, transformWpClub } from '../../../../../helpers/wordpress';
import * as styles from '../../usu.module.css';

const Events = ({ location }) => {
  const auth = useContext(AuthContext);
  const memberNumber = auth && get(auth, 'state.usu.MemberNumber');
  const [isAuthed, setAuthed] = useState(null);
  const [club, setClub] = useState();
  const [events, setEvents] = useState(null);
  const [extraDetail, setExtraDetail] = useState(null);
  const [preview, showPreview] = useState(false);

  const fetchClubEvents = useCallback(async (clubData) => {
    const eventsData = await wpAll(`events?status=draft,publish&club=${location.state.clubId}&upcoming`);
    const extra = {};
    
    if (eventsData.status === 200 && eventsData.response) {
      const eventsObj = eventsData.response.map(async event => {
        event.acf.club = transformWpClub(clubData);
        const eventData = transformWpEvent(event);
        // console.log(eventData);
        const eventTitle = decode(event.title.rendered) || 'Unable to fetch name...';
        extra[event.id] = {
          ...eventData,
          title: eventTitle,

          // id
          // databaseId
          // status
          // uri
          // excerpt
          // featuredImage {
          //     node {
          //         publicUrl
          //         sourceUrl
          //         localFile {
          //             childImageSharp {
          //               fixed(width: 570, height: 570, quality: 75, cropFocus: CENTER) {
          //                 src
          //               }
          //             }
          //         }
          //     }
          // }
          // eventFieldsMain {
          //     dayTripLength
          //     dayTripTimes
          //     dietaryRequirements
          //     domesticInternational
          //     findOutDayTrips
          //     futureDayTrips
          //     limitRegistrationAmount
          //     enableTickets
          // }
          // eventFieldsSide {
          //     startDate
          //     endDate
          //     startTime
          //     endTime
          //     eventFee
          //     location
          //     registrationOverride
          //     ctaLabel
          //     recurringEvent
          //     recurringInfo {
          //         repeatDays
          //         repeatTimes
          //         repeatType
          //     }
          //     timeSlot
          //     roomName
          //     club {
          //         ... on WpClub {
          //             title
          //             uri
          //             id: databaseId
          //             clubFieldsSide {
          //                 logo {
          //                   sourceUrl
          //                   localFile {
          //                     childImageSharp {
          //                       fluid(maxWidth: 300, quality: 100) {
          //                         src
          //                       }
          //                     }
          //                   }
          //                 }
          //                 stripeConnectId
          //             }
          //         }
          //     }
          //     bannerImage {
          //         sourceUrl
          //         localFile {
          //             childImageSharp {
          //               fixed(width: 2000, height: 635, cropFocus: CENTER) {
          //                 src
          //               }
          //             }
          //         }
          //     }
          // }
          // seo {
          //     title
          //     metaDesc
          //     metaKeywords
          //     opengraphTitle
          //     opengraphDescription
          //     opengraphImage {
          //         publicUrl
          //         sourceUrl
          //     }
          //     twitterTitle
          //     twitterDescription
          //     twitterImage {
          //         publicUrl
          //         sourceUrl
          //     }
          // }
          // eventTags {
          //     nodes {
          //         uri
          //         name
          //     }
          // }
        };

        console.log(extra[event.id]);

        return {
            id: event.id,
            status: event.status === 'publish' ? 'Live' : 'Pending',
            event_name: eventTitle,
            date_from: event.acf.start_date,
            // registered_members: 0,
        }
      });
      Promise.all(eventsObj).then(returned => {
        setExtraDetail(extra);
        setEvents(returned);
        setClub(location.state.clubId)
      });
    } else {
      // Failed fetch of club data
      setEvents(false);
    }
  }, [location]);

  useMemo(() => {
    if (isAuthed === null || (isAuthed && club && club !== get(location, 'state.clubId'))) {
        setEvents(null);
        authClubExec(memberNumber, get(location, 'state.clubId')).then(authed => {
            if (String(authed.status).startsWith("2") && authed.response) {
                setAuthed(true);
                fetchClubEvents(authed.response);
            } else {
                setAuthed(false);
            }
        });
    }
  }, [isAuthed, memberNumber, location, fetchClubEvents, club]);

  return (
    <>
      {events ? (
        <div className={`${styles.root}`}>
          {/* Club Edit Details */}
          <DataTable
            tableData={events}
            // bulkActions={[{ label: 'Bulk delete', key: 'delete' }]}
            //    handleBulkAction={handleBulkActions}
            topActions={[
              {
                label: 'New Event',
                icon: <Icon symbol='fileOther' />,
                event: () => navigate(`/account/my-usu/clubs/manage/event-submission`, {
                  state: location.state,
                }),
              },
            ]}
            headingKeys={[
              { label: 'Status', data_key: 'status', sortable: true, filterable: true },
              { label: 'Event Name', data_key: 'event_name', sortable: true },
              { label: 'Date From', data_key: 'date_from', sortable: true, format: 'date', hideMobile: true },
              // {
              //   label: 'Registered Members',
              //   data_key: 'registered_members',
              //   sortable: true,
              //   hideMobile: true
              // },
            ]}
            rowActions={[
              { label: 'Preview', cta: data => showPreview(extraDetail[data.id]) },
              { label: 'Manage attendees', cta: data => navigate('/account/my-usu/clubs/manage/event-guests', {
                state: {
                  ...location.state,
                  eventSlug: extraDetail[data.id].slug,
                  eventId: data.id
                }
              }) },
            ]}
            bulkAction={true}
          />
          <Dialog open={preview === false ? false : true} size="xl" onCancel={() => showPreview(false)} hideBtnCancel={true} hideBtnOk={true}>
            {preview && (
              <EventPreview event={preview} />
            )}
          </Dialog>
        </div>
      ) : (
        <div className={styles.root}>
          {/* Fetching events */}
          {((isAuthed === null && events === null) || (isAuthed === true && events === null)) && (
              <div>Fetching your club events</div>
          )}
          {/* No clubs found */}
          {(isAuthed === true && events === false) && (
              <div>An error occurred. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
          )}
          {/* No clubs found */}
          {(isAuthed === false) && (
              <div>You are not authorised to manage this club. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
          )}
        </div>
      )}
    </>
  );
};

const ClubManagementEvents = ({ location }) => {
  const persistedLocation = persistLocation(location);
  
  const backToManagementPage = () => {
    navigate(`/account/my-usu/clubs/manage`, {
      state: persistedLocation.state,
    });
  };

  return (
    <AccountPageWrapper
      metaTitle='Account - Club Management'
      title='Manage events'
      breadcrumbTitle='Events'
      breadcrumbs={[
        { link: '/account/my-usu/clubs/', label: 'My USU' },
        {
          onClick: () => backToManagementPage(),
          label: get(persistedLocation, 'state.clubTitle', ''),
        },
      ]}
      menuType='clubManage'
      location={persistedLocation}>
      <Events location={persistedLocation} />
    </AccountPageWrapper>
  );
};

export default ClubManagementEvents;
