import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { navigate } from 'gatsby';
import { decode } from "he";
import AccordionCard from '../../atoms/AccordionCard/AccordionCard';
import Button from '../../atoms/Button/Button';
import RichTextField from '../../atoms/RichTextField/RichTextField';
import OrttoEmailTemplate from '../OrttoEmailTemplate/OrttoEmailTemplate';
import MembersList from '../MembersList/MembersList';
import { wpApi } from '../../../helpers/wordpress';
import { sendEmail } from '../../../helpers/ortto';
import { toast } from 'react-toastify';

import * as styles from './ClubCompliant.module.css';

const ClubCompliant = ({ club, auth, forApproval = false }) => {
  const [data, setData] = useState({});
  const [saving, setSaving] = useState(false);
  const [disableActions, setDisable] = useState(false);
  const [testingLabel, setTesting] = useState('Send test');
  const [emailData, setEmailData] = useState({
    subject: '',
    body: '',
  });
  const emailInitial = {
      subject: 'Welcome to USU Clubs',
      body: `
      <p>Hi ${club.acf.details.contact},</p>
      <p>We're excited to inform you that your club application for ${club.title.rendered} has been approved to progress to the next stage of setup!</p>
      <p><strong>What does this mean?</strong></p>
      <p>You now need to organise an IGM meeting, where all the registered interested members will attend and vote in members for positions within the club.</p>
      <p>You have all the necessary tools within your club management area to define the roles the club requires, create an event for the IGM and book a venue, as well as scan in attendees on the day of the event.</p>
      <p>It is important that all attendees are scanned in as this not only records against the club records that you had a majority attend, but it also enables the voting screens within member logins.</p>
      <p>Any questions or issues, please contact USU Staff for assistance.</p>
      `,
  };

//   const submitClubHandler = async () => {
//     try {
//       setSaving(true);
      
//       await wpApi(`clubs/${data.id}`, 'POST', {
//         fields: acfFields,
//       });

//       toast.success('Changes have been sent for approval.');
//     } catch (error) {
//       toast.error('An unexpected error has occured.\nPlease try again.');
//     } finally {
//       setSaving(false);
//     }
//   };

  const approveChangesHandler = async rejected => {
    try {
      setSaving(true);

      let postData = {
        status: 'rejected'
      };
      if (!rejected) {
        postData = {
            status: 'ign',
            fields: {
              agm_functionality: true,
            }
        }
      }

      await wpApi(`clubs/${data.id}`, 'POST', postData);
      if (!rejected) {
        await sendWelcomeEmail();
      }
      toast.success(rejected ? 'Application has been rejected' : 'Application has been moved to the next stage');
      navigate('/account/manage-clubs/');
    } catch (error) {
      toast.error('An unexpected error has occured.\nPlease try again.');
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    const pageInit = async () => {
      const initData = {
        id: club.id,
        full_title: club.acf.full_title,
        club_display_name: club.title.rendered,
        abbr: club.acf.abbr,
        contact: club.acf.details.contact,
        email: club.acf.details.email,
        mobile: club.acf.details.mobile,
        phone: club.acf.details.phone,
        campaign_start_date: club.acf.application_settings?.campaign_start_date || new Date(new Date().getTime() + (-new Date().getTimezoneOffset())*60000).toISOString().split('.')[0],
        accepting_signatures_period: club.acf.application_settings?.accepting_signatures_period || 30,
        minimum_amount_of_signatures_required: club.acf.application_settings?.minimum_amount_of_signatures_required || 20,
      };

      setData({ ...data, ...initData });
      setEmailData(emailInitial);
    };
    pageInit();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const updateValue = (field, value) => {
      const state = {...emailData};
      state[field] = value;
      setEmailData({...state});
  }

  const returnBody = (body) => {
      updateValue('body', body);
  }

  const charCount = () => {
      return 120 - emailData.subject.length;
  }

  const processEmail = (recipients) => {
      // console.log("Club data: ", emailData);
      const emailBody = renderToString(<OrttoEmailTemplate logo={null} clubName={null} content={emailData.body} />);
      // console.log("Email Body:", emailBody);

      const emailOptions = {
          html_body: emailBody,
          subject: emailData.subject,
          email_name: `${club.title.rendered} IGM Welcome email`,
          from_name: 'USU Staff',
          reply_to: 'staff@usu.edu.au'
      };
      
      return sendEmail(emailOptions, recipients).then(postResponse => {
          // console.log(postResponse);

          return postResponse;
      });
  }

  const sendTest = () => {
      setDisable(true);
      setTesting(`Sending test...`);
      const emailRecipients = [{
          email: auth.email,
          first: auth.usu.FirstName,
          last: auth.usu.LastName
      }];
      // const emailRecipients = [{
      //     email: "ash@matterdesign.com.au",
      //     first: "Ash",
      //     last: "Durham"
      // }];

      processEmail(emailRecipients).then(result => {
          if (String(result.status).startsWith("2") && 'emails' in result.response) {
              setDisable(false);
              setTesting('Sent!');
              setTimeout(() => {
                  setTesting(`Send test`);
              }, 5000);
          } else {
              setDisable(false);
              // TODO: Inform that something went wrong
          }
      });
  }

  const sendWelcomeEmail = () => {
      setDisable(true);
      
      const emailRecipients = [{
          email: club.acf.details.email,
          first: club.acf.details.contact.split(' ')[0],
          last: club.acf.details.contact.split(' ')[1]
      }];
      // const emailRecipients = [{
      //     email: "ash@matterdesign.com.au",
      //     first: "Ash",
      //     last: "Durham"
      // }];

      processEmail(emailRecipients).then(result => {
          if (result) {
              setTimeout(() => {
                  setDisable(false);
              }, 5000);
          } else {
              setDisable(false);
              // TODO: Inform that something went wrong
          }
      });
  }

  return (
    <>
      <AccordionCard title={'Club Details'}>
          <div className={styles.root}>
              <div>Club Name: {decode(club.title.rendered)}</div>
              <div>Contact: {data?.contact} (M: {data?.mobile} | E: {data?.email})</div>
          </div>
      </AccordionCard>

      <AccordionCard title={'Interested members'} contentPadding={false}>
          <div className={styles.root}>
              <MembersList clubSlug={club.slug} />
          </div>
      </AccordionCard>

      <AccordionCard title={'Welcome email'}>
          <div className={styles.root}>
              {/* Create  */}
              <div className="formField">
                  <label htmlFor="emailSubject">Email subject <span className="floatRight">{charCount()} characters remaining</span></label>
                  <input type="text" id="emailSubject" defaultValue={emailInitial.subject} onChange={(e) => updateValue('subject', e.target.value)} />
              </div>
              <div className="formField">
                  <label htmlFor="emailBody">Email body</label>
                  <RichTextField defaultValue={emailInitial.body} returnValue={returnBody} />
              </div>
              <div className="buttonGroup stretch">
                  <Button level="secondary" type="button" disabled={disableActions} onClick={() => sendTest()}>{testingLabel}</Button>
              </div>
          </div>
      </AccordionCard>
      
      {forApproval ? (
        <div className={`grid grid-50 grid-responsive `}>
          <Button
            disabled={saving}
            type={'button'}
            onClick={() => approveChangesHandler(true)}
            level={'secondary'}>
            Reject
          </Button>
          <Button
            disabled={saving}
            type={'button'}
            onClick={() => approveChangesHandler(false)}
            level={'primary'}>
            Approve
          </Button>
        </div>
      ) : (
        <></>
        // <Button
        //   disabled={saving}
        //   type={'button'}
        //   onClick={submitClubHandler}
        //   level={'primary'}>
        //   Submit changes
        // </Button>
      )}
    </>
  );
};

export default ClubCompliant;

