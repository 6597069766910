/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useContext, useEffect } from "react"
import { get } from 'lodash'
import AuthContext from '../context/AuthProvider';
import CartContext from '../context/CartProvider';
import ContentfulContext from "../context/ContentfulProvider";
import Layout from "../components/organisms/Layout/Layout"
import Seo from "../components/organisms/Seo/Seo"
import Container from "../components/atoms/Container/Container"
import PageTitle from "../components/atoms/PageTitle/PageTitle"
import Form from "../components/organisms/SignupForm/SignupForm";
import ContentfulRichText from "../components/atoms/ContentfulRichText/ContentfulRichText";

import * as styles from './signup.module.css'

const SignupPage = () => {
    const auth = useContext(AuthContext);
    const isLoggedIn = auth && auth.state.isLoggedIn;
    const userChecked = auth && auth.state.userChecked;
    const siteConfig = useContext(ContentfulContext);
    const noticeContent = siteConfig?.membershipNotice;
    // console.log(siteConfig);
    const cart = useContext(CartContext);
    const rewardSkus = ['20183', '13610'];
    
    useEffect(() => {
        if (isLoggedIn && typeof window !== 'undefined') {
            window.location = '/account/';
        }
    }, [isLoggedIn]);

    useEffect(() => {
        let alreadyAdded = false;
        const cartItems = get(cart, 'state.cart.lineItems', {});
        Object.keys(cartItems).forEach(itemType => {
            cartItems[itemType].forEach(lineItem => {
                if (rewardSkus.indexOf(lineItem.sku) > -1) {
                    alreadyAdded = true;
                }
            });
        });
        
        if (alreadyAdded && typeof window !== 'undefined') {
            window.location = '/confirm-order/?mpaa=true';
        }
    }, [cart])

    if (!isLoggedIn && userChecked) {
        return (
        <Layout>
            <Seo title="Join USU" />
            <Container>
                <PageTitle title="Join USU"  titleClass={styles.title}/>
                <p className={styles.subtitle}>Get the most out of Uni Life.</p>
                <div className={styles.notice}>
                    <ContentfulRichText raw={noticeContent.raw}></ContentfulRichText>
                </div>
                <Form />
            </Container>
        </Layout>
        )
    } else {
        return null;
    }
  }
  
  export default SignupPage
  