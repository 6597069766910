import React, { useContext, useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { get } from "lodash"
import { decode } from 'he';
import { toast } from 'react-toastify';
import AuthContext from '../../../context/AuthProvider';
import { hasStaffPermissions } from '../../../helpers/general';
import { wpApi, wpAll, createNewAcquittal } from '../../../helpers/wordpress';
import AccountPageWrapper from '../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import AccordionCard from '../../../components/atoms/AccordionCard/AccordionCard';
import FormInputField from '../../../components/atoms/FormInputField/FormInputField';
import Button from '../../../components/atoms/Button/Button';

import Details from '../../../components/molecules/GrantDetails/GrantDetails';
import Receipts from '../../../components/organisms/Receipts/Receipts';
import { emailer } from '../../../helpers/emailer';
import { processFundingData } from '../../../helpers/usuapi';

// import * as styles from './../details.module.css';
const styles = {
    split: {
        display: 'flex',
        gap: '20px'
    },
    spacer: {
        padding: '40px'
    }
}
// import Acquittal from './acquittal';

const GrantDetails = ({ location }) => {
    const auth = useContext(AuthContext);
    const memberDetails = auth && get(auth, 'state');
    const [authed, setAuthed] = useState(null);
    const [spent, setSpent] = useState(null);
    const [saving, setSaving] = useState(false);
    const [reviewData, setReviewData] = useState({});
    const [buttons, setButtons] = useState([]);

    useEffect(() => {
        if (authed === null && get(auth, 'state.userChecked') && get(location, 'state.grant')) {
            setAuthed(hasStaffPermissions(auth, [2, 3, 4]));
            setReviewData({
                staffNotes: location.state.grant.staffNotes,
                valueProvided: location.state.grant.status === 'Pending' && location.state.grant.valueProvided === '0' ? location.state.grant.valueGranted : location.state.grant.valueProvided
            });

            rejuvenateButtons();
        } else if (!get(location, 'state.grant')) {
            navigate(`/account/manage-finances/`);
        }
    }, [auth, authed, setAuthed, location]);

    const updateSpent = (value) => {
        setSpent(value);
    }

    const rejuvenateButtons = () => {
        const _buttons = [];
            
        if (get(location, 'state.grant.status') === 'Pending') {
            // Handle pending status
            _buttons.push({function: () => update(), label: 'Update', level: 'primary', theme: 'evenWidth'});
            // Handle level 2 auth
            if (hasStaffPermissions(auth, [2])) {
            // if (1 === 1) {
                _buttons.push({function: () => approve(), label: 'Approve', level: 'primary', theme: 'evenWidth'});
            }
            // Handle level 3, 4 auth
            if (hasStaffPermissions(auth, [3, 4])) {
            // if (1 === 2) {
                _buttons.push({function: () => approve(), label: 'Approve', level: 'primary', theme: 'evenWidth'});
                _buttons.push({function: () => pay(), label: 'Approve & Process Payment', level: 'primary', theme: 'evenWidth'});
            }
            _buttons.push({function: () => reject(), label: 'Reject', level: 'secondary', theme: 'evenWidth'});
        } else if (get(location, 'state.grant.status') === 'Approved' && hasStaffPermissions(auth, [3, 4])) {
        // } else if (get(location, 'state.grant.status') === 'Approved' && 1 === 2) {
            // Handle pending status & level 3 and 4 auth
            _buttons.push({function: () => update(), label: 'Update', level: 'primary', theme: 'evenWidth'});
            _buttons.push({function: () => pay(), label: 'Process Payment', level: 'primary', theme: 'evenWidth'});
            _buttons.push({function: () => reject(), label: 'Reject', level: 'secondary', theme: 'evenWidth'});
        } else if (['Pending', 'Approved'].indexOf(get(location, 'state.grant.status')) === -1) {
            _buttons.push({function: () => update(), label: 'Update', level: 'primary', theme: ''});
        }

        setButtons(_buttons);
    }

    useEffect(() => {
        rejuvenateButtons();
    }, [reviewData]);

    const handleReviewChange = (field, value) => {
        const _field = field.startsWith('staffNotes-') ? 'staffNotes' : field;
        setReviewData((data) => ({ ...data, [_field]: value }));
    }

    const update = () => {
        const object = { ...location.state.grant, ...reviewData };
        updateGrantRecord(object);
    }

    const approve = async () => {
        const { usu: user } = memberDetails;
        const object = { ...location.state.grant, ...reviewData };
        object.status = 'approved';
        object.approvedBy = `${user.FirstName} ${user.LastName} (${user.MemberNumber})`
        if (object.valueProvided === "0") object.valueProvided = object.valueGranted;

        if (['camp', 'welcome fest'].indexOf(object.type.toLowerCase()) > -1) {
            const { response: _receipts } = await wpAll(`club_receipts?grant=${object.id}&club=${object.clubId}&_fields=id`);
    
            // Generate acquittal
            const acquittalFields = {
                summary: object.reason,
                explanation: '',
                club: object.clubId,
                status: 'pending',
                grants: [{grant: object.id}],
                receipts: _receipts.map(r => ({receipt: r.id})),
                no_amount_spent: false,
                calculated_received: object.valueGranted,
                calculated_spent: object.valueGranted,
                submitted: `${new Date().getFullYear()}-${String(Number(new Date().getMonth()) + 1).padStart(2, '0')}-${new Date().getDate()}`,
                submitted_by: object.submittedBy // `${user.FirstName} ${user.LastName} (${user.MemberNumber})`,
            };
      
            const newAcquittalData = {
                club: {
                  clubId: object.clubId,
                  clubTitle: object.club,
                //   clubStatus: club.acf.status.label,
                },
                fields: acquittalFields
            };
      
            const { response: newAcquittal } = await createNewAcquittal(
                newAcquittalData
            );
        }

        updateGrantRecord(object);
    }

    const pay = () => {
        const { usu: user } = memberDetails;
        const object = { ...location.state.grant, ...reviewData };
        object.status = 'paid';
        object.paidBy = `${user.FirstName} ${user.LastName} (${user.MemberNumber})`
        if (object.valueProvided === "0") object.valueProvided = object.valueGranted;
        updateGrantRecord(object);
    }
    
    const reject = () => {
        const object = { ...location.state.grant, ...reviewData };
        object.status = 'rejected';
        object.valueProvided = "0";
        updateGrantRecord(object);
    }

    const updateGrantRecord = async (object) => {
        setSaving(true);
        try {
            const { usu: user } = memberDetails;
            const fields = {
                fields: {
                    status: object.status,
                    staff_notes: object.staffNotes,
                    value_provided: object.valueProvided,
                    reviewed_by: `${user.FirstName} ${user.LastName} (${user.MemberNumber})`,
                    approved_by: object.approvedBy,
                    paid_by: object.paidBy,
                }
            }

            const paymentResponse = [];
            if (['paid'].indexOf(object.status) > -1) {
                // Send payment
                const vendorCode = get(location, 'state.grant.vendorcode');
                if (!vendorCode || vendorCode.length !== 6) {
                    toast.error('Club contains invalid vendor code');
                    throw "error";
                }
                const fundingData = {
                    data: [{
                        FundingID: Number(object.id),
                        description: `${object.type} Grant`,
                        club_id: Number(get(location, 'state.grant.clubId')),
                        vendorcode: vendorCode,
                        Amount: Number(parseFloat(object.valueProvided).toFixed(2))
                    }]
                }
                
                paymentResponse.push(await processFundingData(fundingData));
            }

            const paymentResult = await Promise.all(paymentResponse);
            
            if (
                (paymentResult.length === 0) || 
                (paymentResult.length > 0 && paymentResult[0].response.indexOf("Success") > -1)
            ) {

                const updateResponse = await wpApi(`grants/${object.id}`, 'POST', fields);
            
                if (String(updateResponse.status).startsWith('2')) {
                    if (['paid', 'rejected'].indexOf(object.status) > -1) {
                        emailer(object).then(postResponse => {
                            // console.log(postResponse);
                    
                            toast.success('Grant updated!');
                            navigate(`/account/manage-finances/`);
                    
                            return postResponse;
                        });
                    } else {
                        toast.success('Grant updated!');
                        navigate(`/account/manage-finances/`);
                    }
                } else {
                    toast.error(
                    'An unexpected error has occurred.\nPlease try again later.'
                    );
                }
            } else {
                toast.error('An unexpected error has occurred.\nPlease try again later.');
                console.error(paymentResult[0].response);
            }
        } catch (error) {
          toast.error('An unexpected error has occurred.\nPlease try again later.');
        } finally {
          setSaving(false);
        }
    }

    return location.state && location.state.grant ? (
        <>
            <AccordionCard title="Details" showContent={['Pending', 'Approved'].indexOf(get(location, 'state.grant.status')) !== -1 ? true : false}>
                <Details grant={get(location, 'state.grant')} club={get(location, 'state')} spent={spent} sharedStyles={styles} forApproval={true} />
            </AccordionCard>
            {(['Pending', 'Approved'].indexOf(get(location, 'state.grant.status')) === -1 || (get(location, 'state.grant.acquittal'))) && (
                <AccordionCard title="Receipts">
                    <Receipts clubId={get(location, 'state.grant.clubId')} grantId={get(location, 'state.grant.id')} hasAcquittal={get(location, 'state.grant.acquittal')} remit={updateSpent} forApproval={true} />
                </AccordionCard>
            )}
            <AccordionCard title="Staff controls">
                <section style={styles.spacer}>
                    <div>
                        <strong>Staff notes:</strong>
                        <FormInputField type="textarea" id="staffNotes-grt" value={reviewData.staffNotes} handleChange={handleReviewChange} />
                    </div>
                    {['Pending', 'Approved'].indexOf(get(location, 'state.grant.status')) > -1 && (
                        <div>
                            <strong>Amount approved:</strong>
                            <FormInputField type="text" id="valueProvided" value={reviewData.valueProvided} handleChange={handleReviewChange} />
                        </div>
                    )}

                    <div style={styles.split}>
                        {buttons.map((b, bI) => (
                            <Button
                                key={bI}
                                type={'button'}
                                level={b.level}
                                theme={b.theme}
                                disabled={saving}
                                onClick={b.function}
                                className={styles.submitButton}>
                                {b.label}
                            </Button>
                        ))}
                    </div>
                </section>
            </AccordionCard>
        </>
    ) : (
        <div className={styles.root}>
            {/* Fetching club preferences */}
            {(authed === null) && (
                <div>Fetching grant data</div>
            )}
            {/* No grant found */}
            {(authed === true && get(location, 'state.grant') === undefined) && (
                <div>An error occurred. Return back to <Link to="/account/manage-finances/"><u>list</u></Link></div>
            )}
            {/* No grant found */}
            {(authed === false) && (
                <div>You are not authorised to manage this grant. Return back to <Link to="/account/manage-finances/"><u>list</u></Link></div>
            )}
        </div>
    );
};

const ClubManagementOutput = ({location}) => {
    const breadcrumbs = [{ link: "/account/manage-finances/", label: "Manage Club Finances"}];
    if (location.state && location.state.fromClubManage) {
        breadcrumbs.push({onClick: () => backToClubFinancePage(), label:`Manage ${decode(location.state.clubName)}`});
    }

    const backToClubFinancePage = () =>{
        navigate(`/account/manage-finances/club-details`,{
            state: location.state
        })
    }

    return (
        <AccountPageWrapper bgRaw metaTitle="Account - USU Management" title="Manage Grant" breadcrumbTitle="Manage Grant" breadcrumbs={breadcrumbs} location={location}>
            <GrantDetails location={location} />
        </AccountPageWrapper>

    )
}

export default ClubManagementOutput

