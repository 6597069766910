// import { Link } from "gatsby";
import React, { useCallback, useContext, useMemo, useState } from "react";
import Dialog from "../../../components/atoms/Dialog/Dialog";
import MyUsuEvent from "../../../components/atoms/MyUsu/MyUsuEvent";
import AccountPageWrapper from "../../../components/organisms/AccountPageWrapper/AccountPageWrapper";
import MyUsuTab from "../../../components/organisms/MyUsuTab/MyUsuTab";
import AuthContext from "../../../context/AuthProvider";
import { generateQR } from "../../../helpers/general";
import { getPerson, updatePerson } from "../../../helpers/ortto";
import { wpApi } from "../../../helpers/wordpress";

import * as styles from './usu.module.css';

const Events = () => {

    const auth = useContext(AuthContext);
    const userEmail = auth && auth.state.email;
    const memberNumber = auth && auth.state.usu.MemberNumber;
    const [eventList, setEventList] = useState(null);
    const [qrCode, setQrCode] = useState(false);

    const fetchEventList = useCallback(async () => {
        const result = await getPerson(['Tags'], userEmail);
        if (result.status === 200 && "contacts" in result.response && Array.isArray(result.response.contacts)) {
            // Successfully found
            if (result.response.contacts[0].fields.tags) {
                const eventSlugs = result.response.contacts[0].fields.tags.filter(tag => tag.indexOf('event-') === 0).map(tag => tag.replace('event-', ''));
                if (eventSlugs.length > 0) {
                    const events = await wpApi(`events?slug=${eventSlugs.join(',')}`);
                    if (events.status === 200 && events.response.length > 0) {
                        setEventList(events.response);
                    } else {
                        // Failed fetch of event data
                        setEventList(false);
                    }
                } else {
                    // No event tags found
                    setEventList(false);
                }
            } else {
                // No tags found
                setEventList(false);
            }
        } else {
            // Failed fetch of tags
            setEventList(false);
        }
    }, [userEmail]);

    useMemo(() => {
        if (eventList === null) {
            fetchEventList();
        }
    }, [eventList, fetchEventList]);

    const leaveEvent = async (slug) => {
        const eventKey = `event-${slug}`;
        const userKey = userEmail;

        const person = [
            {
                email: userKey,
                unset_tags: [eventKey],
            }
        ];
        const updateResponse = await updatePerson(person);

        if (updateResponse.status === 200 && "people" in updateResponse.response) {
            // Successfully added
            fetchEventList();
        } else {
            // Error occurred
            // TODO: Show error somewhere
        }
    }

    const signInEvent = async (eventId) => {
        const theCode = await generateQR({memberId: memberNumber, eventId});
        setQrCode(theCode);
    }

    return (
        <div className={styles.root}>
            {/* List events */}
            <p>Here is the list of all events you have joined:</p>
            {(eventList && Array.isArray(eventList)) ? (
                <div >
                    {eventList.map((event, eventIndex) => (
                        <MyUsuEvent key={eventIndex} event={event} onLeave={leaveEvent} onSignIn={signInEvent} hasLink />
                    ))}
                </div>
            ) : (
                <div >
                    {/* Fetching events */}
                    {eventList === null && (
                        <div>Fetching your events</div>
                    )}

                    {/* No events found */}
                    {eventList === false && (
                        // https://matterdesign.atlassian.net/browse/USU-496
                        <div>You have not registered for any <a target='_blank' rel='noopener noreferrer' href="/events"><u>events</u></a></div>
                    )}
                </div>
            )}
            <Dialog open={qrCode} size="xs" onOk={() => setQrCode(false)} hideBtnCancel hideBtnClose><div style={{textAlign: 'center'}}><img src={qrCode} alt="QR Code" style={{width: '100%'}}/></div></Dialog>
        </div>
    )
}

const EventsOutput = () => (
    <AccountPageWrapper metaTitle="Account - My USU" breadcrumbTitle={'Events'} title="Welcome to my USU Portal">
        <MyUsuTab pageId={'events'}>
            <Events />
        </MyUsuTab>
    </AccountPageWrapper>

)

export default EventsOutput