import React, { useContext, useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import { get } from "lodash"
import AuthContext from "../../../../../../../context/AuthProvider";
import { persistLocation } from "../../../../../../../helpers/general";
import { authClubExec } from '../../../../../../../helpers/wordpress';
import AccountPageWrapper from "../../../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper"
import ReceiptsList from "../../../../../../../components/organisms/Receipts/Receipts";

import * as styles from '../../../../usu.module.css';

const Receipts = ({location}) => {
    const auth = useContext(AuthContext);
    const memberNumber = auth && get(auth, 'state.usu.MemberNumber');
    const [isAuthed, setAuthed] = useState(null);
    const [club, setClub] = useState(null);

    useEffect(() => {
        if (isAuthed === null || (isAuthed && club  && club.id !== get(location, 'state.clubId'))) {
            authClubExec(memberNumber, get(location, 'state.clubId')).then(authed => {
                if (String(authed.status).startsWith("2") && authed.response) {
                    setAuthed(true);
                    setClub(authed.response);
                } else {
                    setAuthed(false);
                }
            });
        }
    }, [isAuthed, memberNumber, location, club]);

    return (
        <>
            {club ? (
                <>
                    <ReceiptsList 
                        clubId={get(location, 'state.clubId')}
                        type={'all'}
                        withRowActions={true}
                        bulkActions={true}
                        filterGrant={true}
                    />
                </>
            ) : (
                <div className={styles.root}>
                    {/* Fetching club preferences */}
                    {((isAuthed === null && club === null) || (isAuthed === true && club === null)) && (
                        <div>Fetching receipt data</div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === true && club === false) && (
                        <div>An error occurred. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === false) && (
                        <div>You are not authorised to manage this club. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                </div>
            )}
        </>
    )
}

const ClubManagementOutput = ({location}) => {
    const persistedLocation = persistLocation(location);
    
    const backToManagementPage = () =>{
        navigate(`/account/my-usu/clubs/manage`,{
            state: persistedLocation.state
        })
    }

    const backToFinancesPage = () =>{
        navigate(`/account/my-usu/clubs/manage/finances`,{
            state: persistedLocation.state
        })
    }

    return (
        <AccountPageWrapper bgRaw metaTitle="Account - Club Management" title="Manage Receipts" breadcrumbTitle="Manage Club Receipts" breadcrumbs={[{ link: '/account/my-usu/clubs/', label: 'My USU' }, { onClick: () => backToManagementPage(), label: get(persistedLocation, 'state.clubTitle', '')}, { onClick: () => backToFinancesPage(), label: "Club Finances"}]} menuType="clubManage" location={persistedLocation}>
            <Receipts location={persistedLocation} />
        </AccountPageWrapper>

    )
}

export default ClubManagementOutput