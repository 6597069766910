import React from 'react';
import parse from 'html-react-parser'

import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
import Container from '../../atoms/Container/Container';

import * as styles from './FreshDeskArticle.module.css';
import Button from '../../atoms/Button/Button';

const FreshDeskArticle = ({category, folder, article}) => {
  
  return (
    <div className={ styles.FreshDeskArticle }>
      <Container>
      <Breadcrumbs crumbs={[{ link: '/', label: 'Home' }, { link: '/faq', label: `${category.name}` }, { link: '/faq', label: `${folder.name}` }, { label: `${article?.title}` }]} />
        <div className={styles.contentContainer}>
          <h1 className={styles.title}>{article?.title}</h1>
          {article?.description && parse(article?.description)}
        </div>
        <div className={styles.articleFooterContainer}>
          <h2>Still have questions?</h2>
          <Button size={'noWidth'} href={'/contact'} type={'a'} level={'primary'}>Contact Us</Button>
        </div>
      </Container>
    </div>
  );
};

export default FreshDeskArticle;
