import { Link, navigate } from 'gatsby';
import { get } from 'lodash';
// import parse from 'html-react-parser';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import AccountPageWrapper from '../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import DataTable from '../../../../../components/organisms/DataTable/DataTable';
import AuthContext from '../../../../../context/AuthProvider';
import { persistLocation } from '../../../../../helpers/general';
import { wpApi, wpAll, authClubExec } from '../../../../../helpers/wordpress';
import * as styles from '../../usu.module.css';

const History = ({ location }) => {
  const auth = useContext(AuthContext);
  const memberNumber = auth && get(auth, 'state.usu.MemberNumber');
  const [isAuthed, setAuthed] = useState(null);
  const [history, setHistory] = useState(null);
  const [club, setClub] = useState();

  const fetchHistory = useCallback(async () => {
    const historyData = await wpAll(`igm_agm_history?club=${location.state.clubId}`);
    if (String(historyData.status).startsWith('2') && historyData.response) {
      const eventIds = historyData.response.map(h => h.acf.event.ID).filter(id => id);
      const eventsData = await wpAll(`events?include=${eventIds.join(',')}`);
      if (String(eventsData.status).startsWith('2') && eventsData.response) {
        // console.log(eventsData);
        const historyObj = historyData.response.map(h => {
          // console.log(h);
          if (h.acf.event) {
            const eventData = eventsData.response.find(e => e.id === h.acf.event.ID);
            const status = `${h.acf.status.charAt(0).toUpperCase()}${h.acf.status.slice(1)}`;
            const resultBy = h.acf.result_by ? h.acf.result_by.split(' (')[0] : 'Undisclosed';
            const result = h.acf.result ? `${h.acf.result.charAt(0).toUpperCase()}${h.acf.result.slice(1)} by ${resultBy}` : 'Unknown';
            return {
                id: h.id,
                event_name: h.acf.event.post_title,
                event_date: eventData ? eventData.acf.start_date : 'Not found',
                status,
                result
            }
          } else return false;
        }).filter(h => h);
        // console.log(historyObj);
        // Promise.all(historyObj).then(returned => {
          setHistory(historyObj);
          setClub(location.state.clubId)
        // });
      } else {
        // Failed fetch of club data
        setHistory(false);
      }
    } else {
      // Failed fetch of club data
      setHistory(false);
    }
  }, [location]);

  useMemo(() => {
    if (isAuthed === null || (isAuthed && club && club !== get(location, 'state.clubId'))) {
        setHistory(null)
        authClubExec(memberNumber, get(location, 'state.clubId')).then(authed => {
            if (String(authed.status).startsWith("2") && authed.response) {
                setAuthed(true);
                fetchHistory();
            } else {
                setAuthed(false);
            }
        });
    }
  }, [isAuthed, memberNumber, location, fetchHistory , club]);

  return (
    <>
      {history ? (
        <div className={`${styles.root}`}>
          {/* Club Edit Details */}
          <DataTable
            tableData={history}
            defaultSortType='desc'
            headingKeys={[
              { label: 'Event Date', data_key: 'event_date', sortable: true, format: 'date' },
              { label: 'Event Name', data_key: 'event_name', sortable: true },
              { label: 'Status', data_key: 'status' },
              { label: 'Result', data_key: 'result' },
            ]}
            rowActions={[
                { label: 'Details', cta: data =>
                    navigate('/account/my-usu/clubs/manage/view-gm/', {
                        state: {
                            ...location.state,
                            historyId: data.id
                        },
                    }),
                },
              
            ]}
          />
        </div>
      ) : (
        <div className={styles.root}>
          {/* Fetching events */}
          {((isAuthed === null && history === null) || (isAuthed === true && history === null)) && (
              <div>Fetching your club history</div>
          )}
          {/* No clubs found */}
          {(isAuthed === true && history === false) && (
              <div>An error occurred. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
          )}
          {/* No clubs found */}
          {(isAuthed === false) && (
              <div>You are not authorised to manage this club. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
          )}
        </div>
      )}
    </>
  );
};

const ClubManagementHistory = ({ location }) => {
  const persistedLocation = persistLocation(location);
  
  const backToManagementPage = () => {
    navigate(`/account/my-usu/clubs/manage`, {
      state: persistedLocation.state,
    });
  };

  return (
    <AccountPageWrapper
      metaTitle='Account - Club Management'
      title={`General Meeting History`}
      breadcrumbTitle='General Meeting History'
      breadcrumbs={[
        { link: '/account/my-usu/clubs/', label: 'My USU' },
        {
          onClick: () => backToManagementPage(),
          label: get(persistedLocation, 'state.clubTitle', ''),
        },
      ]}
      menuType='clubManage'
      location={persistedLocation}>
      <History location={persistedLocation} />
    </AccountPageWrapper>
  );
};

export default ClubManagementHistory;
