import React, { useContext, useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { get } from "lodash"
import AuthContext from "../../../../../../../context/AuthProvider";
import { persistLocation } from '../../../../../../../helpers/general';
import { authClubExec } from '../../../../../../../helpers/wordpress';
import AccountPageWrapper from '../../../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper';

import * as styles from './../details.module.css';
import AcquittalSubmissionForm from '../../../../../../../components/molecules/AcquittalSubmissionForm/AcquittalSubmissionForm';

const AcquittalSubmission = ({ location }) => {
    const auth = useContext(AuthContext);
    const memberNumber = auth && get(auth, 'state.usu.MemberNumber');
    const member = auth && get(auth, 'state.usu');
    const [isAuthed, setAuthed] = useState(null);
    const [club, setClub] = useState(null);

    useEffect(() => {
        if (isAuthed === null || (isAuthed && club  && club.id !== get(location, 'state.clubId'))) {
            authClubExec(memberNumber, get(location, 'state.clubId')).then(authed => {
                if (String(authed.status).startsWith("2") && authed.response) {
                    setAuthed(true);
                    setClub(authed.response);
                } else {
                    setAuthed(false);
                }
            });
        }
    }, [isAuthed, memberNumber, location, club]);

    return (
        <>
            {isAuthed && club ? (
                <>
                    <AcquittalSubmissionForm club={club} member={member} location={location} />
                </>
            ) : (
                <div className={styles.root}>
                    {/* Fetching club preferences */}
                    {(isAuthed === null) && (
                        <div>Authorising session...</div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === true && club === false) && (
                        <div>An error occurred. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === false) && (
                        <div>You are not authorised to manage this club. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                </div>
            )}
        </>
    )
};

const ClubManagementOutput = ({location}) => {
    const persistedLocation = persistLocation(location);
    
    const backToManagementPage = () =>{
        navigate(`/account/my-usu/clubs/manage`,{
            state: persistedLocation.state
        })
    }

    const backToFinancesPage = () =>{
        navigate(`/account/my-usu/clubs/manage/finances`,{
            state: persistedLocation.state
        })
    }

    const backToAcquittalsPage = () =>{
        navigate(`/account/my-usu/clubs/manage/finances/acquittals`,{
            state: persistedLocation.state
        })
    }

    return (
        <AccountPageWrapper bgRaw metaTitle="Account - Club Management" title="Acquittal Submission" breadcrumbTitle="Acquittal Submission" breadcrumbs={[{ link: '/account/my-usu/clubs/', label: 'My USU' }, { onClick: () => backToManagementPage(), label: get(persistedLocation, 'state.clubTitle', '')}, { onClick: () => backToFinancesPage(), label: "Club Finances"}, { onClick: () => backToAcquittalsPage(), label: "Manage Acquittals"}]} menuType="clubManage" location={persistedLocation}>
            <AcquittalSubmission location={persistedLocation} />
        </AccountPageWrapper>

    )
}

export default ClubManagementOutput

