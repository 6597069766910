import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';
import { get } from 'lodash';
import { decode } from 'he';
import { toast } from 'react-toastify';
import AuthContext from '../../../context/AuthProvider';
import { hasStaffPermissions, persistLocation } from '../../../helpers/general';
import { wpApi, wpAll, createNewAcquittal} from '../../../helpers/wordpress';
import { processFundingData } from '../../../helpers/usuapi';
import AccountPageWrapper from '../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import DataTable from '../../../components/organisms/DataTable/DataTable';
import Loader from "../../../components/atoms/Loader/Loader";
import FormInputField from '../../../components/atoms/FormInputField/FormInputField';
import { emailer } from '../../../helpers/emailer';

// import * as styles from '../my-usu/dashboard.module.css';
import * as styles from '../manage-screens.module.css';

const Manage = ({ location }) => {
  const auth = useContext(AuthContext);
  const memberDetails = auth && get(auth, 'state');
  const [authed, setAuthed] = useState(null);
  const [financeList, setFinanceList] = useState(null);
  const [activeView, setActiveView] = useState('pendingGrants');
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [quickSearchList, setQuickSearchList] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const [rowActions, setRowActions] = useState([]);

  const {
      allWpGrantType: { nodes: grantTypes },
      clubs: { nodes: clubList },
      pendingClubs: { nodes: pendingClubList },
  } = useStaticQuery(graphql`
      query {
        allWpGrantType {
          nodes {
            name
            id: databaseId
          }
        }

        clubs: allWpClub {
          nodes {
            club_name: title
            id: databaseId
            slug
            clubFieldsMain {
              abbr
            }
            clubFieldsSide {
              vendorCode
            }
          }
        }
  
        pendingClubs: allWpPendingClub {
          nodes {
            club_name: title
            id: databaseId
            slug
            clubFieldsMain: acf {
              abbr
            }
            clubFieldsSide: acf {
              vendorCode: vendor_code
            }
          }
        }
      }
  `);

  const financeHeadings = {
    pendingGrants: [
      { label: 'Type', data_key: 'type', sortable: true, filterable: true },
      {
        label: 'Amount Requested',
        data_key: 'valueGranted',
        sortable: false,
        format: 'currency',
      },
      { label: 'Club', data_key: 'club', sortable: true, filterable: true },
      { label: 'Submitted by', data_key: 'submittedBy', filterable: true },
      {
        label: 'Submitted date',
        data_key: 'submittedDate',
        sortable: true,
        format: 'date',
      },
    ],
    pendingAcquittals: [
      { label: 'Club', data_key: 'club', sortable: true, filterable: true },
      { label: 'Grants', data_key: 'grants_count' },
      { label: 'Receipts', data_key: 'receipts_count' },
      { label: 'Submitted by', data_key: 'submittedBy', filterable: true },
      {
        label: 'Submitted date',
        data_key: 'submittedDate',
        sortable: true,
        format: 'date',
      },
    ],
    approvedGrants: [
      { label: 'Type', data_key: 'type', sortable: true, filterable: true },
      {
        label: 'Amount Requested',
        data_key: 'valueGranted',
        sortable: false,
        format: 'currency',
      },
      { label: 'Club', data_key: 'club', sortable: true, filterable: true },
      { label: 'Submitted by', data_key: 'submittedBy', filterable: true },
      {
        label: 'Submitted date',
        data_key: 'submittedDate',
        sortable: true,
        format: 'date',
      },
    ],
  }

  const financeRowActions = {
    pendingGrants: [
      { label: 'Review', cta: data => review(activeView, data) },
      { label: 'Approve', cta: data => approve(activeView, data) },
      { label: 'Reject', cta: data => reject(activeView, data) },
    ],
    pendingAcquittals: [
      { label: 'Review', cta: data => review(activeView, data) },
      { label: 'Approve', cta: data => approve(activeView, data) },
      { label: 'Reject', cta: data => reject(activeView, data) },
    ],
    approvedGrants: [
      { label: 'Review', cta: data => review(activeView, data) },
      { label: 'Process Payment', cta: data => pay(activeView, data) },
      { label: 'Reject', cta: data => reject(activeView, data) },
    ]
  }

  const financeBulkActions = {
    pendingGrants: null,
    pendingAcquittals: null,
    approvedGrants: [
      { key: "payAll", label: "Process Payments" },
      { key: "rejectAll", label: 'Reject' }
    ]
  }

  useEffect(() => {
    if (!quickSearchList && (clubList || pendingClubList)) {
        const qsClubList = clubList?.map((club) => club);
        const qsPendingClubList = pendingClubList?.map((club) => club);
        const qsList = [...qsClubList, ...qsPendingClubList];
        setQuickSearchList(qsList);
    }
  }, [clubList, pendingClubList, quickSearchList])

  useMemo(() => {
    if (authed === null && get(auth, 'state.userChecked')) {
      setAuthed(hasStaffPermissions(auth, [2, 3, 4]));
      const permission = hasStaffPermissions(auth, [3, 4]);

      if (permission) {
        const _rowActions = [...rowActions];

        setRowActions(_rowActions);
      }
    }
  }, [auth, authed, setAuthed, rowActions]);

  const fetchFinances = useCallback(async (view) => {
    setLoading(true);
    const pendingGrants = [];
    const pendingAcquittals = [];
    const approvedGrants = []
    const _view = view || activeView;

    switch (_view) {
      case 'pendingGrants':
        const _pendingGrants = await wpAll(`grants?pending=true`);
        // console.log("Pending Grants", _pendingGrants);
        
        if (_pendingGrants.status === 200) {
          pendingGrants.push(..._pendingGrants.response.map(grant => ({
            id: grant.id,
            name: decodeURI(grant.title.rendered),
            club: grant.acf.club.post_title,
            clubId: grant.acf.club.ID,
            type: grantTypes.find(type => Number(type.id) === Number(grant.grant_types[0]))?.name,
            status: grant.acf.status
                .substr(
                grant.acf.status.lastIndexOf(':') + 1,
                grant.acf.status.length
                )
                .trim(),
            acquittal: grant.acf.acquittal,
            valueGranted: grant.acf.value_granted,
            valueProvided: grant.acf.value_provided,
            submittedBy: grant.acf.submitted_by,
            submittedDate: grant.date,
            reviewedBy: grant.acf.reviewed_by,
            staffNotes: grant.acf.staff_notes,
            reason: grant.acf.reason,
            associated_event: grant.acf.associated_event,
            approvedBy: grant.acf.approved_by,
            additionalInformation: grant.acf.additional_information
          })))
        }
      break;

      case 'pendingAcquittals':
        const _pendingAcquittals = await wpAll(`acquittals?pending=true`);
        // console.log("Pending Acquittals", _pendingAcquittals);
        
        if (_pendingAcquittals.status === 200) {
          pendingAcquittals.push(..._pendingAcquittals.response.map(acquittal => ({
            id: acquittal.id,
            name: decodeURI(acquittal.title.rendered),
            club: acquittal.acf.club.post_title,
            clubId: acquittal.acf.club.ID,
            status: acquittal.acf.status
                .substr(
                  acquittal.acf.status.lastIndexOf(':') + 1,
                  acquittal.acf.status.length
                )
                .trim(),
            calculatedReceived: acquittal.acf.calculated_received,
            calculatedSpent: acquittal.acf.calculated_spent,
            submittedBy: acquittal.acf.submitted_by,
            submittedDate: acquittal.date,
            reviewedBy: acquittal.acf.reviewed_by,
            approvedBy: acquittal.acf.approved_by,
            staffNotes: acquittal.acf.staff_notes,
            summary: acquittal.acf.summary,
            explanation: acquittal.acf.explanation,
            grants: acquittal.acf.grants,
            receipts: acquittal.acf.receipts,
            grants_count: acquittal.acf.grants.length,
            receipts_count: acquittal.acf.receipts.length,
          })));
        }
      break;

      case 'approvedGrants':
        const _approvedGrants = await wpAll(`grants?approved=true`);
        // console.log("Approved Grants", _approvedGrants);
        
        if (_approvedGrants.status === 200) {
          approvedGrants.push(..._approvedGrants.response.map(grant => ({
            id: grant.id,
            name: decodeURI(grant.title.rendered),
            club: grant.acf.club.post_title,
            clubId: grant.acf.club.ID,
            vendorcode: quickSearchList.find(c => c.id === grant.acf.club.ID)?.clubFieldsSide.vendorCode || '',
            type: grantTypes.find(type => Number(type.id) === Number(grant.grant_types[0]))?.name,
            status: grant.acf.status
                .substr(
                grant.acf.status.lastIndexOf(':') + 1,
                grant.acf.status.length
                )
                .trim(),
            acquittal: grant.acf.acquittal,
            valueGranted: grant.acf.value_granted,
            valueProvided: grant.acf.value_provided,
            submittedBy: grant.acf.submitted_by,
            submittedDate: grant.date,
            reviewedBy: grant.acf.reviewed_by,
            staffNotes: grant.acf.staff_notes,
            reason: grant.acf.reason,
            associated_event: grant.acf.associated_event,
            approvedBy: grant.acf.approved_by,
            additionalInformation: grant.acf.additional_information
          })))
        }
      break;

      default: 
      break;
    }

    setFinanceList({
      pendingGrants,
      pendingAcquittals,
      approvedGrants
    });
    
    setLoading(false);
  }, [grantTypes, quickSearchList]);

  useMemo(() => {
    if (financeList === null) {
      fetchFinances();
    }
  }, [financeList, fetchFinances]);

  const triggerViewChange = (view) => {
    if (view !== activeView) {
      setActiveView(view);
      fetchFinances(view);
    }
  }

  const review = async (view, data) => {
    if (view === 'pendingGrants' || view === 'approvedGrants') {
      navigate(`/account/manage-finances/grant-details/`, {
        state: {
          ...location.state,
          grant: data
        },
      })
    }

    if (view === 'pendingAcquittals') {
      navigate(`/account/manage-finances/acquittal-details/`, {
        state: {
          ...location.state,
          acquittal: data
        },
      })
    }
  }

  const approve = async (view, data) => {
    const { usu: user } = memberDetails;
    const object = { ...data };
    object.status = 'approved';
    object.approvedBy = `${user.FirstName} ${user.LastName} (${user.MemberNumber})`
    
    if (view === 'pendingGrants') {
      if (object.valueProvided === "0") object.valueProvided = object.valueGranted;

      if (['camp', 'welcome fest'].indexOf(object.type.toLowerCase()) > -1) {
        const { response: _receipts } = await wpAll(`club_receipts?grant=${object.id}&club=${object.clubId}&_fields=id`);

        // Generate acquittal
        const acquittalFields = {
            summary: object.reason,
            explanation: '',
            club: object.clubId,
            status: 'pending',
            grants: [{grant: object.id}],
            receipts: _receipts.map(r => ({receipt: r.id})),
            no_amount_spent: false,
            calculated_received: object.valueGranted,
            calculated_spent: object.valueGranted,
            submitted: `${new Date().getFullYear()}-${String(Number(new Date().getMonth()) + 1).padStart(2, '0')}-${new Date().getDate()}`,
            submitted_by: object.submittedBy // `${user.FirstName} ${user.LastName} (${user.MemberNumber})`,
        };
  
        const newAcquittalData = {
            club: {
              clubId: object.clubId,
              clubTitle: object.club,
            //   clubStatus: club.acf.status.label,
            },
            fields: acquittalFields
        };
  
        const { response: newAcquittal } = await createNewAcquittal(
            newAcquittalData
        );
      }
      updateGrantRecord(object);
    }

    if (view === 'pendingAcquittals') {
      updateAcquittalRecord(object)
    }
  }

  const pay = (view, data) => {
    const { usu: user } = memberDetails;
    const object = { ...data };
    object.status = 'paid';
    object.paidBy = `${user.FirstName} ${user.LastName} (${user.MemberNumber})`
    
    if (view === 'approvedGrants') {
      if (object.valueProvided === "0") object.valueProvided = object.valueGranted;
      updateGrantRecord(object);
    }
  }


  const reject = (view, data) => {
    const object = { ...data };
    object.status = 'rejected';

    if (view === 'pendingGrants') {
      object.valueProvided = "0";
      updateGrantRecord(object);
    }

    if (view === 'pendingAcquittals') {
      updateAcquittalRecord(object)
    }
  }

  const handleBulkActions = (type, data) => {
    switch (type) {
      case 'payAll':
        const { usu: user } = memberDetails;
        const objects = data.map(g => ({
          ...g, 
          valueProvided: (g.valueProvided === "0") ? g.valueGranted : g.valueProvided,
          status: 'paid', 
          paidBy: `${user.FirstName} ${user.LastName} (${user.MemberNumber})`,
        }));
        bulkUpdateGrantRecords(objects);
      break;

      case 'rejectAll':
        bulkUpdateGrantRecords(data, {
          status: 'rejected', 
          valueProvided: '0'
        });
      break;

      default:
        break;
    }
  }

  const updateGrantRecord = async (object) => {
    try {
      const { usu: user } = memberDetails;
      const fields = {
        fields: {
          status: object.status,
          staff_notes: object.staffNotes,
          value_provided: object.valueProvided,
          reviewed_by: `${user.FirstName} ${user.LastName} (${user.MemberNumber})`,
          approved_by: object.approvedBy,
          paid_by: object.paidBy,
        }
      }

      const paymentResponse = [];
      // console.log(object);
      if (['paid'].indexOf(object.status) > -1) {
        // Send payment
        const vendorCode = object.vendorcode;
        if (!vendorCode || vendorCode.length !== 6) {
            toast.error('Club contains invalid vendor code');
            throw "error";
        }
        const fundingData = {
            data: [{
                FundingID: Number(object.id),
                description: `${object.type} Grant`,
                club_id: Number(object.clubId),
                vendorcode: vendorCode,
                Amount: Number(parseFloat(object.valueProvided).toFixed(2))
            }]
        }
        
        paymentResponse.push(await processFundingData(fundingData));
      }

      const paymentResult = await Promise.all(paymentResponse);
      
      if (paymentResult[0].response.indexOf("Success") > -1) {

        const updateResponse = await wpApi(`grants/${object.id}`, 'POST', fields);

        if (String(updateResponse.status).startsWith('2')) {
          if (['paid', 'rejected'].indexOf(object.status) > -1) {
            emailer(object).then(postResponse => {
              // console.log(postResponse);
        
              toast.success('Grant updated!');

              setFinanceList(null);
              setLoading(true);

              return postResponse;
            });
          } else {
            toast.success('Grant updated!');

            setFinanceList(null);
            setLoading(true);
          }
        } else {
          toast.error(
            'An unexpected error has occurred.\nPlease try again later.'
          );
        }
      } else {
        toast.error('An unexpected error has occurred.\nPlease try again later.');
        console.error(paymentResult[0].response);
      }
    } catch (error) {
      toast.error('An unexpected error has occurred.\nPlease try again later.');
    }
  }

  const bulkUpdateGrantRecords = async (objects, override = {}) => {
    setLoading(true);

    try {
      const { usu: user } = memberDetails;
      const updates = objects.map(async grant => {
        const object = { ...grant, ...override };
        const fields = {
          fields: {
            status: object.status,
            staff_notes: object.staffNotes,
            value_provided: object.valueProvided,
            reviewed_by: `${user.FirstName} ${user.LastName} (${user.MemberNumber})`,
            approved_by: object.approvedBy,
            paid_by: object.paidBy,
          }
        }

        const paymentResponse = [];
        
        if (['paid'].indexOf(object.status) > -1) {
          // Send payment
          const vendorCode = object.vendorcode;
          if (!vendorCode || vendorCode.length !== 6) {
              toast.error('Club contains invalid vendor code');
              throw "error";
          }
          const fundingData = {
              data: [{
                  FundingID: Number(object.id),
                  description: `${object.type} Grant`,
                  club_id: Number(get(location, 'state.grant.clubId')),
                  vendorcode: vendorCode,
                  Amount: Number(parseFloat(object.valueProvided).toFixed(2))
              }]
          }
          
          paymentResponse.push(await processFundingData(fundingData));
        }

        const paymentResult = await Promise.all(paymentResponse);

        if (paymentResult[0].response.indexOf("Success") > -1) {
  
          const updateResponse = await wpApi(`grants/${object.id}`, 'POST', fields);
    
          if (String(updateResponse.status).startsWith('2')) {
            if (['paid', 'rejected'].indexOf(object.status) > -1) {
              return emailer(object).then(() => {
                return true;
              });
            } else {
              return true;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      });

      const results = await Promise.all(updates);

      const successful = results.filter(r => r === true);
      const failed = results.filter(r => r === false);
      if (failed && failed.length > 0) {
        toast.warn(`${successful.length} Grant${successful.length > 1 ? 's' : ''} updated!\n${failed.length} Grant${failed.length > 1 ? 's' : ''} failed!`);
      } else {
        toast.success(`${successful.length} Grant${successful.length > 1 ? 's' : ''} updated!`);
      }

      setFinanceList(null);
    } catch (error) {
      toast.error('An unexpected error has occurred.\nPlease try again later.');
    }
  }

  const updateAcquittalRecord = async (object) => {
    try {
      const { usu: user } = memberDetails;
      const fields = {
        fields: {
          status: object.status,
          staff_notes: object.staffNotes,
          confirmed_spent: object.confirmedSpent,
          reviewed_by: `${user.FirstName} ${user.LastName} (${user.MemberNumber})`,
          approved_by: object.approvedBy,
        }
      }

      // console.log(object, fields);

      const updateResponse = await wpApi(`acquittals/${object.id}`, 'POST', fields);

      const updateReceipts = object.receipts.map(async r => {
        const rFields = {
          fields: {
            // approved: r.fields.approved, // THIS needs rework as fields is not present at index level
            approved: object.status === 'approved' ? true : false,
            reviewed_by: `${user.FirstName} ${user.LastName} (${user.MemberNumber})`,
          }
        }

        const updateReceiptResponse = await wpApi(`club_receipts/${r?.receipt?.ID}`, 'POST', rFields); // Changed from r.id to r?.receipt?.ID

        if (String(updateReceiptResponse.status).startsWith('2')) {
          return true
        }
      })

      Promise.all(updateReceipts).then(() => {
        if (String(updateResponse.status).startsWith('2')) {
          toast.success('Acquittal updated!');

          setFinanceList(null);
          setLoading(true);
        } else {
          toast.error(
            'An unexpected error has occurred.\nPlease try again later.'
          );
        }
      });
    } catch (error) {
      toast.error('An unexpected error has occurred.\nPlease try again later.');
    }
  }

  const handleClubSearch = (id, value) => {
    setSearchQuery(value);
    actionQuickSearch(value);
  }

  const actionQuickSearch = (value) => {
    if (value.length >= 3) {
      const results = quickSearchList.filter(club => {
        const name = decode(`${club.club_name} (${club.clubFieldsMain?.abbr || 'PENDING'})`);
        return name.toLowerCase().indexOf(value.toLowerCase()) > -1
      });
      setSearchResults(results);
    }
  }

  return (
    <>
      {authed ? (
        <div className={`${styles.root}`} style={{opacity: loading ? '0.5' : '1'}}>
          {loading ? (
            <>Fetching financials. Please wait...</>
          ) : (
            <>
              <div>
                <span className={`${styles.tab}${activeView === 'pendingGrants' ? ` ${styles.active}` : ''}`} role="presentation" onClick={() => triggerViewChange('pendingGrants')}>Pending grants</span> 
                <span className={`${styles.tab}${activeView === 'pendingAcquittals' ? ` ${styles.active}` : ''}`} role="presentation" onClick={() => triggerViewChange('pendingAcquittals')}>Pending acquittals</span>
                {hasStaffPermissions(auth, [3, 4]) && (
                  <span className={`${styles.tab}${activeView === 'approvedGrants' ? ` ${styles.active}` : ''}`} role="presentation" onClick={() => triggerViewChange('approvedGrants')}>Approved grants</span>
                )}
              </div>
              {financeList && (
                <DataTable
                  tableData={financeList[activeView]}
                  headingKeys={financeHeadings[activeView]}
                  bulkActions={financeBulkActions[activeView]}
                  handleBulkAction={handleBulkActions}
                  rowActions={financeRowActions[activeView]}
                />
              )}
              {loading && <div className={styles.loaderContainer}><Loader /></div>}

              {/**
               * Club search
               **/}
              <h6 className='mb-4 mt-6'>Search for a club</h6>
              <div className={`${styles.root} ${styles.withBg}`}>
                <FormInputField placeholder="Search club name" handleChange={handleClubSearch} />
                <div>
                  {(searchQuery && searchQuery.length >= 3) && (
                    <>
                      {searchResults.length === 0 && (
                        <p>No clubs found</p>
                      )}

                      {searchResults.length > 0 && (
                        <DataTable
                          tableData={searchResults}
                          headingKeys={[
                            { label: 'Club name', data_key: 'club_name', sortable: true },
                          ]}
                          rowActions={[
                            {
                              label: 'View',
                              cta: data =>
                                navigate('/account/manage-finances/club-details', {
                                  state: {
                                    clubId: data.id,
                                    clubName: `${data.club_name}`,
                                    club: data
                                  },
                                }),
                            },
                          ]}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className={styles.root}>
          {/* Fetching data */}
          {authed === null && <div>Fetching data</div>}
          {/* No data found */}
          {authed === false && (
            <div>
              An error occurred. Return back to{' '}
              <Link to='/account/'>
                <u>dashboard</u>
              </Link>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const USUClubManagementOutput = ({ location }) => {
  const persistedLocation = persistLocation(location);
  return (
    <AccountPageWrapper
      metaTitle='Account - USU Management'
      bgRaw
      title='Manage Club Finances'
      breadcrumbTitle='Manage Club Finances'>
      <Manage location={persistedLocation} />
    </AccountPageWrapper>
  )
};

export default USUClubManagementOutput;

