import React, { useContext, useEffect, useState } from 'react';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';
import { get } from "lodash"
import AuthContext from "../../../../../../../context/AuthProvider";
import { extractDate } from '../../../../../../../helpers/general';
import { authClubExec, wpAll, wpApi } from '../../../../../../../helpers/wordpress';
import AccountPageWrapper from '../../../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import AccordionCard from '../../../../../../../components/atoms/AccordionCard/AccordionCard';
import CurrencyFormatter from '../../../../../../../components/atoms/CurrencyFormatter/CurrencyFormatter';

import * as styles from './../details.module.css';
import DataTable from '../../../../../../../components/organisms/DataTable/DataTable';
import ReceiptsList from '../../../../../../../components/organisms/Receipts/Receipts';
// import Acquittal from './acquittal';

const AcquittalDetails = ({ location }) => {
    const auth = useContext(AuthContext);
    const memberNumber = auth && get(auth, 'state.usu.MemberNumber');
    const [isAuthed, setAuthed] = useState(null);
    const [club, setClub] = useState(null);
    const [grants, setGrants] = useState(null);
    const [receipts, setReceipts] = useState(null);
    const [valueProvided, setValueProvided] = useState(0);
    const [valueSpent, setValueSpent] = useState(0);

    const {
        allWpGrantType: { nodes: grantTypes },
    } = useStaticQuery(graphql`
        query {
          allWpGrantType {
            nodes {
              name
              id: databaseId
            }
          }
        }
    `);

    useEffect(() => {
        const fetchData = async () => {
            const { response: grantsList } = await wpAll(
                `grants?club=${location.state.clubId}&acquittal=${location.state.acquittal.id}`
            );
            if (grantsList) {
              const _receipts = [];
              let provided = 0;
              let spent = 0;
              const _grants = grantsList.map(grant => ({
                  id: grant.id,
                  name: decodeURI(grant.title.rendered),
                  type: grantTypes.find(type => Number(type.id) === Number(grant.grant_types[0]))?.name,
                  status: grant.acf.status
                      .substr(
                      grant.acf.status.lastIndexOf(':') + 1,
                      grant.acf.status.length
                      )
                      .trim(),
                  acquittal: grant.acf.acquittal,
                  valueGranted: grant.acf.value_granted,
                  valueProvided: grant.acf.value_provided,
                  submittedBy: grant.acf.submitted_by,
                  submittedDate: grant.date,
                  reviewedBy: grant.acf.reviewed_by,
                  staffNotes: grant.acf.staff_notes,
                  reason: grant.acf.reason,
                  approvedBy: grant.acf.approved_by,
              }))
              // console.log(_grants);
              
              if (_grants.length > 0) {
                const promises = _grants.map(async (grant, gId) => {
                  /* Fetch all receipts for grant */
                  const { response: receiptsList } = await wpApi(`club_receipts?grant=${grant.id}&club=${location.state.clubId}`);
                  const grantReceipts = receiptsList.map(receipt => {
                    spent += Number(receipt.acf.value);
                    return {
                      id: receipt.id,
                      receipt: receipt.acf.receipt,
                      value: receipt.acf.value,
                      description: receipt.acf.description,
                      date: receipt.acf.date_of_purchase,
                      submittedBy: receipt.acf.submitted_by,
                      submittedDate: receipt.acf.submitted,
                      grant: `${grant.type} (${extractDate(grant.submittedDate)})`
                    };
                  });

                //   _receipts.push(...grantReceipts);
                  _receipts.push(...receiptsList);
      
                  provided += Number(grant.valueProvided);

                  _grants[gId].receipts = grantReceipts;
                  _grants[gId].receiptCount = grantReceipts.length;
      
                  return true;
                });
      
                Promise.all(promises).then(() => {
                  setGrants(_grants);
                  setReceipts(_receipts);
                  setValueProvided(provided);
                  setValueSpent(spent);
                })
              } else {
                setGrants(false);
                setReceipts(false);
              }
            } else {
              setGrants(false);
              setReceipts(false);
            }
        }

        if (isAuthed === null || (isAuthed && club  && club.id !== location.state.clubId)) {
            authClubExec(memberNumber, location.state.clubId).then(authed => {
                if (String(authed.status).startsWith("2") && authed.response) {
                    setAuthed(true);
                    setClub(authed.response);
                    fetchData();
                } else {
                    setAuthed(false);
                }
            });
        }
    }, [isAuthed, memberNumber, location, club]);

    return location.state && location.state.acquittal ? (
        <>
            <AccordionCard title="Details" showContent={location.state.acquittal?.status === 'Pending' ? true : false}>
                <div>Status: {location.state.acquittal.status}</div>
                <div>Included grants: {grants ? grants.length : 'Calcuating...'}</div>
                <div>Included receipts: {receipts ? receipts.length : 'Calculating...'}</div>
                <div>Total provided: <CurrencyFormatter amount={valueProvided} /></div>
                <div>Total spent: <CurrencyFormatter amount={valueSpent} /></div>
                <div>Total remaining: <CurrencyFormatter amount={valueProvided - valueSpent} /></div>
                <div>Brief summary on club activities and how grants were spent: {location.state.acquittal.summary}</div>
                <div>Expense differences explanation: {location.state.acquittal.explanation}</div>
            </AccordionCard>

            <AccordionCard title="Grants">
                <DataTable
                    tableData={grants}
                    headingKeys={[
                        { label: 'Status', data_key: 'status', sortable: true, filterable: true },
                        { label: 'Type', data_key: 'type', sortable: true, filterable: true },
                        {
                            label: 'Amount Requested',
                            data_key: 'valueGranted',
                            sortable: false,
                            format: 'currency',
                        },
                        {
                            label: 'Amount Provided',
                            data_key: 'valueProvided',
                            sortable: false,
                            format: 'currency',
                        },
                        {
                            label: 'Receipts',
                            data_key: 'receiptCount'
                        },
                        {
                            label: 'Submitted date',
                            data_key: 'submittedDate',
                            sortable: true,
                            format: 'date',
                        }
                    ]}
                />
            </AccordionCard>
            
            {receipts && (
                <AccordionCard title="Receipts">
                    <ReceiptsList customReceiptData={receipts} hasAcquittal={true} />
                </AccordionCard>
            )}
        </>
    ) : (
        <div className={styles.root}>
            {/* Fetching club preferences */}
            {(isAuthed === null) && (
                <div>Fetching acquittal data</div>
            )}
            {/* No clubs found */}
            {(isAuthed === true && club === false) && (
                <div>An error occurred. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
            )}
            {/* No clubs found */}
            {(isAuthed === false) && (
                <div>You are not authorised to manage this club. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
            )}
        </div>
    );
};

const ClubManagementOutput = ({location}) => {
    const backToManagementPage = () =>{
        navigate(`/account/my-usu/clubs/manage`,{
            state: location.state
        })
    }

    const backToFinancesPage = () =>{
        navigate(`/account/my-usu/clubs/manage/finances`,{
            state: location.state
        })
    }

    const backToAcquittalPage = () =>{
        navigate(`/account/my-usu/clubs/manage/finances/acquittals`,{
            state: location.state
        })
    }

    return (
        <AccountPageWrapper bgRaw metaTitle="Account - Club Management" title="Acquittal Detail" breadcrumbTitle="Acquittal Detail" breadcrumbs={[{ link: '/account/my-usu/clubs/', label: 'My USU' }, { onClick: () => backToManagementPage(), label: get(location, 'state.clubTitle', '')}, { onClick: () => backToFinancesPage(), label: "Club Finances"}, { onClick: () => backToAcquittalPage(), label: "Manage Acquittals"}]} menuType="clubManage" location={location}>
            <AcquittalDetails location={location} />
        </AccountPageWrapper>

    )
}

export default ClubManagementOutput

