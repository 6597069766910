import React, { useMemo, useState, useContext, useEffect } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { get } from "lodash";
import { decode } from "he";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { hasStaffPermissions, persistLocation } from "../../helpers/general";
import { wpApi, wpAll, updateCertificate, mapWordpressStatus } from "../../helpers/wordpress"; 
import AccountPageWrapper from "../../components/organisms/AccountPageWrapper/AccountPageWrapper";
import AuthContext from "../../context/AuthProvider";
import DataTable from "../../components/organisms/DataTable/DataTable";
import Dialog from "../../components/atoms/Dialog/Dialog";
import Loader from "../../components/atoms/Loader/Loader";
import { success } from '../../helpers/toast';

import * as styles from './manage-screens.module.css';
import { getPerson } from "../../helpers/ortto";
import FormInputField from "../../components/atoms/FormInputField/FormInputField";
import { useCallback } from "react";

const Manage = () => {
    const auth = useContext(AuthContext);
    const [authed, setAuthed] = useState(null);

    const [certificateList, setCertificateList] = useState(null);
    const [typeList, setTypeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [quickSearchLoaded, setQuickSearchLoaded] = useState(false);
    // const [type, setType] = useState();

    const [editDialog, setEditDialog] = useState(false);
    const [expiryDate, setExpiryDate] = useState();
    const [certFocus, setCertFocus] = useState();
    const [bulkCert, setBulkCert] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [memberDetails, setMemberDetails] = useState([]);
    const [memberDetail, setMemberDetail] = useState(false);
    const [viewDetail, setViewDetail] = useState(false);

    const [searchQQuery, setSearchQQuery] = useState('');
    const [quickSearchList, setQuickSearchList] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const certTypeList = useStaticQuery(graphql`
    query {
        certificationType: allWpCertificationType {
                nodes {
                    name
                    id
                    databaseId
                }
            }
        }
    `)

    useMemo(() => {
        if (authed === null && get(auth, 'state.userChecked')) {
            // setAuthed(true); // get an actual staff account
            setAuthed(hasStaffPermissions(auth, [2, 3, 4]));
        }
    }, [auth, authed, setAuthed]);

    const headingKeys = [
        { label: 'Certificate Name', data_key: 'certificate_name', sortable: true, filterable: true },
        { label: 'Status', data_key: 'status', sortable: true, filterable: true },
        { label: 'Student Name', data_key: 'student_name', sortable: true },
        { label: 'Uploaded Date', data_key: 'uploaded_date', sortable: true, format: 'date' },
        { label: 'Expiring Date', data_key: 'expiring_date', sortable: true, format: 'date' },
    ];

    const handlePreview = (data) => {
        window.open(data.link, '_blank');
    }

    const handleDetail = async (data) => {
        setViewDetail(true);
        // console.log(data);
        // console.log(memberDetails);
        const profile = memberDetails.find(contact => contact.fields['str:cm:membernumber'] === data.member_number);
        // console.log(profile);
        const certs = certificateList.filter(cert => cert?.member_number === data.member_number);
        // console.log(certs);
        const clubSlugs = profile?.fields.tags.filter(tag => tag.startsWith('club-')).map(tag => tag.replace('club-', '')) || false;
        const clubs = clubSlugs ? await wpAll(`clubs?slug=${clubSlugs.join(',')}`) : false;
        // console.log(clubs);
        const isExec = clubs?.response?.filter(club => club.acf.club_execs.find(exec => exec.member_number === data.member_number)) || [];
        // console.log(isExec)
        setMemberDetail({
            name: data.student_name,
            member_number: data.member_number,
            certs: certs,
            execOf: isExec
        });
    }

    const getWpCertification = useCallback((certTypeList, queryStatus = 'draft', isQuichSearch = false) => {
        const returnName = (list, memberNumber) => {
            if (String(list.status).startsWith('2') && list.response && 'contacts' in list.response) {
                if (memberDetails !== list.response.contacts) {
                    setMemberDetails(list.response.contacts);
                }
                const record = list.response.contacts.find(contact => contact.fields['str:cm:membernumber'] === memberNumber);
                if (record) {
                    return `${record.fields['str::first']} ${record.fields['str::last']}`;
                }
            }
            
            return `(${memberNumber})`;
        }

        wpAll(`certifications?status=${queryStatus}`, 100).then(async (response) => {
            if(String(response?.status).startsWith('2')) {
                // console.log(response);
                const memberNumbers = [];
                response?.response.map(cert => {
                    if (cert?.acf.usu_member_number && memberNumbers.indexOf(cert?.acf.usu_member_number) === -1) {
                        memberNumbers.push(cert?.acf.usu_member_number);
                    }

                    return true;
                });
                const _memberDetails = await getPerson(['first', 'last', 'MemberNumber', 'tags'], memberNumbers, 'str:cm:membernumber');
                // console.log(_memberDetails);
                const cl = response?.response.map((cert) => {
                    return({
                        id: cert.id,
                        // certificate_name: cert?.acf.name,
                        certificate_name: mapCertType(certTypeList, cert?.certification_types[0]),
                        uploaded_date: cert.date,
                        expiring_date: cert.acf.expiring_date,
                        link: cert?.acf.file,
                        file_id: cert?.acf.file_id,
                        standing: cert?.acf.standing,
                        member_number: cert?.acf.usu_member_number,
                        student_name: returnName(_memberDetails, cert?.acf.usu_member_number),
                        status: mapWordpressStatus(cert?.status),
                    })
                })

                if (isQuichSearch) {
                    setQuickSearchLoaded(true);
                    setQuickSearchList(cl);
                } else {
                    setCertificateList(cl);
                }
            }
        });
    }, [memberDetails])

    const getCertificationList = useCallback((certTypeList) => {    
        return getWpCertification(certTypeList);
    }, [getWpCertification])

    const updateExpiry = () => {
        setLoading(true);
        const updateObj = {
            id: certFocus.id,
            expiring_date: expiryDate,
        }
        updateCertificate(updateObj).then((data) => {
            refresh();
        })
    }

    const updateStatusCert = (id, status) => {
        setLoading(true);
        const updateObj = {
            id: id,
            status: status,
        }
        updateCertificate(updateObj).then((data) => {
            success('Certificate updated');
            refresh();
        })
    }

    const mapCertType = (cTypeList, id) => {
        const typeIndex = cTypeList.findIndex((t) => t.value === parseInt(id));
        return cTypeList[typeIndex].label;
    }

    const handleDelete = () => {
        setLoading(true);

        const promiseArray = [];
        for (let index = 0; index < bulkCert.length; index++) {
            const deleteCertPromise = wpApi(`certifications/${bulkCert[index]?.id}`, 'DELETE');
            const deleteMediaPromise = wpApi(`media/${bulkCert[index]?.file_id}?force=true`, 'DELETE');
            promiseArray.push(deleteCertPromise);
            promiseArray.push(deleteMediaPromise);
        }

        Promise.all(promiseArray).then(() => {
            refresh();
            setDeleteDialog(false);
        })
    }

    useEffect(() => {
        if (certTypeList && typeList.length === 0) {
            const cTypeList = certTypeList?.certificationType?.nodes.map((type) => ({
                label: type.name,
                value: type.databaseId,
            }))
            setTypeList(cTypeList);
            // setType(cTypeList[0].value);
            getCertificationList(cTypeList);
            getWpCertification(cTypeList, 'publish,private', true);
        }
    }, [certTypeList, getCertificationList, getWpCertification, typeList])

    const refresh = () => {
        setLoading(false);
        setEditDialog(false);
        setBulkCert([]);
        setCertFocus(undefined);
        getCertificationList(typeList);
        getWpCertification(typeList, 'publish,private', true);
    }

    const handleBulkAction = (e, certList) => {
        console.log("bulk");
        if(certList.length === 0) return;

        if(e === 'delete') {
            setBulkCert(certList);
            setDeleteDialog(true);
        }

        if(e === 'approve') {
            const updatePromiseCollection = certList.map((cert) => updateCertificate({
                id: cert.id,
                status: 'publish',
            }));

            Promise.all(updatePromiseCollection).then((responses) => {
                success(`${responses.length} ${responses.length === 1 ? 'certificate' : 'certificates'} updated`);
                refresh();
            })
        }

        if(e === 'reject') {
            const updatePromiseCollection = certList.map((cert) => updateCertificate({
                id: cert.id,
                status: 'private',
            }));

            Promise.all(updatePromiseCollection).then((responses) => {
                success(`${responses.length} ${responses.length === 1 ? 'certificate' : 'certificates'} updated`);
                refresh();
            })
        }
    }

    const handleClubSearch = (id, value) => {
        setSearchQQuery(value);
        actionQuickSearch(value);
    }
    
    const actionQuickSearch = (value) => {
        if (value.length >= 3 && quickSearchList) {
            const list = quickSearchList;
            const results = list.filter(cert => {
                const name = decode(`${cert.member_number} ${cert.student_name} ${cert.certificate_name}`);
                return name.toLowerCase().indexOf(value.toLowerCase()) > -1
            });
            setSearchResults(results);
        }
    }

    return (
        <>
            {(authed) ? (
                <>
                    <div className={`${styles.root} ${styles.withBg}`} style={{opacity: loading ? '0.5' : '1'}}>
                        {/* Manage Events */}
                        <DataTable 
                            handleBulkAction={handleBulkAction}
                            tableData={certificateList} 
                            bulkActions={[
                                { label: 'Approve', key: 'approve' },
                                { label: 'Reject', key: 'reject' },
                                { label: 'Delete', key: 'delete' }
                            ]}
                            // resetAfterBulk = {true}
                            allowExport={true}
                            filename="certificates"
                            headingKeys={headingKeys}
                            rowActions={[
                                { label: 'Preview', cta: (data) => handlePreview(data) },
                                { label: 'View Detail', cta: (data) => handleDetail(data) },
                                { label: 'Edit', cta: (data) => {
                                    setCertFocus(data);
                                    setEditDialog(true);
                                }},
                                { label: 'Approve', cta: (data) => {updateStatusCert(data.id, 'publish')}},
                                { label: 'Reject', cta: (data) => {updateStatusCert(data.id, 'private')}},
                                { label: 'Delete', cta: (data) => {
                                    // setDeleteId(data.id);
                                    // setCertFocus([data]);
                                    // setShowPrompt(true)
                                    setBulkCert([data]);
                                    setDeleteDialog(true);
                                }},
                                // { label: 'Details', href: '/dummy' }
                            ]}
                            // perPage={5}
                            showSearch={true} 
                            defaultSortType='asc'
                            defaultSortKey='uploaded_date'
                        />
                        {loading && <div className={styles.loaderContainer}><Loader /></div>}
                        <Dialog
                            hideBtnOk
                            cancelBtnText={'Close'}
                            open={viewDetail}
                            onCancel={() => {setMemberDetail(false); setViewDetail(false);}}
                            title={'Member detail'}
                            size={'sm'}
                        >
                            {!memberDetail && (
                                <span>Fetching details...</span>
                            )}

                            {memberDetail && (
                                <>
                                    <p><strong>{memberDetail.name} ({memberDetail.member_number})</strong></p>
                                    <strong>All Certifications</strong>
                                    <ul>
                                        {memberDetail.certs.map((cert, cI) => (
                                            <li key={cI}>{cert.certificate_name} ({cert.status})</li>
                                        ))}
                                    </ul>
                                    <strong>Club Executive of</strong>
                                    <ul>
                                        {memberDetail.execOf.map((club, cI) => (
                                            <li key={cI}>{decode(club.title.rendered)} ({club.acf.club_execs.find(exec => exec.member_number === memberDetail.member_number)?.position})</li>
                                        ))}
                                    </ul>
                                </>
                            )}
                        </Dialog>
                        <Dialog
                            onCancel={() => setEditDialog(false)}
                            onOk={() => updateExpiry()}
                            open={editDialog}
                            size={'sm'}
                            hideBtnCancel
                            okBtnText={'Update'}
                            title={'Set Expiry Date'}
                            // disabled={ !selectedFile ? true : false}
                        >
                            <div className={styles.datePickerContainer}>
                                <DatePicker 
                                    selected={expiryDate} 
                                    onChange={(date) => setExpiryDate(date)} 
                                    open = {true}
                                    fixedHeight
                                    inline
                                />
                            </div>
                        </Dialog>
                        <Dialog
                            onCancel={() => setDeleteDialog(false)}
                            open={deleteDialog}
                            onOk={handleDelete}
                            hideBtnClose
                            okBtnText='Delete'
                            size={'xs'}
                        >
                            <div className={styles.promptContainer}>
                                <span className={styles.promptTitle}> {bulkCert?.length === 1 ? 'Delete certification?' : 'Delete certifications?'}</span>
                                <span className={styles.promptMessage}>This can't be undone and it will permanently delete {bulkCert?.length === 1 ? 'this document' :'these documents'}.</span>
                            </div>
                        </Dialog>
                    </div>
                    {/**
                    * Certificate search
                    **/}
                    <h6 className='mb-4 mt-6'>Search by person or certificate</h6>
                    <div className={`${styles.root} ${styles.withBg}`}>
                        {quickSearchLoaded ? (
                            <>
                                <FormInputField placeholder="Search certificate or person name" handleChange={handleClubSearch} />
                                <div>
                                {(searchQQuery && searchQQuery.length >= 3) && (
                                    <>
                                    {searchResults.length === 0 && (
                                        <p>No certificates found</p>
                                    )}

                                    {searchResults.length > 0 && (
                                        <DataTable
                                            tableData={searchResults}
                                            headingKeys={[
                                                { label: 'Certificate name', data_key: 'certificate_name', sortable: false },
                                                { label: 'Status', data_key: 'status', sortable: false},
                                                { label: 'Student Name', data_key: 'student_name', sortable: false }
                                            ]}
                                            rowActions={[
                                                { label: 'Preview', cta: (data) => handlePreview(data) },
                                                { label: 'View Detail', cta: (data) => handleDetail(data) },
                                                { label: 'Edit', cta: (data) => {
                                                    setCertFocus(data);
                                                    setEditDialog(true);
                                                }},
                                            ]}
                                        />
                                    )}
                                    </>
                                )}
                                </div>
                            </>
                        ) : (
                            <p>Loading, please wait...</p>
                        )}
                    </div>
                </>
            ) : (
                <div className={styles.root}>
                    {/* Fetching data */}
                    {authed === null && (
                        <div>Fetching data</div>
                    )}
                    {/* No data found */}
                    {authed === false && (
                        <div>An error occurred. Return back to <Link to="/account/"><u>dashboard</u></Link></div>
                    )}
                </div>
            )}
        </>
    )
}

const USUCertificationManagementOutput = ({location}) => {
    const persistedLocation = persistLocation(location);
    return (
        <AccountPageWrapper metaTitle="Account - USU Management" bgRaw title={'Manage Certificates'} breadcrumbTitle="Manage Certificates">
            <Manage location={persistedLocation} />
        </AccountPageWrapper>
    )
}

export default USUCertificationManagementOutput