import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Link } from 'gatsby';
import { get } from 'lodash';
import parse from 'html-react-parser';
import AuthContext from '../../context/AuthProvider';
import AccountPageWrapper from '../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import DataTable from '../../components/organisms/DataTable/DataTable';
import Dialog from '../../components/atoms/Dialog/Dialog';
import Loader from "../../components/atoms/Loader/Loader";
import Icon from '../../components/atoms/Icon/Icon';
import FormInputField from '../../components/atoms/FormInputField/FormInputField';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import { decodeUnicode, hasStaffPermissions, persistLocation } from '../../helpers/general';
import { wpApi } from '../../helpers/wordpress';
import { success, error } from '../../helpers/toast';

import * as styles from './manage-screens.module.css';


const Manage = () => {
    const auth = useContext(AuthContext);
    const [authed, setAuthed] = useState(null);
    const [notices, setNotices] = useState(null);
    const [previewNotice, setPreviewNotice] = useState(false);
    const [editNotice, setEditNotice] = useState(false);
    const [createNew, setCreateNew] = useState(false);
    const [noticeTitle, setNoticeTitle] = useState('');
    const [noticeContent, setNoticeContent] = useState('');
    const [noticePublished, setNoticePublished] = useState('');
    const [noticeHighlight, setNoticeHighlight] = useState('');
    const [loading, setLoading] = useState(false);

    useMemo(() => {
        if (authed === null && get(auth, 'state.userChecked')) {
            setAuthed(hasStaffPermissions(auth, [2, 3, 4]));
        }
    }, [auth, authed, setAuthed]);

    const fetchNotices = useCallback(async () => {
        setLoading(true);
        const noticesData = await wpApi(`pages`);

        if (String(noticesData.status).startsWith('2')) {
            if (noticesData.response.length > 0) {
                setNotices(noticesData.response.map(notice => {
                    return {
                        id: notice.id,
                        title: notice.title.rendered,
                        content: notice.content.rendered,
                        published: notice.date,
                        modified: notice.modified,
                        highlighted: notice.acf.highlight
                    }
                }));
            } else {
                setNotices([]);
            }
        } else {
            // Failed fetch of club data
            setNotices(false);
        }
        setLoading(false);
    }, []);

    useMemo(() => {
        if (notices === null) {
            fetchNotices();
        }
    }, [notices, fetchNotices]);

    const refresh = () => {
        setNotices(false);
        fetchNotices();
    }

    const preview = (data) => {
        setPreviewNotice(data);
    }

    const createNotice = async () => {
        const data = {};

        setLoading(true);
        data.status = 'publish';
        data.title = decodeUnicode(noticeTitle);
        data.content = noticeContent;
        data.date = `${noticePublished}:00`;
        data.fields = {};
        data.fields.highlight = noticeHighlight;

        const response = await wpApi(`pages`, 'POST', data);
        if (String(response.status).startsWith('2')) {
            refresh();
            clearNotice();
            success('Notice created');
        } else {
            error('Failed to add notice');
        }
    }

    const editNotification = async data => {
        setNoticeTitle(parse(data.title));
        setNoticeContent(data.content);
        setNoticePublished(data.published);
        setNoticeHighlight(data.highlighted);
        setEditNotice(data);
    }

    const updateNotice = async () => {
        const data = {};

        setLoading(true);
        data.status = 'publish';
        data.title = noticeTitle;
        data.content = noticeContent;
        data.date = noticePublished;
        data.fields = {};
        data.fields.highlight = noticeHighlight;

        const response = await wpApi(`pages/${editNotice.id}`, 'POST', data);
        if (String(response.status).startsWith('2')) {
            refresh();
            clearNotice();
            success('Notice updated');
        } else {
            error('Failed to update notice');
        }
    }

    const clearNotice = () => {
        setCreateNew(false);
        setNoticeTitle('');
        setNoticeContent('');
        setNoticePublished('');
        setNoticeHighlight(null);
        setEditNotice(false);
    }

    const deleteNotice = async data => {
        const response = await wpApi(`pages/${data.id}`, 'DELETE');
        // TODO: Delete media item as well to keep media library clean
        if (String(response.status).startsWith('2')) {
            refresh();
            success('Notice deleted');
        } else {
            error('Failed to delete notice');
        }
    }

    const handleDetailUpdate = (id, value) => {
        switch (id) {
            case 'noticeTitle':
                setNoticeTitle(value);
                break;

            case 'noticeContent':
                setNoticeContent(value);
                break;

            case 'noticePublished':
                setNoticePublished(value);
                break;

            case 'noticeHighlight':
                setNoticeHighlight(value.target.checked);
                break;

            default:
                break;
        }
    }

    const handleBulkActions = (value, selected) => {
        switch (value) {
            case 'delete':
                const deletes = selected.map(async data => {
                    const response = wpApi(`pages/${data.id}`, 'DELETE');
                    // TODO: Delete media item as well to keep media library clean
                    if (String(response.status).startsWith('2')) {
                        return true;
                    } else {
                        return false;
                    }
                });

                Promise.all(deletes).then(() => {
                    refresh();
                    success('Notices deleted');
                });
                break;

            default:
                break;
        }
    }

    return (
        <>
        {authed ? (
            <div className={`${styles.root} ${styles.withBg}`} style={{opacity: loading ? '0.5' : '1'}}>
            {(loading || !notices) ? (
                <>Fetching notices. Please wait...</>
            ) : (
                <>
                    <DataTable
                        tableData={notices}
                        bulkActions={[{ label: 'Bulk delete', key: 'delete' }]}
                        handleBulkAction={handleBulkActions}
                        topActions={[
                            { label: 'Create new', icon: <Icon symbol="plus" />, event: () => setCreateNew(true) }
                        ]}
                        headingKeys={[
                            { label: 'Title', data_key: 'title', sortable: true, searchable: true },
                            { label: 'Published', data_key: 'published', sortable: true, format: 'dateTime' },
                            { label: 'Is Highlighted?', data_key: 'highlighted', filterable: true },
                            { label: 'Updated', data_key: 'modified', sortable: true, format: 'dateTime' },
                        ]}
                        rowActions={[
                            { label: 'Edit', cta: data => editNotification(data) },
                            { label: 'Preview', cta: data => preview(data) },
                            { label: 'Delete', cta: data => deleteNotice(data) },
                        ]}
                        bulkAction={true}
                        showSearch={true}
                    />
                    {loading && <div className={styles.loaderContainer}><Loader /></div>}

                    <Dialog
                        size='lg'
                        hideBtnCancel={true}
                        hideBtnOk={true}
                        open={previewNotice}
                        onCancel={() => setPreviewNotice(false)}
                    >
                        {previewNotice && (
                            <>
                                <h5 dangerouslySetInnerHTML={{
                                __html: previewNotice.title
                                }}></h5>
                                {parse(previewNotice.content)}
                            </>
                        )}
                    </Dialog>

                    <Dialog
                        size='md'
                        title='Edit Notice'
                        okBtnText='Update'
                        onOk={() => updateNotice()}
                        onCancel={() => clearNotice()}
                        open={typeof editNotice === 'object'}>
                            {editNotice && (
                            <div className={styles.dialogContainer}>
                                <div className="formField">
                                    <label htmlFor="name">Notice Title</label>
                                    <FormInputField
                                        id='noticeTitle'
                                        handleChange={handleDetailUpdate}
                                        placeholder="Title"
                                        value={decodeUnicode(noticeTitle)}
                                    />
                                </div>
                                <div className="formField">
                                    <label htmlFor="content">Content</label>
                                    <FormInputField
                                        type="richtext"
                                        id='noticeContent'
                                        handleChange={handleDetailUpdate}
                                        placeholder="Content"
                                        value={noticeContent}
                                    />
                                </div>
                                <div className="formField">
                                    <label htmlFor="name">Published Date</label>
                                    <input 
                                        type="datetime-local" 
                                        id="published" 
                                        value={noticePublished} 
                                        onChange={(e) => handleDetailUpdate('noticePublished', e.target.value)} 
                                    />
                                </div>
                                <div className="formField">
                                    <Checkbox
                                        id="noticeHighlight"
                                        value={true}
                                        label="Is highlighted"
                                        action={(e) => handleDetailUpdate('noticeHighlight', e)}
                                        isChecked={noticeHighlight}
                                    />
                                </div>
                            </div>
                            )}
                    </Dialog>

                    <Dialog
                        size='md'
                        title='Create new notice'
                        okBtnText='Create'
                        onOk={() => createNotice()}
                        onCancel={() => setCreateNew(false)}
                        open={createNew}
                        hideBtnCancel
                    >
                        <div className={styles.dialogContainer}>
                            <div className="formField">
                                <label htmlFor="name">Notice Title</label>
                                <FormInputField
                                    id='noticeTitle'
                                    handleChange={handleDetailUpdate}
                                    placeholder="Title"
                                    value={decodeUnicode(noticeTitle)}
                                />
                            </div>
                            <div className="formField">
                                <label htmlFor="content">Content</label>
                                <FormInputField
                                    type="richtext"
                                    id='noticeContent'
                                    handleChange={handleDetailUpdate}
                                    placeholder="Content"
                                    value={noticeContent}
                                />
                            </div>
                            <div className="formField">
                                <label htmlFor="name">Published Date</label>
                                <input 
                                    type="datetime-local" 
                                    id="published" 
                                    value={noticePublished} 
                                    onChange={(e) => handleDetailUpdate('noticePublished', e.target.value)} 
                                />
                            </div>
                            <div className="formField">
                                <Checkbox
                                    id="noticeHighlight"
                                    value={true}
                                    label="Is highlighted"
                                    action={(e) => handleDetailUpdate('noticeHighlight', e)}
                                    isChecked={noticeHighlight}
                                />
                            </div>
                        </div>
                    </Dialog>
                </>
            )}
            </div>
        ) : (
            <div className={styles.root}>
            {/* Fetching data */}
            {authed === null && <div>Fetching data</div>}
            {/* No data found */}
            {authed === false && (
                <div>
                An error occurred. Return back to{' '}
                <Link to='/account/'>
                    <u>dashboard</u>
                </Link>
                </div>
            )}
            </div>
        )}
        </>
    );
};

const USUClubManagementOutput = ({ location }) => {
    const persistedLocation = persistLocation(location);
    return (
        <AccountPageWrapper
            metaTitle='Account - USU Management'
            bgRaw
            title='Manage Club Notices'
            breadcrumbTitle='Manage Club Notices'>
            <Manage location={persistedLocation} />
        </AccountPageWrapper>
    )
};

export default USUClubManagementOutput;

