import React, { useMemo, useState, useCallback } from "react"
import Button from "../../components/atoms/Button/Button";
import AccountPageWrapper from "../../components/organisms/AccountPageWrapper/AccountPageWrapper"
import { getPerson, updatePerson } from "../../helpers/ortto"
import { wpApi } from "../../helpers/wordpress";

import * as styles from './clubs.module.css';

const Clubs = ({ userEmail }) => {
    const [clubList, setClubList] = useState(null);

    const fetchClubList = useCallback(async () => {
        const result = await getPerson(['Tags'], userEmail);
        if (result.status === 200 && "contacts" in result.response && result.response.contacts.length > 0) {
            // Successfully found
            if (result.response.contacts[0].fields.tags) {
                const clubSlugs = result.response.contacts[0].fields.tags.filter(tag => tag.indexOf('club-') === 0).map(tag => tag.replace('club-', ''));
                if (clubSlugs.length > 0) {
                    const clubs = await wpApi(`clubs?slug=${clubSlugs.join(',')}`);
                    if (clubs.status === 200 && clubs.response.length > 0) {
                        setClubList(clubs.response);
                    } else {
                        // Failed fetch of club data
                        setClubList(false);
                    }
                } else {
                    // no club tags found
                    setClubList(false);
                }
            } else {
                // No tags found
                setClubList(false);
            }
        } else {
            // Failed fetch of tags
            setClubList(false);
        }
    }, [userEmail]);

    useMemo(() => {
        if (clubList === null) {
            fetchClubList();
        }
    }, [clubList, fetchClubList]);

    const leaveClub = async (slug) => {
        const clubKey = `club-${slug}`;
        const userKey = userEmail;

        const person = [
            {
            email: userKey,
            unset_tags: [clubKey],
            }
        ]
        const updateResponse = await updatePerson(person);
        
        if (updateResponse.status === 200 && "people" in updateResponse.response) {
            // Successfully added
            fetchClubList();
        } else {
            // Error occurred
            // TODO: Show error somewhere
        }
    }

    return (
        <div className={styles.root}>
            {/* List clubs */}
            {(clubList && Array.isArray(clubList)) ? (
                <div>
                    {clubList.map((club, clubIndex) => (
                        <div className={styles.clubCard} key={clubIndex}>
                            <div className="row flex-center g-32">
                                <img src={club.acf.logo} alt="" />
                                <div>
                                    <h1 className="title">{club.acf.full_title || club.title.rendered}</h1>
                                    <p className="excerpt" dangerouslySetInnerHTML={{__html : club.excerpt.rendered}}></p>
                                </div>
                            
                            </div>
                            <div className="actions">
                                {/* TODO: Club execs to be identified by USU member ID in future once we have that data mapping */}
                                {(club.acf.club_execs && club.acf.club_execs.indexOf(userEmail) > -1) ? (
                                    <Button  className={'smallButtonGray'}  type="a" href="#">Manage</Button>
                                ) : (
                                    <Button   className={'smallButtonGray'} type="span" onClick={() => leaveClub(club.slug)}>Leave</Button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    {/* Fetching clubs */}
                    {clubList === null && (
                        <div>Fetching your clubs</div>
                    )}

                    {/* No clubs found */}
                    {clubList === false && (
                        <div>You have not joined any clubs</div>
                    )}
                </>
            )}
        </div>
    )
}

const ClubsOutput = () => (
    <AccountPageWrapper metaTitle="Account - Clubs" title="Clubs">
        <Clubs />
    </AccountPageWrapper>
)

export default ClubsOutput