import { Link, navigate } from 'gatsby';
import { get } from 'lodash';
// import parse from 'html-react-parser';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import AccountPageWrapper from '../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import ClubDetails from '../../../../../components/organisms/ClubDetails/ClubDetails';
import AuthContext from '../../../../../context/AuthProvider';
import { persistLocation } from '../../../../../helpers/general';
import { getPerson } from '../../../../../helpers/ortto';
import { wpApi } from '../../../../../helpers/wordpress';
import * as styles from '../../usu.module.css';

const Details = ({ location }) => {
  const auth = useContext(AuthContext);
  const userEmail = auth && auth.state.email;
  const [club, setClub] = useState(null);

  const fetchClubDetails = useCallback(async () => {
    setClub(null);
    const result = await getPerson(['Tags'], userEmail);
    if (
      result.status === 200 &&
      'contacts' in result.response &&
      Array.isArray(result.response.contacts)
    ) {
      // Successfully found
      if (result.response.contacts[0].fields.tags) {
        const clubSlugs = result.response.contacts[0].fields.tags
          .filter(tag => tag.indexOf('club-') === 0)
          .map(tag => tag.replace('club-', ''));
        const authorised = clubSlugs.find(
          slug => slug === location.state.clubSlug
        );
        if (
          authorised ||
          userEmail === 'ash@matterdesign.com.au' ||
          userEmail === 'developers+usu@matterdesign.com.au'
        ) {
          const clubData = await wpApi(`clubs/${get(location, 'state.clubId')}`);
          if (clubData.status === 200 && clubData.response) {
            setClub(clubData.response);
          } else {
            // Failed fetch of club data
            setClub(false);
          }
        } else {
          // no club tags found
          setClub(false);
        }
      } else {
        // No tags found
        setClub(false);
      }
    } else {
      // Failed fetch of tags
      setClub(false);
    }
  }, [userEmail, location]);

  useMemo(() => {
    if (club === null || (club && club.id !== get(location, 'state.clubId'))) {
      fetchClubDetails();
    }
  }, [club, fetchClubDetails, location]);

  return (
    <>
      {club ? (
        <ClubDetails club={club} />
      ) : (
        <div className={styles.root}>
          {/* Fetching clubs */}
          {club === null && <div>Fetching your club details</div>}
          {/* No clubs found */}
          {club === false && (
            <div>
              An error occurred. Return back to{' '}
              <Link to='/account/my-usu/clubs'>
                <u>list</u>
              </Link>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const ClubManagementDetails = ({ location }) => {
  const persistedLocation = persistLocation(location);
  
  const backToManagementPage = () => {
    navigate(`/account/my-usu/clubs/manage`, {
      state: persistedLocation.state,
    });
  };

  return (
    <AccountPageWrapper
      bgRaw={true}
      metaTitle='Account - Club Management'
      breadcrumbTitle='Edit Details'
      breadcrumbs={[
        { link: '/account/my-usu/clubs/', label: 'My USU' },
        {
          onClick: () => backToManagementPage(),
          label: get(persistedLocation, 'state.clubTitle', ''),
        },
      ]}
      menuType='clubManage'
      location={persistedLocation}>
      <Details location={persistedLocation} />
    </AccountPageWrapper>
  );
};

export default ClubManagementDetails;

