import React, { createContext, useContext, useState, useEffect } from 'react';
import { get, values, sumBy } from 'lodash';
import jwt from 'jsonwebtoken'
import moment from 'moment'
import AuthContext from './AuthProvider';
import { bcApi, handleBcAddToCartError } from '../helpers/bigcommerce';
/* SUPPORT FOR LOCALISR AND CnC */
import { dataLayerPush, getStorage, setStorage, setCookie, removeCookie } from '../helpers/general';
import { successAddToCart, error } from '../helpers/toast';
// import { LOCAL_STORE, parseJsonValue } from '../components/molecules/Localisr/Statics';

const CartContext = createContext();

const initialState = {
  cartLoading: false,
  cartError: false,
  cart: {
    currency: {
      code: 'USD'
    },
    cartAmount: 0,
    lineItems: {},
    numberItems: 0,
    redirectUrls: {}
  },
  shippingMethod: 'delivery',
  withGradHire: false,
  unableCheckout: false
  /* SUPPORT FOR LOCALISR AND CnC */

  // shippingMethod: getStorage(LOCAL_STORE.SHIPPING_METHOD) || '',
  // selectedStore: {
  //   store_id: getStorage(LOCAL_STORE.UID) || '',
  //   store_name: getStorage(LOCAL_STORE.NAME) || '',
  //   store_address: getStorage(LOCAL_STORE.ADDRESS) || '',
  //   store_location: parseJsonValue(getStorage(LOCAL_STORE.LOCATION)) || false,
  // },
  // stockLoading: false,
  // stockAvailability: []
};
const gradHireCategories = [84];

const getItemsIdInCart = lineItems => {
  return values(lineItems)
    .reduce((acc, curr) => {
      return [
        ...acc,
        ...curr.map(ite => ({ item_id: ite.id, quantity: ite.quantity }))
      ];
    }, [])
    .filter(x => x);
};

export const CartProvider = ({ children }) => {
  const auth = useContext(AuthContext);
  const customer = auth && auth.state;
  const [state, setState] = useState(initialState);
  const [notifications, updateNotifications] = useState([]);

  const addNotification = (text, type = 'notify') => {
    updateNotifications([...notifications, { text, type, id: Date.now() }]);
  };

  const removeNotification = id => {
    updateNotifications(notifications.filter(ntfy => ntfy.id !== id));
  };

  const loadQuote = async quoteToken => {
    // TODO: If we can work out what secret BC uses to generate it, we can further secure it.
    // jwt.verify(quoteToken, process.env.BC_CLIENT_SECRET, (err, decoded) => {
    //   console.log(err, decoded);
    // })

    const quote = jwt.decode(quoteToken);
    if (quote && quote.iss === process.env.BC_PATH && quote.exp > moment().unix() && get(quote, 'domain.cart.id')) {
      const cartId = get(quote, 'domain.cart.id');
      await bcApi('carts', 'GET', null, null, cartId).then(({response, status}) => {
        if (response) {
          return refreshCart({...response, status});
        } else {
          setState({ ...state, cartFetched: true });
          return false;
        }
      })
    }
  }

  const loadCart = async cartId => {
    setCookie('cartId', cartId, true, '/.netlify/functions');
    await bcApi('carts', 'GET', null, null, cartId).then(({response, status}) => {
      // console.log(response);
      if (response) {
        return refreshCart({...response, status});
      } else {
        setState({ ...state, cartFetched: true });
        return false;
      }
    });
  }

  const fetchCart = async () => {
    setState({ ...state, cartFetched: false });
    return await bcApi('carts')
      .then(({ response }) => {
        if (response && 'data' in response) {
          return refreshCart(response);
        } else {
          setState({ ...state, cartFetched: true });
          return false;
        }
      })
      .catch(error => {
        setState({ ...state, cartLoading: false, cartFetched: false, cartError: error });
        return false;
      });
  };

  useEffect(() => {
    fetchCart();
    // eslint-disable-next-line
  }, []);

  const calculateNumberItems = lineItems => {
    const {
      physical_items = [],
      digital_items = [],
      custom_items = [],
      gift_certificates = []
    } = lineItems || {};
    const numberPhysical = sumBy(physical_items, ite => ite.quantity) || 0;
    const numberDigital = sumBy(digital_items, ite => ite.quantity) || 0;
    const numberCustom = sumBy(custom_items, ite => ite.quantity) || 0;
    const numberGift = sumBy(gift_certificates, ite => ite.quantity) || 0;
    return numberPhysical + numberDigital + numberCustom + numberGift;
  };

  const updateState = response => {
    return new Promise(res => {
      // console.log(response);
      const lineItems = response.data.line_items;
      const cartAmount = response.data.cart_amount;
      const baseAmount = response.data.base_amount;
      const currency = response.data.currency;
      const cartId = response.data.id;
      const coupons = response.data.coupons;

      // Fetch additional product data
      // const skus = response.data.line_items.physical_items.map(product => product.sku);
      const productIds =
      [ ...response.data.line_items.physical_items.map(product => product.product_id),
        ...response.data.line_items.digital_items.map(product => product.product_id)
      ];

      let withGradHire = false;
      const itemTypes = Object.keys(response.data.line_items);
      itemTypes.map(itemType => {
        response.data.line_items[itemType].map(item => {
          if ([3049].includes(item.product_id)) {
            withGradHire = true;
          }
  
          return item;
        });
      });

      bcApi(`catalog/products?include=variants&id:in=${productIds.join(',')}`).then(productData => {
        let cartUnableCheckout = false
        const productInfo = {};
        // const skus = [];
        productData.response.data.map(product => {
          
          let thisUnable = false;
          if (product.id !== 3049) {
            if (!isValidGradProductToCart(product, withGradHire)) {
              cartUnableCheckout = true;
              thisUnable = true;
            }
          }

          product['no_qty'] = product.id === 3049 ? true : false;
          product['unable_item'] = thisUnable;
          productInfo[product.id] = product;
          // skus.push(product.sku);
          return true;
        });

        /* Extend the cart products object here if required. For example, fetch additional product detail from a PIM and add it to the product info */

        const newState = {
          ...state,
          cartFetched: true,
          cartLoading: false,
          updatingItem: false,
          coupons,
          cart: {
            cartId,
            currency,
            cartAmount,
            lineItems,
            productInfo,
            baseAmount,
            numberItems: calculateNumberItems(lineItems),
            redirectUrls: response.data.redirect_urls || state.cart.redirectUrls
          },
          withGradHire: withGradHire,
          unableCheckout: cartUnableCheckout
        };
        setState(newState);
        refreshCheckout(cartId, newState);

        res(true);
      });
    });
  }

  const refreshCart = response => {
    if (response.status === 204 || response.status === 404 || response.status === 422) {
      setState({ ...state, cartLoading: false });
    } else {
      return updateState(response);
    }
  };

  const refreshCheckout = (cartId, theState) => {
    bcApi(`checkouts/${cartId}`).then(response => {
      if (response.status === 200) {
        setState({...theState, checkout: response.response.data});
      }
    });
  };

  const initCheckout = async () => {
    const {response, status} = await bcApi(`checkouts/${state.cart.cartId}`);
    if (status === 200) {
      setState({...state, checkout: response.data});
      return {...state, checkout: response.data};
    }
    return state;
  };

  const clearCart = (goTo) => {
    const cartId = 'cartId' in state.cart ? state.cart.cartId : null;
    // console.log(cartId);
    if (cartId) {
      bcApi(`carts/${cartId}`, 'DELETE').then(response => {
        // console.log(response);
        setState({ ...state, cartLoading: false });
        if (typeof window !== 'undefined') {
          if(goTo === undefined) {
            window.location.reload();
          } else {
            window.location.href = goTo;
          }

        }
      })
    } else {
      // No cart in state so lets make sure the session cart ID is cleared too.
      removeCookie('cartId');
    }
  }

  const addToCart = (productId, variantId, retry, quantity = 1, customPrice) => {
    // console.log('Adding to Cart: ',productId, variantId, retry, quantity);
    // console.log("State at time", {...state});
    // console.log("Customer state", customer);
    return new Promise((res, rej) => {
      setState({ ...state, addingToCart: productId });

      const cartBody = {
        line_items: [
          {
            quantity: parseInt(quantity, 10),
            product_id: parseInt(productId, 10),
            variant_id: parseInt(variantId, 10),
            list_price: customPrice,
          }
        ]
      };
      const bcApiBody = JSON.stringify(cartBody);
      bcApi('carts/items', 'POST', bcApiBody)
        .then(({ response, status }) => {
          if (status === 404 && !retry) {
            // re create a cart if cart was destroyed
            const newCartBody = {};
            if (customer.isLoggedIn) {
              newCartBody.customer_id = customer.customerId;
            }
            return bcApi('carts', "POST", newCartBody).then(() =>
              addToCart(productId, variantId, true, quantity, customPrice)
            );
          }

          // handle error conditions
          if(String(status).startsWith('2') === false) {
            const message = handleBcAddToCartError(response, status);
            setState({ ...state, addingToCart: false, addToCartError: response });
            error(message);
            return;
          }

          if ('data' in response) {
            status < 300 && addNotification('Item added successfully');
            // success('Product added to cart')
            successAddToCart('Product added to cart')

            // If we have a logged in customer and the cart is not bound to that customer, bind it now
            if (response.data.customer_id !== customer.customerId) {
              bcApi('carts', 'PUT', {customer_id: customer.customerId}).then(response => {
                refreshCart(response.response);
              });
            }

            const addedProduct = [];
            const itemTypes = Object.keys(response.data.line_items);
            itemTypes.map(itemType => {
              response.data.line_items[itemType].map(item => {
                // console.log(item);

                if (item.product_id === productId) {
                  addedProduct.push({
                    id: `${item.product_id}_${item.variant_id}`,
                    title: item.name,
                    price: item.price || item.list_price || 0,
                    quantity: item.quantity
                  })
                }
                return true;
              });
              return true;
            });

            dataLayerPush('addToCart', null, addedProduct);
            // console.log('success',response);
            updateState(response).then(() => {
              res(true);
            });
          } else if (response.status > 300 && 'title' in response) {
            setState({ ...state, addingToCart: false, addToCartError: response });
            rej(response.title);
          }
        })
        .catch(error => {
          setState({ ...state, addingToCart: false, addToCartError: error });
          rej(error);
        });
    });
  };

  const addAllToCart = (items, retry) => {
    return new Promise((res, rej) => {
      const lineItems = items.filter(product => 'product_id' in product ? (product.product_id > 0) : (parseInt(product[0], 10) > 0)).map(product => {
        if ('product_id' in product) {
          return {
            quantity: 'quantity' in product ? parseInt(product.quantity, 10) : 1,
            product_id: parseInt(product.product_id, 10),
            variant_id: parseInt(product.variant_id, 10)
          };
        } else {
          return {
            quantity: 1,
            product_id: parseInt(product[0], 10),
            variant_id: parseInt(product[1], 10)
          };
        }
      });

      if (lineItems.length > 0) {
        const bcApiBody = JSON.stringify({
          line_items: lineItems
        });
        bcApi('carts/items', 'POST', bcApiBody)
          .then(({ response, status }) => {
            if (status === 404 && !retry) {
              // re create a cart if cart was destroyed
              return bcApi('carts').then(() => addAllToCart(items, true));
            }

            if (status === 422) {
              rej(response);
              return;
            }
            status < 300 && addNotification('Item added successfully');

            const addedProduct = [];
            const itemTypes = Object.keys(response.data.line_items);
            itemTypes.map(itemType => {
              response.data.line_items[itemType].map(item => {

                // console.log(item.price);

                if (lineItems.find(product => item.product_id === product.product_id)) {
                  addedProduct.push({
                    id: `${item.product_id}_${item.variant_id}`,
                    title: item.name,
                    price: item.price || item.list_price || 0,
                    quantity: item.quantity
                  })
                }
                return true;
              });
              return true;
            })

            dataLayerPush('addToCart', null, addedProduct);

            updateState(response).then(() => {
              res(true);
            });
          })
          .catch(error => {
            setState({ ...state, addingToCart: false, addToCartError: error });
            rej(error);
          });
      } else {
        setState({ ...state, addingToCart: false, addToCartError: { title: 'No products to add' } });
        rej({ title: 'No products to add' });
      }
    });
  };

  const updateItemInCart = (item, oldQty, itemId, updatedItemData) => {
    const bcApiBody = JSON.stringify(updatedItemData);
    const newQuantity = updatedItemData.line_item.quantity;
    const dataLayerEvent = newQuantity > oldQty ? 'addToCart' : newQuantity < oldQty  ? 'removeFromCart' : undefined;
    const qtyChange = newQuantity > oldQty ? newQuantity - oldQty : newQuantity < oldQty ? oldQty - newQuantity : 0;
    // @see https://developer.bigcommerce.com/api-reference/store-management/carts/cart-items/updatecartlineitem
    bcApi(`carts/${state.cart.cartId}/items/${itemId}?include=redirect_urls,line_items.physical_items.options,line_items.digital_items.options`, 'PUT', bcApiBody)
      .then(({ response }) => {

        // insufficient stock - weirdly this passes into this code block
        if(response.status === 422) {
          error('Insufficient item stock');
          // refreshCart(response);
          fetchCart();
        } else {
          refreshCart(response);
          if (dataLayerEvent && item) {
            item.quantity = qtyChange;
            dataLayerPush(dataLayerEvent, null, item);
          }
        }
      })
      .catch(error => {
        setState({ ...state, cartLoading: false, cartError: error });
      });
  };

  const removeItemFromCart = itemId => {
    // @see https://developer.bigcommerce.com/api-reference/store-management/carts/cart-items/deletecartlineitem
    setState((state) => {
      return {
        ...state,
        updatingItem: itemId,
        cartLoading: true,
      }
    });

    const removedProduct = [];
    const itemTypes = Object.keys(state.cart.lineItems);
    itemTypes.map(itemType => {
      state.cart.lineItems[itemType].map(item => {
        if (item.id === itemId) {
          removedProduct.push({
            id: `${item.product_id}_${item.variant_id}`,
            title: item.name,
            price: item.price || 0,
            quantity: item.quantity
          });
        }
        return true;
      });
      return true;
    });

    bcApi(`carts/${state.cart.cartId}/items/${itemId}?include=redirect_urls,line_items.physical_items.options,line_items.digital_items.options`, 'DELETE')
      .then(({ response, status }) => {
        // console.log(response);
        // addNotification('Item removed successfully');
        if (status === 204) {
          // Refresh page to clear the cookie data from page session
          if (typeof window !== 'undefined') {
            clearCart(window.location.href);
          }
          setState({...initialState, cartFetched: true});
          return true;
        }
        // addNotification('Item removed successfully');

        dataLayerPush('removeFromCart', null, removedProduct);

        /* SUPPORT FOR LOCALISR AND CnC */
        // const stockAvailability = [...state.stockAvailability];
        // if (stockAvailability.length > 0) {
        //   const availabilityData = [];
        //   response.data.line_items.physical_items.map(a => {
        //     const availableData = stockAvailability.find(s => s.sku === a.sku);
        //     if (availableData) {
        //       availabilityData.push(availableData);
        //     }
        //     return true;
        //   });
        //   updateStockAvailability(availabilityData);
        //   refreshCart(response);

        // } else {
          refreshCart(response);
        // }
      })
      .catch(error => {
        setState({ ...state, cartLoading: false, cartError: error });
      });
  };

  const updateCartItemQuantity = (item, action, oldQty) => {
    let newQuantity;
    if (['minus', 'plus'].indexOf(action) > -1) {
      newQuantity = item.quantity + (action === 'minus' ? -1 : 1);
    } else {
      newQuantity = action;
    }
    setState((state) => {
      return {
        ...state,
        updatingItem: item.id,
        cartLoading: true
      }
    });
    if (newQuantity < 1) {
      return removeItemFromCart(item.id);
    }
    let productVariantReferences = null;

    if (typeof item.product_id !== 'undefined') {
      productVariantReferences = {
        product_id: item.product_id,
        variant_id: item.variant_id
      };
    }

    updateItemInCart(item, oldQty, item.id, {
      line_item: {
        quantity: newQuantity,
        ...productVariantReferences
      },

    });
  };

  const addCoupons = async coupon_code => {
    return new Promise(async (res, rej) => {
      const endpoint = `checkouts/${state.cart.cartId}/coupons`;
      const reqBody = { coupon_code };
      try {
        const { response, status } = await bcApi(endpoint, 'POST', reqBody);
        const coupons = get(response, 'data.coupons');
        if (status === 200 && coupons) {
          setState({
            ...state,
            coupons
          });
          res(coupons);
        } else {
          rej(response);
        }
      } catch (error) {
        setState({ ...state, cartLoading: false, cartError: error });
        rej(error);
      }
    });
  };

  const removeCoupons = async coupon_code => {
    const endpoint = `checkouts/${state.cart.cartId}/coupons/${coupon_code}`;
    try {
      const { response, status } = await bcApi(endpoint, 'DELETE');
      const coupons = get(response, 'data.coupons');
      if (status === 200 && coupons) {
        setState({
          ...state,
          coupons
        });
        return coupons;
      } else {
        return response;
      }
    } catch (error) {
      console.log(error);
      setState({ ...state, cartLoading: false, cartError: error });
      return error;
    }
  };

  const addConsignments = async shippingAddress => {
    const { cartId, lineItems } = state.cart;
    if (cartId) {
      const endpoint = `checkouts/${cartId}/consignments`;
      const req_body = [
        {
          shipping_address: shippingAddress,
          line_items: getItemsIdInCart(lineItems) //lineItems?
        }
      ];

      try {
        const { response, status } = await bcApi(endpoint, 'POST', req_body);
        if (status === 200) {
          setState({
            ...state,
            consignments: response.data.consignments
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const updateConsignments = async (shippingAddress, consignmentId) => {
    // PUT /checkouts/{checkoutId}/consignments/{consignmentId}
    const {
      cart: { cartId, lineItems }
    } = state;
    if (cartId) {
      const endpoint = `checkouts/${cartId}/consignments/${consignmentId}`;
      const req_body = {
        shipping_address: shippingAddress,
        line_items: getItemsIdInCart(lineItems)
      };
      try {
        const { status } = await bcApi(endpoint, 'PUT', req_body);
        if (status === 200) {
          setState({
            ...state,
            consignments: []
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const removeConsignments = async (_state) => {
    const { response, status } = await bcApi(`checkouts/${state.cart.cartId}`);
    if (status === 200) {
      const _consignments = response.data.consignments || [];
      if (_consignments && typeof _consignments !== 'undefined' && _consignments.length) {
        const endpoint = `checkouts/${state.cart.cartId}/consignments/${_consignments[0].id}`;
        const resRemoved = await bcApi(endpoint, 'DELETE');
        if (resRemoved.status === 200) {
          setState({ ..._state, consignments: resRemoved.response.data.consignments || [], checkout: resRemoved.response.data });
        }
      }
    }
  }

  const addGiftCertificates = async giftCertCode => {
    const { cartId } = state.cart;
    if (cartId) {
      const endpoint = `checkouts/${cartId}/gift-certificates`;
      // TODO: make this request to work
      try {
        const { response, status } = await bcApi(endpoint, 'POST', {
          giftCertificateCode: giftCertCode
        }, 'sf');
        console.log(status, response);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const changeShippingMethod = async (_method, _state = false) => {
    /* SUPPORT FOR LOCALISR AND CnC */
    // setStorage(LOCAL_STORE.SHIPPING_METHOD, _method);
    const _newState = { ...(_state ? _state : state), shippingMethod: _method };
    setState(_newState);

    if (_method === 'collect') {
      const result = await setPickupConsignment(_newState);
      if (result === 'forceDelivery') {
        await removeConsignments({ ..._newState, shippingMethod: 'delivery' });
        storeCheckoutData({ shipping: {} });
        return 'forceDelivery';
      } else {
        return true;
      }
    } else {
      await removeConsignments(_newState);
      storeCheckoutData({ shipping: {} });
      return true;
    }
  }

  const changeSelectedStore = (_store) => {
    /* SUPPORT FOR LOCALISR AND CnC */
    // store addresses
    // setStorage(LOCAL_STORE.UID, _store.store_id);
    // setStorage(LOCAL_STORE.NAME, _store.store_name);
    // setStorage(LOCAL_STORE.ADDRESS, _store.store_address);
    // setStorage(LOCAL_STORE.LOCATION, JSON.stringify(_store.store_location));

    // update state
    setState({ ...state, selectedStore: _store });
  }

  const storeCheckoutData = (obj) => {
    const storeKey = '__jammcd';
    const existingJSON = getStorage(storeKey) || JSON.stringify({});
    const existing = JSON.parse(existingJSON);
    const newSet = JSON.stringify({...existing, ...obj});
    setStorage(storeKey, newSet);
  }

  const setPickupConsignment = async (_state) => {
    await removeConsignments(_state);

    const { cartId, lineItems } = _state.cart;
    const { selectedStore } = _state;
    const endpoint = `checkouts/${cartId}/consignments?include=consignments.available_shipping_options`;

    if (lineItems.physical_items.length === 0) {
      // Cart can not be collected
      return 'forceDelivery';
    }

    if (!selectedStore || `${selectedStore.store_name}`.trim() === '') {
      return false;
    }

    try {
      // console.log(selectedStore);
      const resAddConsignment = await bcApi(endpoint, 'POST', [
        {
          shipping_address: {
            first_name: selectedStore.store_name,
            last_name: selectedStore.store_id,
            address1: selectedStore.store_location.address_1,
            city: selectedStore.store_location.suburb,
            phone: selectedStore.store_location.phone,
            email: selectedStore.store_location.email,
            postal_code: selectedStore.store_location.postcode,
            state_or_province: selectedStore.store_location.state,
            country_code: selectedStore.store_location.country,
          },
          line_items: getItemsIdInCart(lineItems)
        }
      ]);
      if (resAddConsignment.status === 200 && resAddConsignment.response.data.consignments.length > 0) {
        const _pickupOption = resAddConsignment.response.data.consignments[0].available_shipping_options.filter(a => ['click', 'collect', 'pickup'].filter(b => a.description.toLowerCase().includes(b)).length > 0);
        if (_pickupOption.length) {
          const endpoint = `checkouts/${state.cart.cartId}/consignments/${resAddConsignment.response.data.consignments[0].id}`;
          const resUpdatedCheckout = await bcApi(endpoint, 'PUT', { shipping_option_id: _pickupOption[0].id });
          if (resUpdatedCheckout.status === 200) {
            setState({
              ..._state,
              consignments: resAddConsignment.response.data.consignments,
              checkout: resUpdatedCheckout.response.data
            });
            storeCheckoutData({
              shipping: resUpdatedCheckout.response.data.consignments[0].shipping_address
            });
            return true;
          }
          return false;
        } else {
          // Preselected store - cart can not be collected
          return 'forceDelivery';
        }
      } else {
        // Preselected store - cart can not be collected
        return 'forceDelivery';
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  const isGradMainItem = (item) => {

  }

  const isValidCartItemsGradHire = () => {
    if (state) {
      return state && state.unableCheckout ? false : true; 
    }
  }

  const isValidGradProductToCart = (product, withBypassGrad = false) => {

    if (!product) return false;

    const withGradHire = withBypassGrad ? withBypassGrad : (state ? state.withGradHire : false);

    const isInGradHireCategory = [];
    if (product && product.categories) {
      product.categories.map((category) => {
        const id = typeof category === 'object' ? category.id : category;
        if (gradHireCategories.includes(id)) {
          isInGradHireCategory.push(true);
        } else {
          isInGradHireCategory.push(false);
        }

        return category;
      })
    }

    const prodCatLegnth = product.categories.length;
    const allGradProductsCat = isInGradHireCategory.filter((flag) => flag)

    if (!withGradHire && allGradProductsCat.length !== prodCatLegnth) {
      return true;
    }

    if (withGradHire && allGradProductsCat.length > 0) {
      return true;
    }
    
    return false;
  }

  const loadingStock = loading => {
    setState({ ...state, stockLoading: loading});
  }

  const updateStockAvailability = async (_stocks) => {
    setState({ ...state, stockAvailability: _stocks });
  }

  return (
    <CartContext.Provider
      value={{
        state,
        loadQuote,
        loadCart,
        fetchCart,
        addToCart,
        addAllToCart,
        addCoupons,
        removeCoupons,
        removeItemFromCart,
        updateCartItemQuantity,
        clearCart,
        notifications,
        addNotification,
        removeNotification,
        addConsignments,
        addGiftCertificates,
        updateConsignments,
        changeShippingMethod,
        changeSelectedStore,
        loadingStock,
        updateStockAvailability,
        initCheckout,
        isValidGradProductToCart,
        isValidCartItemsGradHire,
        isGradMainItem
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
