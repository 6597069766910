import React, { useContext, useMemo, useState } from "react";
import { Link, navigate } from "gatsby";
import { get } from "lodash";
import { renderToString } from 'react-dom/server';
// import parse from 'html-react-parser';
import AccountPageWrapper from "../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper";
import AuthContext from "../../../../../context/AuthProvider";
import AccordionCard from "../../../../../components/atoms/AccordionCard/AccordionCard";
import RichTextField from "../../../../../components/atoms/RichTextField/RichTextField";
import OrttoEmailTemplate from "../../../../../components/organisms/OrttoEmailTemplate/OrttoEmailTemplate";
import Button from "../../../../../components/atoms/Button/Button";
import { persistLocation } from "../../../../../helpers/general";
import { sendEmail } from "../../../../../helpers/ortto";
import { wpApi, authClubExec } from "../../../../../helpers/wordpress";
import * as styles from '../../usu.module.css';

const Preferences = ({location}) => {
    const auth = useContext(AuthContext);
    const memberNumber = auth && get(auth, 'state.usu.MemberNumber');
    const [isAuthed, setAuthed] = useState(null);
    const [club, setClub] = useState(null);
    const [init, setInit] = useState(false);
    const [disableActions, setDisable] = useState(false);
    const [saveSenderLabel, setSenderSave] = useState('Update');
    const [saveWelcomeLabel, setWelcomeSave] = useState('Save');
    const [previewWelcomeLabel, setWelcomePreview] = useState('Send preview');
    const [senderData, setSenderData] = useState({
        sender_name: '',
        sender_email: ''
    });

    const [automatedEmails, setAutomatedEmails] = useState({
        welcome: {
            enabled: false,
            subject: '',
            body: ''
        }
    });
    const [automatedInitial, setAutomatedInitial] = useState({
        welcome: {
            enabled: false,
            subject: '',
            body: ''
        }
    });

    useMemo(() => {
        if (isAuthed === null || (isAuthed &&  club && club.id !== get(location, 'state.clubId'))) {
            authClubExec(memberNumber, get(location, 'state.clubId')).then(authed => {
                if (String(authed.status).startsWith("2") && authed.response) {
                    setAuthed(true);
                    setClub(authed.response);
                    const clubPreferences = authed.response.acf.club_preferences;
                    setSenderData({
                        sender_name: clubPreferences.sender_name,
                        sender_email: clubPreferences.sender_email
                    });
                    const eData = {
                        welcome: {
                            enabled: clubPreferences.welcome.enabled,
                            subject: clubPreferences.welcome.subject,
                            body: clubPreferences.welcome.content
                        }
                    };
                    setAutomatedInitial(eData);
                    setAutomatedEmails(eData);
                    setInit(true);
                } else {
                    setAuthed(false);
                }
            });
        }
    }, [isAuthed, memberNumber, location, club]);

    const updateSender = (field, value) => {
        const state = {...senderData};
        state[field] = value;
        setSenderData({...state});
    }

    const updateEmails = (email, field, value) => {
        const state = {...automatedEmails};
        state[email][field] = value;
        setAutomatedEmails({...state});
    }

    const returnWelcomeBody = (body) => {
        updateEmails('welcome', 'body', body);
    }

    const charCount = (email) => {
        return 120 - automatedEmails[email].subject.length;
    }

    const saveSender = () => {
        setDisable(true);
        setSenderSave('Saving...');
        
        // console.log('Save:', senderData);
        return wpApi(`clubs/${location.state.clubId}`, 'POST', {
            fields: {
                club_preferences: senderData
            },
        }).then(response => {
            // console.log(response);
            setDisable(false);
            if (String(response.status).startsWith("2") && 'id' in response.response) {
                setSenderSave('Saved!');
                setTimeout(() => {
                    setSenderSave('Update');
                }, 5000);
            }

            return true;
        })
    }

    const processEmail = (email, recipients) => {
        const emailBody = renderToString(<OrttoEmailTemplate logo={club.acf.logo} clubName={club.title.rendered} content={automatedEmails[email].body} />);
        // console.log("Email Body:", emailBody);

        const emailOptions = {
            html_body: emailBody,
            subject: automatedEmails[email].subject,
            email_name: `${club.title.rendered} club automation - ${email} (TEST)`,
            from_name: club.acf.club_preferences.sender_name,
            reply_to: club.acf.club_preferences.sender_email
        };
        
        return sendEmail(emailOptions, recipients).then(postResponse => {
            // console.log(postResponse);

            return postResponse;
        });
    }

    const handleButtonSave = (email, value) => {
        switch (email) {
            case 'welcome':
                setWelcomeSave(value);
                break;

            default: 
                // Do nothing
        }
    }

    const handleButtonPreview = (email, value) => {
        switch (email) {
            case 'welcome':
                setWelcomePreview(value);
                break;

            default: 
                // Do nothing
        }
    }

    const toggleEmail = (e) => {
        updateEmails(e.target.name, 'enabled', e.target.checked);
    }

    const saveEmail = (email) => {
        setDisable(true);
        const obj = {};
        handleButtonSave(email, 'Saving...');
        
        // console.log('Save:', automatedEmails[email]);
        obj[email] = {
            enabled: automatedEmails[email].enabled,
            subject: automatedEmails[email].subject,
            content: automatedEmails[email].body
        };
        return wpApi(`clubs/${location.state.clubId}`, 'POST', {
            fields: {
                club_preferences: obj
            },
        }).then(response => {
            // console.log(response);
            setDisable(false);
            if (String(response.status).startsWith("2") && 'id' in response.response) {
                handleButtonSave(email, 'Saved!');
                setTimeout(() => {
                    handleButtonSave(email, 'Save');
                }, 5000);
            }

            return true;
        })
    }

    const sendPreview = (email) => {
        handleButtonPreview(email, `Sending preview...`);
        console.log(`Send:`, automatedEmails[email]);
        
        const emailRecipients = [{
            email: auth.state.email,
            first: auth.state.object.first_name,
            last: auth.state.object.last_name
        }];
        // const emailRecipients = [{
        //     email: "ash@matterdesign.com.au",
        //     first: "Ash",
        //     last: "Durham"
        // },{
        //     email: "sruti@matterdesign.com.au",
        //     first: "Sruti",
        //     last: "Venkat"
        // }];

        saveEmail(email).then(() => {
            setDisable(true);
            processEmail(email, emailRecipients).then(result => {
                // console.log('Result:', result);
    
                if (String(result.status).startsWith("2") && 'emails' in result.response) {
                    setDisable(false);
                    handleButtonPreview(email, 'Sent!');
                    setTimeout(() => {
                        handleButtonPreview(email, `Send preview`);
                    }, 5000);
                } else {
                    setDisable(false);
                    // TODO: Inform that something went wrong
                }
            });
        })
    }

    return (
        <>
            {(club) ? (
                <>
                    <AccordionCard title="Set a default sender for Emails">
                        <div className="grid" style={{gridTemplateColumns: '1.2fr 1.2fr 0.6fr'}}>
                            <div className="formField">
                                <label htmlFor="sender_name">From name</label>
                                {/* {console.log("Render senderData:", senderData)} */}
                                <input type="text" id="sender_name" placeholder="Example: Club Name" defaultValue={senderData.sender_name} onChange={(e) => updateSender('sender_name', e.target.value)} />
                            </div>
                            <div className="formField">
                                <label htmlFor="sender_email">Reply to (valid email)</label>
                                <input type="text" id="sender_email" placeholder="Example: club@email.com" defaultValue={senderData.sender_email} onChange={(e) => updateSender('sender_email', e.target.value)} />
                            </div>
                            <div className="formField">
                                <label htmlFor="saveSender">&nbsp;</label>
                                <Button level="primary" size="small" id="saveSender" type="button" disabled={disableActions} onClick={() => saveSender()}>{saveSenderLabel}</Button>
                            </div>
                        </div>
                    </AccordionCard>

                    <AccordionCard title="Welcome email" toggleSelector={toggleEmail} selectorName="welcome" isChecked={automatedEmails.welcome.enabled}>
                        <div className={styles.root}>
                            <p>Send a welcome email when new members join.</p>
                            <div className="formField">
                                <label htmlFor="welcomeSubject">Email subject <span className="floatRight">{charCount('welcome')} characters remaining</span></label>
                                <input type="text" id="welcomeSubject" defaultValue={automatedInitial.welcome.subject} onChange={(e) => updateEmails('welcome', 'subject', e.target.value)} />
                            </div>
                            <div className="formField">
                                <label htmlFor="welcomeBody">Email body</label>
                                {init && (
                                    <RichTextField defaultValue={automatedInitial.welcome.body} returnValue={returnWelcomeBody} />
                                )}
                            </div>
                            <div className="buttonGroup stretch">
                                <Button level="secondary" type="button" disabled={disableActions} onClick={() => sendPreview('welcome')}>{previewWelcomeLabel}</Button>
                                <Button level="primary" type="button" disabled={disableActions} onClick={() => saveEmail('welcome')}>{saveWelcomeLabel}</Button>
                            </div>
                        </div>
                    </AccordionCard>
                </>
            ) : (
                <div className={styles.root}>
                    {/* Fetching club preferences */}
                    {((isAuthed === null && club === null) || (isAuthed === true && club === null)) && (
                        <div>Fetching your club preferences</div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === true && club === false) && (
                        <div>An error occurred. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === false) && (
                        <div>You are not authorised to manage this club. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                </div>
            )}
        </>
    )
}

const ClubManagementPreferences = ({location}) => {
    const persistedLocation = persistLocation(location);
    
    const backToManagementPage = () =>{
        navigate(`/account/my-usu/clubs/manage`,{
            state: persistedLocation.state
        })
    }

    return (
        <AccountPageWrapper bgRaw metaTitle="Account - Club Management" title="Club Preferences" breadcrumbTitle="Club Preferences" breadcrumbs={[{ link: '/account/my-usu/clubs/', label: 'My USU' }, { onClick: () => backToManagementPage(), label: get(persistedLocation, 'state.clubTitle', '')}]} menuType="clubManage" location={persistedLocation}>
            <Preferences location={persistedLocation} />
        </AccountPageWrapper>

    )
}

export default ClubManagementPreferences