import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby';
import { decode } from 'he';
import AccordionCard from '../../atoms/AccordionCard/AccordionCard';
import Button from '../../atoms/Button/Button';
import FormInputField from '../../atoms/FormInputField/FormInputField';
import SelectField from '../../atoms/SelectField/SelectField'
import DateTimeInput from '../../atoms/DateTimeInput/DateTimeInput';
import { wpApi } from '../../../helpers/wordpress';
import { toast } from 'react-toastify';

import * as styles from './ClubApplication.module.css';

const ClubApplication = ({ club, forApproval = false }) => {
  const [data, setData] = useState({});
  const [saving, setSaving] = useState(false);
  const startDateRef = useRef();

//   const submitClubHandler = async () => {
//     try {
//       setSaving(true);
      
//       await wpApi(`clubs/${data.id}`, 'POST', {
//         fields: acfFields,
//       });

//       toast.success('Changes have been sent for approval.');
//     } catch (error) {
//       toast.error('An unexpected error has occured.\nPlease try again.');
//     } finally {
//       setSaving(false);
//     }
//   };

  const approveChangesHandler = async rejected => {
    try {
      setSaving(true);

      let postData = {
        status: 'rejected'
      };
      if (!rejected) {
        postData = {
            status: data.status === 'applied' ? 'signatures' : data.status,
            title: data.club_display_name,
            content: data.content,
            fields: {
                full_title: data.full_title,
                // abbr: data.abbr,
                details: {
                    contact: data.contact,
                    email: data.email,
                    // phone: data.phone,
                    mobile: data.mobile,
                },
                application_settings: {
                    campaign_start_date: data.campaign_start_date,
                    accepting_signatures_period: data.accepting_signatures_period,
                    minimum_amount_of_signatures_required: data.minimum_amount_of_signatures_required
                },
                agm_month: new Date()
                  .toLocaleString('en-au', { month: 'long' })
                  .toLowerCase(),
            }
        }
      }

      await wpApi(`clubs/${data.id}`, 'POST', postData);
      toast.success(rejected ? 'Application has been rejected' : (data.status === 'applied' ? 'Application has been moved to the next stage' : 'Application updated'));
      navigate('/account/manage-clubs/');
    } catch (error) {
      toast.error('An unexpected error has occured.\nPlease try again.');
    } finally {
      setSaving(false);
    }
  };

  const changeEventHandler = (id, value) => {
    let tempData = Object.assign({}, data);
    tempData[id] = value;
    setData(tempData);
  };

  const returnBody = (body, target) => {
    changeEventHandler(target, body);
  };

  useEffect(() => {
    const pageInit = async () => {
      const initData = {
        id: club.id,
        full_title: club.acf.full_title ? decode(club.acf.full_title) : '',
        content: club.content.rendered,
        category: club.categories,
        status: club.status,
        club_display_name: club.title.rendered ? decode(club.title.rendered) : '',
        // abbr: club.acf.abbr,
        contact: club.acf.details.contact,
        email: club.acf.details.email,
        mobile: club.acf.details.mobile,
        // phone: club.acf.details.phone,
        constitution_document: club.acf.application_fields?.constitution_document,
        campus: club.acf.application_fields?.campus,
        affiliations: club.acf.application_fields?.affiliations,
        affiliation_detail: club.acf.application_fields?.affiliation_detail,
        similar_club_details: club.acf.application_fields?.similar_club_details,
        campaign_start_date: club.acf.application_settings?.campaign_start_date || new Date(new Date().getTime() + (-new Date().getTimezoneOffset())*60000).toISOString().split('.')[0],
        accepting_signatures_period: club.acf.application_settings?.accepting_signatures_period || 30,
        minimum_amount_of_signatures_required: club.acf.application_settings?.minimum_amount_of_signatures_required || 20,
      };

      setData({ ...data, ...initData });
    };
    pageInit();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
        <AccordionCard title={'Club application'}>
            <div className={styles.root}>
                <FormInputField
                    id='full_title'
                    type='input'
                    labelName='Full Club Name'
                    value={data?.full_title}
                    handleChange={changeEventHandler}
                />

                <div className={`grid grid-50 grid-responsive `}>
                    <div>
                        <FormInputField
                            id='club_display_name'
                            type='input'
                            labelName='Club Display Name'
                            value={data?.club_display_name}
                            handleChange={changeEventHandler}
                        />
                    </div>
                    <div>
                        <SelectField
                            id='campus'
                            label={'Campus will be based on'}
                            placeholder={'Select...'}
                            value={data?.campus}
                            handleChange={changeEventHandler}
                            data={[{value: 'main', text: 'Main campus - Camperdown'}, {value: 'music', text: 'The Conservatorium of Music'}, {value: 'rural', text: 'Rural Campus'}, {value: 'other', text: 'Other Satellite Campus'}]}
                        />
                    </div>
                </div>

                <div className={`grid grid-50 grid-responsive `}>
                    <div>
                        <SelectField
                            id='affiliations'
                            label={'Club Affiliations'}
                            placeholder={'Select...'}
                            value={data?.affiliations}
                            handleChange={changeEventHandler}
                            data={[{value: 'yes', text: 'Yes'}, {value: 'no', text: 'No'}, {value: 'unsure', text: 'Unsure if relevant'}]}
                        />
                    </div>
                    <div>
                        
                    </div>
                </div>

                {['yes', 'unsure'].indexOf(data?.affiliations) > -1 && (
                    <FormInputField
                        id='affiliation_detail'
                        type='textarea'
                        labelName='Affiliation involvement details'
                        disabled={true}
                        value={data?.affiliation_detail}
                        handleChange={changeEventHandler}
                    />
                )}

                <FormInputField
                    id='similar_club_details'
                    type='textarea'
                    labelName='Existing club research'
                    disabled={true}
                    value={data?.similar_club_details}
                    handleChange={changeEventHandler}
                />

                {data?.content && ( // Ensure data is loaded before rendering textfield
                  <FormInputField
                      id='content'
                      type='richtext'
                      labelName='Public blurb'
                      value={data?.content}
                      handleChange={returnBody}
                  />
                )}

                <div>Constitution: <a href={data?.constitution_document} target="_blank" rel="noreferrer noopener">View</a></div>
            </div>
        </AccordionCard>

        <AccordionCard title={'Contact'}>
            <div className={styles.root}>
                
                <FormInputField
                    id='contact'
                    type='input'
                    labelName='Contact Name'
                    value={data?.contact}
                    handleChange={changeEventHandler}
                />

                <div className={`grid grid-50 grid-responsive `}>
                    <div>
                        <FormInputField
                            id='mobile'
                            type='text'
                            labelName='Contact Mobile'
                            value={data?.mobile}
                            handleChange={changeEventHandler}
                        />
                    </div>
                    <div>
                        <FormInputField
                            id='email'
                            type='email'
                            labelName='Contact Email'
                            value={data?.email}
                            handleChange={changeEventHandler}
                        />
                    </div>
                </div>
            </div>
        </AccordionCard>

        <AccordionCard title={'Signature settings'}>
            <div className={styles.root}>
                <DateTimeInput
                    id='campaign_start_date'
                    type='datetime-local'
                    label='Campaign start date/time for signatures'
                    placeholder='Start date/time'
                    value={data?.campaign_start_date}
                    handleChange={changeEventHandler}
                    ref={startDateRef}
                />

                <div className={`grid grid-50 grid-responsive `}>
                    <div>
                        <FormInputField
                            id='accepting_signatures_period'
                            type='input'
                            labelName='Campaign length (in days)'
                            value={data?.accepting_signatures_period}
                            handleChange={changeEventHandler}
                        />
                    </div>
                    <div>
                        <FormInputField
                            id='minimum_amount_of_signatures_required'
                            type='text'
                            labelName='Signatures required'
                            value={data?.minimum_amount_of_signatures_required}
                            handleChange={changeEventHandler}
                        />
                    </div>
                </div>
            </div>
        </AccordionCard>
      
      {forApproval ? (
        <div className={`grid grid-50 grid-responsive `}>
          {data.status === 'applied' && (
            <>
              <Button
                disabled={saving}
                type={'button'}
                onClick={() => approveChangesHandler(true)}
                level={'secondary'}>
                Reject
              </Button>
              <Button
                disabled={saving}
                type={'button'}
                onClick={() => approveChangesHandler(false)}
                level={'primary'}>
                Approve
              </Button>
            </>
          )}

          {data.status !== 'applied' && (
            <>
              <Button
                disabled={saving}
                type={'button'}
                onClick={() => approveChangesHandler(false)}
                level={'primary'}>
                Update
              </Button>
            </>
          )}
        </div>
      ) : (
        <></>
        // <Button
        //   disabled={saving}
        //   type={'button'}
        //   onClick={submitClubHandler}
        //   level={'primary'}>
        //   Submit changes
        // </Button>
      )}
    </>
  );
};

export default ClubApplication;

