import React, { useContext, useMemo, useState } from 'react';
import { navigate } from "gatsby";
import { get } from 'lodash';
import { toast } from 'react-toastify';
import AccountPageWrapper from '../../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import AuthContext from '../../../../context/AuthProvider';
import { wpApi, eventCheckIn } from '../../../../helpers/wordpress';
import { decodeQRData, persistLocation } from '../../../../helpers/general';
import { getPerson } from '../../../../helpers/ortto';

import * as styles from '../usu.module.css';

const CheckIn = ({ location }) => {
    const auth = useContext(AuthContext);
    const memberNumber = auth && get(auth, 'state.usu.MemberNumber');
    const [checkIn, setCheckIn] = useState(null);

    const relocate = () => {
        navigate('/account/my-usu/clubs/');
    }

    useMemo(() => {
        const checkInMember = async () => {
            // console.log("Location:", location);
            // console.log("Window Location:", window.location);
            // console.log("MemberNumber:", memberNumber);
            // console.log("Auth:", auth.state);
            if (typeof window !== 'undefined' && window.location.search.startsWith("?c=")) {
                const hash = window.location.search.replace('?c=', '');
                const jsonData = decodeQRData(hash);
                let obj = false;

                try {
                    obj = JSON.parse(jsonData);
                } catch {
                    toast.error("Data error");
                    setCheckIn(false);
                    relocate();
                    return;
                }

                // console.log("Scanned data:", obj);
                const event = await wpApi(`events/${obj.eventId}`);
                const existingCheckIn = await wpApi(`event_checkins?event=${obj.eventId}&member_number=${memberNumber}`);
                // console.log("Event:", event);
                // console.log("Event Checkin:", existingCheckIn);

                if (
                    (String(event.status).startsWith('2') && event.response) &&
                    (String(existingCheckIn.status).startsWith('2') && Array.isArray(existingCheckIn.response))
                ) {
                    const slug = event.response.slug;
                    const guest = await getPerson(
                        ['first', 'last', 'email', 'MemberNumber', 'tags'],
                        memberNumber,
                        'str:cm:memberNumber'
                    );
                    // console.log('Profile:', guest);
                    if (String(guest.status).startsWith('2') && Array.isArray(get(guest, 'response.contacts', false))) {
                        const profile = guest.response.contacts[0].fields;
                        const profileTags = profile.tags;
                        const isRSVPd = profileTags.find(tag => tag === `event-${slug}`);
                        if (isRSVPd) {
                            const checkedIn = existingCheckIn.response.length > 0 || false;

                            if (!checkedIn) {
                                eventCheckIn({memberNumber: memberNumber, eventId: obj.eventId, type: 'attendee'}).then(result => {
                                    if (String(result.status).startsWith('2') && result.response) {
                                        setCheckIn(true);
                                        toast.success("Checked in!");
                                        relocate();
                                    } else {
                                        toast.error("An error occurred while attempting to check you in. Please try again.");
                                        setCheckIn(false);
                                        relocate();
                                    }
                                })
                            } else {
                                // Member already checked in for this event
                                toast.error("You are already checked in");
                                setCheckIn(false);
                                relocate();
                            }
                        } else {
                            // Member doesn't have the tag for this event on their profile. Consider them a guest.
                            const checkedIn = existingCheckIn.response.length > 0 || false;

                            if (!checkedIn) {
                                eventCheckIn({memberNumber: memberNumber, eventId: obj.eventId, type: 'guest'}).then(result => {
                                    if (String(result.status).startsWith('2') && result.response) {
                                        setCheckIn(true);
                                        toast.warn("You are not on the list for this event and have been checked in as a guest");
                                        relocate();
                                    } else {
                                        toast.error("An error occurred while attempting to check you in. Please try again.");
                                        setCheckIn(false);
                                        relocate();
                                    }
                                })
                            } else {
                                // Member already checked in for this event
                                toast.error("You are already checked in");
                                setCheckIn(false);
                                relocate();
                            }
                        }
                    } else {
                        // Failed to fetch profile from Ortto
                        toast.error("Failed to check the RSVP");
                        setCheckIn(false);
                        relocate();
                    }
                } else {
                    // Event fetch failed
                    toast.error("Couldn't find event to check in to");
                    setCheckIn(false);
                    relocate();
                }
            } else {
                // location.search.startsWith failed
                toast.error("Invalid check in");
                setCheckIn(false);
                relocate();
            }
        }

        if (checkIn === null) {
            checkInMember();
        }
    }, [location, memberNumber, checkIn]);

    return (
        <>
        {(checkIn) ? (
            <>
                Redirecting...
            </>
        ) : (
            <div className={styles.root}>
                {!checkIn ? (
                    <div>Checking you in to the event - please wait...</div>
                ) : (
                    <div>Redirecting...</div>
                )}
            </div>
        )}
        </>
    );
};

const ClubManagementOutput = ({ location }) => {
    const persistedLocation = persistLocation(location);
    return (
        <AccountPageWrapper
            metaTitle={`Account - Checking in`}
            bgRaw
            title="Checking in"
            breadcrumbTitle="Checking in"
            breadcrumbs={[{ link: '/account/my-usu/clubs/', label: 'My USU' }]}
            location={persistedLocation}>
            <CheckIn location={persistedLocation} />
        </AccountPageWrapper>
    )
};

export default ClubManagementOutput;

