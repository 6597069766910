import { Link, navigate } from "gatsby";
import { get } from "lodash";
import { toast } from 'react-toastify';
// import parse from 'html-react-parser';
import React, { useContext, useMemo, useState } from "react";
import Button from "../../../../../components/atoms/Button/Button";
import FormInputField from "../../../../../components/atoms/FormInputField/FormInputField";
import Icon from "../../../../../components/atoms/Icon/Icon";
import AccountPageWrapper from "../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper";
import AuthContext from "../../../../../context/AuthProvider";
import { persistLocation } from "../../../../../helpers/general";
import { authClubExec, wpApi } from "../../../../../helpers/wordpress";
import * as styles from '../../usu.module.css';

const ClubRoles = ({location}) => {
    const auth = useContext(AuthContext);
    const memberNumber = auth && get(auth, 'state.usu.MemberNumber');
    const [isAuthed, setAuthed] = useState(null);
    const [roles, setRoles] = useState(null);
    const [disableActions, setDisable] = useState(false);
    const [club, setClub] = useState();
    const requiredRoles = useMemo(() => {
        return [
        {
            role: "President",
            amount: 1
        },
        {
            role: "Secretary",
            amount: 1
        },
        {
            role: "Treasurer",
            amount: 1
        }
    ]}, []);

    useMemo(() => {
        if (isAuthed === null || (isAuthed && club && club !== get(location, 'state.clubId'))) {
            setRoles(null);
            authClubExec(memberNumber, get(location, 'state.clubId')).then(authed => {
                if (String(authed.status).startsWith("2") && authed.response) {
                    setAuthed(true);
                    const clubRoles = get(authed, 'response.acf.club_roles', null);
                    setClub(get(location, 'state.clubId'))
                    setRoles(clubRoles || requiredRoles);
                } else {
                    setAuthed(false);
                }
            });
        }
    }, [isAuthed, memberNumber, requiredRoles, location , club]);

    const updateRoles = (value, field, index) => {
        const tempRoles = [...roles];
        tempRoles[index][field] = value;
        setRoles(tempRoles);
    }

    const removeRole = (index) => {
        const tempRoles = [...roles];
        tempRoles.splice(index, 1);
        setRoles(tempRoles);
    }

    const addRole = () => {
        const tempRoles = [...roles];
        tempRoles.push({
            role: '',
            amount: 1
        });
        setRoles(tempRoles);
    }

    const saveRoles = () => {
        setDisable(true);
        return wpApi(`clubs/${get(location, 'state.clubId')}`, 'POST', {
            fields: {
                club_roles: roles
            },
        }).then(response => {
            // console.log(response);
            setDisable(false);
            if (String(response.status).startsWith("2") && 'id' in response.response) {
                toast.success("Roles saved");
            } else {
                toast.error("Unable to save. Please try again.");
            }

            return true;
        })
    }

    return (
        <div className={`${styles.root}`}>
            <p>Define the roles that will be required to be filled in the IGM vote. There are required positions that have been preset for you. You are free to add any additional as required.</p>
            <p>At the IGM, attendees will be able to log into their accounts and vote in other attendees to these positions.</p>
            {(roles) ? (
                <div className={styles.roles}>
                    {roles.map((role, roleIndex) => {
                        const disabled = typeof requiredRoles.find(r => role.role === r.role) !== 'undefined';
                        return (
                            <div key={roleIndex} className={styles.role}>
                                <div className={styles.name}>
                                    <FormInputField labelName="Role:" id="role" value={role.role} customHandleChange={(e) => updateRoles(e.target.value, 'role', roleIndex)} disabled={disabled} />
                                </div>
                                <div className={styles.amount}>
                                    <FormInputField labelName="Amount:" id="amount" value={role.amount} customHandleChange={(e) => updateRoles(Number(e.target.value), 'amount', roleIndex)} disabled={disabled} />
                                </div>
                                {!disabled && (<div role="presentation" className={styles.close} onClick={() => removeRole(roleIndex)}><Icon symbol="close" /></div>)}
                            </div>
                        )
                    })}
                    <div className="buttonGroup stretch">
                        <Button level="secondary" type="button" disabled={disableActions} onClick={() => addRole()}>Add new role</Button>
                        <Button level="primary" type="button" disabled={disableActions} onClick={() => saveRoles()}>Save</Button>
                    </div>
                </div>
            ) : (
                <>
                    {/* Fetching clubs */}
                    {((isAuthed === null && roles === null) || (isAuthed === true && roles === null)) && (
                        <div>Fetching your club roles</div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === true && roles === false) && (
                        <div>An error occurred. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                    {/* No clubs found */}
                    {(isAuthed === false) && (
                        <div>You are not authorised to manage this club. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
                    )}
                </>
            )}
        </div>
    )
}

const ClubManagementRoles = ({location}) => {
    const persistedLocation = persistLocation(location);
    
    const backToManagementPage = () =>{
        navigate(`/account/my-usu/clubs/manage`,{
            state: persistedLocation.state
        })
    }

    return (
        <AccountPageWrapper metaTitle="Account - Club Management" title={`Define club roles`} breadcrumbTitle="Club Roles" breadcrumbs={[{ link: '/account/my-usu/clubs/', label: 'My USU' }, { onClick: () => backToManagementPage(), label: get(persistedLocation, 'state.clubTitle', '')}]} menuType="clubManage" location={persistedLocation}>
            <ClubRoles location={persistedLocation} />
        </AccountPageWrapper>

    )
}

export default ClubManagementRoles