import { chunk, orderBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import { decode } from 'he';
import { extractDate } from '../../../helpers/general';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';

import { CSVLink } from 'react-csv';

import Checkbox from '../../atoms/Checkbox/Checkbox';
import UseOutsideClick from '../../atoms/UseOutsideClick/UseOutsideClick';
import Dialog from '../../atoms/Dialog/Dialog';
import ConfirmDialog from '../../atoms/ConfirmDialog/ConfirmDialog';
import * as styles from './DataTable.module.css';
import { useCallback } from 'react';

const DataTable = ({ 
  tableData, 
  exportData,
  headingKeys, 
  rowActions = null, 
  rowActionsFlag = false,
  topActions = null,
  bulkActions = null,
  resetAfterBulk = true,
  handleBulkAction,
  perPage = 30,
  showSearch = false,
  searchLabel = 'Search...',
  searchableKey = null,
  allowExport = false,
  fileName = 'members',
  emptyMessage = 'No records found...',
  defaultSortKey = null,
  defaultSortType = 'asc',
  classes = [],
  noFirstAction = false
}) => {
  const [sortKey, setSortKey] = useState(defaultSortKey);
  const [sortType, setSortType] = useState(defaultSortType);
  const [_tableData, setTableData] = useState(null);
  const [_headingKeys, setHeadingKeys] = useState(null);
  const [bulkSelected, setBulkSelected] = useState([]);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [searchKey, setSearchKey] = useState(null);
  const [displayData, setDisplayData] = useState([]);
  const [chunkedData, setChunkedData] = useState([]);
  const [toggleFilters, setToggleFilters] = useState(false);

  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [imagePreview, setImagePreview] = useState(false);

  const selectRef = useRef();
  const filterRef = useRef();

  UseOutsideClick(filterRef, () => setToggleFilters(false), 'mousedown');

  const generatePagination = useCallback(data => {
    let chunked = chunk(data, perPage);
    let totalPage = chunked.length;

    setTotalPage(totalPage);
    setChunkedData(chunked);
  }, [perPage]);

  const sortColumns = useCallback((key = null, data = null) => {
    let newSortType = sortType;
    if (key) {
      newSortType = sortType === 'asc' ? 'desc' : 'asc';
    }

    if (!key && headingKeys) {
      let firstHeadings = headingKeys.filter(keys => {
        return keys.sortable;
      })[0];
      key = firstHeadings ? firstHeadings.data_key : null;
    }

    let sortedData = searchKey ? chunkedData.flat() : data || displayData;
    if (key) {
      sortedData = orderBy(sortedData, [key], [newSortType]);

      setSortKey(key);
      setSortType(newSortType);
    }

    if (!searchKey) {
      setDisplayData(sortedData);
    }
    generatePagination(sortedData);
    return sortedData;
  }, [chunkedData, displayData, generatePagination, headingKeys, searchKey, sortType]);

  useEffect(() => {
    if ((tableData && headingKeys && (JSON.stringify(tableData) !== _tableData || JSON.stringify(headingKeys) !== _headingKeys))) {
      setTableData(JSON.stringify(tableData));
      setHeadingKeys(JSON.stringify(headingKeys));
      const bodyData = [];
      tableData.forEach(data => {
        let thisData = [];
        headingKeys.forEach(head => {
          thisData[head.data_key] =
            head.data_key in data ? data[head.data_key] : null;
        });
        bodyData.push({ ...thisData, ...data });
      });

      // console.log("sort columns", bodyData);
      sortColumns(defaultSortKey, bodyData);
    }
  }, [tableData, headingKeys, _tableData, _headingKeys, defaultSortKey, sortColumns]);

  const fetchFilterOptions = key => {
    const options = [];
    if (tableData) {
      tableData.map(row => {
        const _key = row[key];
        if (
          typeof _key === 'string' &&
          _key !== '' &&
          options.indexOf(_key) === -1
        ) {
          options.push(_key);
        } else if (typeof _key === 'boolean') {
          if (key !== '' && options.length === 0) {
            options.push('True');
            options.push('False');
          }
        } else if (
          typeof _key === 'object' &&
          _key !== null &&
          Array.isArray(_key)
        ) {
          // console.log(_key);
          _key.map(k => {
            if (k !== '' && options.indexOf(k) === -1) {
              options.push(k);
            }
            return true;
          });
        } else if (
          typeof _key === 'object' &&
          _key !== null &&
          'props' in _key
        ) {
          const k = _key.props.children;
          if (k !== '' && options.indexOf(k) === -1) {
            options.push(k);
          }
        }
        return true;
      });
    }
    return options;
  };

  const handleFilterChange = (event, key) => {
    let selected = event.target.value;
    const toFilter = searchKey ? chunkedData.flat() : tableData;
    setCurrentPage(0);
    if (selected) {
      if (selected === 'True') selected = true;
      if (selected === 'False') selected = null;
      const filteredData = toFilter.filter(record => record[key] === selected);
      setDisplayData(filteredData);
      generatePagination(filteredData);
      return filteredData;
    } else {
      setDisplayData(toFilter);
      generatePagination(toFilter);
      return toFilter;
    }
  };

  const handlePageChanged = page => {
    let dataPage = page - 1;
    setCurrentPage(dataPage);
  };

  const generatePageControl = () => {
    let element = [];
    for (let i = 1; i <= totalPage; i++) {
      element.push(
        <li key={i}>
          <span
            role='button'
            tabIndex='0'
            onClick={() => handlePageChanged(i)}
            onKeyDown={() => {}}
            className={currentPage + 1 === i ? styles.active : ''}>
            {i}
          </span>
        </li>
      );
    }

    return element;
  };

  const handleBulkActionChange = event => {
    handleBulkAction(event.target.value, [...bulkSelected]);

    // reset
    if (resetAfterBulk === true) {
      setBulkSelected([]);
      selectRef.current.value = '';
    }
  };

  const handleCheckAll = allData => {
    if (bulkSelected.length > 0 && bulkSelected.length === allData.length) {
      // All are checked, so clear the list
      setBulkSelected([]);
    } else {
      // Add all to selected
      setBulkSelected(allData);
    }
  }

  const handleBulkSelect = data => {
    let newItems = [];
    if (bulkSelected.length > 0) {
      let exists = false;
      bulkSelected.map(selected => {
        if (selected.id !== data.id) {
          newItems.push(selected);
        } else {
          exists = true;
        }

        return selected;
      });

      if (!exists) {
        newItems.push(data);
      }
    } else {
      newItems.push(data);
    }

    setBulkSelected(newItems);
  };

  const handleSearch = event => {
    let key = event.target.value;

    setCurrentPage(0);
    if (key.length > 0) {
      if (searchTimeout) clearTimeout(searchTimeout);

      setSearchTimeout(
        setTimeout(() => {
          setSearchKey(key.toLowerCase());
          doSearch(key.toLowerCase());
        }, 500)
      );
    } else {
      generatePagination(displayData);
    }
  };

  const doSearch = s => {
    const filter = displayData.filter(data => {
      let found = false;

      if (searchableKey) {
        searchableKey.map(key => {
          if (
            key in data &&
            String(data[key])
              .toLowerCase()
              .indexOf(s) > -1
          ) {
            found = true;
          }

          return key;
        });
      } else {
        headingKeys.map(key => {
          if (
            key.data_key in data &&
            String(data[key.data_key])
              .toLowerCase()
              .indexOf(s) > -1
          ) {
            found = true;
          }

          return key;
        });
      }

      return found ? data : false;
    });

    generatePagination(filter);
  };

  // TODO: Use as util
  const moneyFormat = value => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return value > 0 ? formatter.format(value) : 'N/A';
  };

  const dateFormat = dateString => {
    if (String(Number(dateString)).length === 8) {
      const _s = String(dateString);
      const dateYear = _s.substring(0, 4);
      const dateMonth = _s.substring(4, 6);
      const dateDate = _s.substring(6, 8);

      const _d = new Date();
      _d.setFullYear(dateYear);
      _d.setMonth(Number(dateMonth) - 1);
      _d.setDate(dateDate);

      return extractDate(_d);
    } else if (dateString !== '' && typeof dateString !== 'undefined') {
      return extractDate(dateString);
    }
  };

  const showHoverDetail = (e, open) => {
    const t = e.target.nextSibling;
    if (open) {
      // Show
      t.classList.add(styles.display);
    } else {
      // Hide
      t.classList.remove(styles.display);
    }
  };

  const classFormat = value => {
    if (typeof value === 'string') {
      return encodeURIComponent(
        value.toLowerCase().replace(/[^a-z0-9 _-]+/gi, '-')
      );
    }

    return value;
  };

  const hideRowAction = (data) => {
    return rowActionsFlag && ('editable' in data && !data.editable) ? true : false;
  }

  const RowAction = ({ children, rowActions, data, index }) => {
    if (noFirstAction) {
      return children;
    }
    
    if (rowActions && rowActions.length > 0) {
      if ('cta' in rowActions[0]) {
        return (
          <Button
            className={styles.silent}
            type='button'
            onClick={() => {
              rowActions[0].cta(data, index);
            }}>
            {children}
          </Button>
        );
      } else {
        return (
          <Button className={styles.silent} type='a' href={rowActions[0].href}>
            {children}
          </Button>
        );
      }
    }

    return children;
  };

  return (
    <div className={`${styles.root} ${classes.map(c => styles[c]).join(' ')}`}>
      <div className={styles.headControls}>
        {bulkActions && (
          <div className={styles.leftControls}>
            {/* TO DO: should be based from props */}
            {bulkActions.length > 0 && (
              <div className={styles.bulkActions}>
                <select ref={selectRef} onChange={handleBulkActionChange}>
                  <option value={''}>Action</option>
                  {bulkActions.map((action, y) => (
                    <option key={y} value={action.key}>
                      {action.label}
                    </option>
                  ))}
                </select>
                {bulkSelected.length > 0 && (
                  <span className={styles.selectedLabel}>
                    ({bulkSelected.length} selected)
                  </span>
                )}
              </div>
            )}
            <div className={styles.filters}>
              {showSearch && (
                <div className={styles.search}>
                  <input
                    type='text'
                    placeholder={searchLabel}
                    onChange={handleSearch}
                  />
                  <Icon symbol='search' />
                </div>
              )}
              {headingKeys.filter(key => key.filterable).length > 0 && (
                <div className={styles.filtersWrap}>
                  <span
                    role='presentation'
                    onClick={() => setToggleFilters(!toggleFilters)}>
                    Filters <Icon symbol='filter' />
                  </span>
                  <div
                    className={`${styles.filterItems}${
                      toggleFilters ? ` ${styles.active}` : ''
                    }`}
                    ref={filterRef}>
                    {headingKeys
                      .filter(key => key.filterable)
                      .map(filterColumn => {
                        return (
                          <div
                            key={`${filterColumn.data_key}_filter`}
                            className={styles.filter}>
                            <label>Filter by {filterColumn.label}</label>
                            <select
                              onChange={e =>
                                handleFilterChange(e, filterColumn.data_key)
                              }>
                              <option value=''>All</option>
                              {fetchFilterOptions(filterColumn.data_key).map(
                                (option, k) => (
                                  <option key={k} value={option}>
                                    {option}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className={styles.mainRightControlContainer}>
          {topActions && (
            <div className={styles.rightControls}>
              {/* TO DO: should be based from props */}
              {topActions.map((action, y) => (
                <Button
                  key={y}
                  type='button'
                  onClick={() => {
                    action.event({});
                  }}>
                  {'icon' in action ? action.icon : ''}
                  {action.label}
                </Button>
              ))}
            </div>
          )}
          {allowExport && (tableData || exportData) && (
            <div className={styles.exportContainer}>
              <CSVLink data={exportData || tableData} filename={fileName}>
                <Icon symbol={'export'}></Icon>
                <span>Export List</span>
              </CSVLink>
            </div>
          )}
        </div>
      </div>

      <div className={styles.mainTable}>
        <table className={styles.tableView}>
          <thead>
            <tr>
              {bulkActions && 
                <th className={styles.tableViewBulk}>
                  {chunkedData[currentPage] && (
                    <Checkbox
                      action={() => handleCheckAll(chunkedData[currentPage])}
                      isChecked={
                        bulkSelected &&
                        bulkSelected.length === chunkedData[currentPage].length
                      }
                    />
                  )}
                </th>}
              {headingKeys &&
                headingKeys.map((head, index) => (
                  <th
                    key={index}
                    className={`${head.hidden ? styles.hidden : ''} ${
                      'sortable' in head &&
                      head.sortable &&
                      chunkedData[currentPage] &&
                      chunkedData[currentPage].length > 1
                        ? styles.withSort
                        : ''
                    } ${('hideMobile' in head && head.hideMobile) ? styles.hideMobile : ''}`}>
                    {!head.hidden && (
                      <>
                        {head.label}
                        {'sortable' in head &&
                          head.sortable &&
                          chunkedData[currentPage] &&
                          chunkedData[currentPage].length > 1 && (
                            <div
                              className={`
                          ${styles.sortIcons} 
                          ${
                            sortKey === head.data_key && sortType === 'asc'
                              ? styles.asc
                              : sortKey === head.data_key && sortType === 'desc'
                              ? styles.desc
                              : ''
                          } 
                          ${sortKey === head.data_key ? styles.active : ''}`}
                              onClick={() => sortColumns(head.data_key)}
                              onKeyDown={() => {}}
                              tabIndex={index}
                              role='button'>
                              <Icon symbol='caret' />
                              <Icon symbol='caret' />
                            </div>
                          )}
                      </>
                    )}
                  </th>
                ))}
              {rowActions && rowActions.length > 0 && (
                <th className={styles.tableViewActions}>&nbsp;</th>
              )}
            </tr>
          </thead>
          <tbody>
            {chunkedData.length > 0 &&
              chunkedData[currentPage].map((data, index) => (
                <tr key={index}>
                  {bulkActions && (
                    <td>
                      <Checkbox
                        action={() => handleBulkSelect(data)}
                        isChecked={
                          bulkSelected &&
                          bulkSelected.filter(
                            selected => data.id === selected.id
                          ).length > 0
                        }
                      />
                      {/* <span role="button" 
                      tabIndex={index} 
                      className={`${styles.bulkCheckbox} ${bulkSelected && bulkSelected.filter((selected) => data.id === selected.id).length > 0 ? styles.checked : ''}`} 
                      onClick={() => handleBulkSelect(data)} 
                      onKeyDown={() => {}}> </span> */}
                    </td>
                  )}
                  {headingKeys &&
                    headingKeys.map((head, x) => (
                      !head.hidden && <td key={x} className={`${('hideMobile' in head && head.hideMobile) ? styles.hideMobile : ''}`}>
                        {typeof data[head.data_key] === 'boolean' ? (
                          <>
                            {data[head.data_key] ? (
                              <div
                                className={`${styles.boolean} ${styles.tick}`}>
                                <Icon symbol='tick' />
                              </div>
                            ) : (
                              <div
                                className={`${styles.boolean} ${styles.cross}`}>
                                {/*<Icon symbol="cross" />*/}
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {head.format === 'date' && (
                              <RowAction rowActions={rowActions} data={data} index={index}>
                                {dateFormat(data[head.data_key])}
                              </RowAction>
                            )}

                            {head.format === 'dateTime' && (
                              <RowAction rowActions={rowActions} data={data} index={index}>
                                {extractDate(data[head.data_key], true)}
                              </RowAction>
                            )}

                            {head.format === 'currency' && (
                              <RowAction rowActions={rowActions} data={data} index={index}>
                                {moneyFormat(data[head.data_key])}
                              </RowAction>
                            )}

                            {head.format === 'html' && (
                              <RowAction rowActions={rowActions} data={data} index={index}>
                                {parse(data[head.data_key])}
                              </RowAction>
                            )}

                            {head.format === 'image' && (
                              <RowAction rowActions={rowActions} data={data} index={index}>
                                <div onClick={() => setImagePreview(data[head.data_key])/*window.open(data[head.data_key])*/}
                                  onKeyDown={() => setImagePreview(data[head.data_key])/*window.open(data[head.data_key])*/}
                                  role="presentation"
                                  >
                                  <img
                                    width={50}
                                    height={50}
                                    alt='Action'
                                    style={{ cursor: 'pointer' }}
                                    src={data[head.data_key]}
                                  />
                                </div>
                              </RowAction>
                            )}

                            {head.format === 'hoverDetail' && (
                              <>
                                {data[head.data_key].length > 0 && (
                                  <div className={styles.hoverWrap}>
                                    <span
                                      role='presentation'
                                      onMouseEnter={e =>
                                        showHoverDetail(e, true)
                                      }
                                      onMouseLeave={e =>
                                        showHoverDetail(e, false)
                                      }>
                                      {data[head.data_key].length}
                                    </span>
                                    <div className={styles.hoverDetail}>
                                      {data[head.data_key].join(', ')}
                                    </div>
                                  </div>
                                )}
                              </>
                            )}

                            {[
                              'date',
                              'dateTime',
                              'currency',
                              'html',
                              'hoverDetail',
                              'image',
                            ].indexOf(head.format) === -1 && (
                              <RowAction rowActions={rowActions} data={data} index={index}>
                                <span
                                  className={
                                    ['status'].indexOf(head.data_key) > -1
                                      ? styles[
                                          `${classFormat(data[head.data_key])}`
                                        ]
                                      : ''
                                  }>
                                  {typeof data[head.data_key] === 'string'
                                    ? decode(data[head.data_key])
                                    : data[head.data_key]}
                                </span>
                              </RowAction>
                            )}
                          </>
                        )}
                      </td>
                    ))}
                  {!hideRowAction(data) && rowActions && rowActions.length > 0 && (
                    <td>
                      <div className={styles.actionEllipsis}>
                        <div className={styles.optionsPop}>
                          {rowActions.map((action, y) =>
                            'cta' in action ? (
                              ('withWarning' in action && action.withWarning) ? (
                                <ConfirmDialog
                                  key={y}
                                  message={'warningMessage' in action ? action.warningMessage : 'Do you wish to proceed?'}
                                  btnOk="Yes"
                                  btnCancel="No"
                                  onOk={() => action.cta(data, y)}
                                >
                                  <Button 
                                    key={y} 
                                    type='button'>
                                    {/* To-do href should be get from data display, per item */}
                                      {action.label}
                                  </Button>
                                </ConfirmDialog>
                              ) : (
                                <Button 
                                  key={y} 
                                  type='button' 
                                  onClick={() => {
                                    // console.log(y);
                                    action.cta(data, y)
                                  }}>
                                  {/* To-do href should be get from data display, per item */}
                                    {action.label}
                                </Button>
                              )
                            ) : (
                              <Button key={y} type='a' href={action.href}>
                                {/* To-do href should be get from data display, per item */}
                                {action.label}
                              </Button>
                            )
                          )}
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
        <Dialog open={imagePreview} hideBtnOk={true} hideBtnCancel={true} onCancel={() => setImagePreview(false)}>
          {imagePreview && (<img src={imagePreview} />)}
        </Dialog>
        {chunkedData.length < 1 && (
          <p className={`lowerFont px-4`}>{emptyMessage}</p>
        )}
      </div>
      {chunkedData && chunkedData.length > 1 && (
        <div className={styles.footerControls}>
          <div className={styles.pagination}>
            <button
              className={`${styles.pageControl} ${styles.pagePrev} ${
                currentPage === 0 ? styles.hide : ''
              }`}
              onClick={() => handlePageChanged(currentPage)}
              type='button'
              onKeyDown={() => {}}>
              <Icon symbol='arrowLeft' />
            </button>
            <ul>{generatePageControl()}</ul>
            <button
              className={`${styles.pageControl} ${styles.pageNext} ${
                currentPage + 1 === totalPage ? styles.hide : ''
              }`}
              onClick={() => handlePageChanged(currentPage + 2)}
              type='button'
              onKeyDown={() => {}}>
              <Icon symbol='arrowRight' />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DataTable;

