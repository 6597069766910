import React, { useState, useContext, useEffect} from 'react'
import { useStaticQuery, graphql } from 'gatsby';

import AuthContext from '../../context/AuthProvider';
import AccountPageWrapper from "../../components/organisms/AccountPageWrapper/AccountPageWrapper"
// import Button from '../../components/atoms/Button/Button';
import DataTable from '../../components/organisms/DataTable/DataTable';
import Dialog from '../../components/atoms/Dialog/Dialog'
import DropDown from '../../components/atoms/Dropdown/Dropdown';
import Icon from '../../components/atoms/Icon/Icon';

import { createCertificate, uploadMedia, wpApi, mapWordpressStatus } from '../../helpers/wordpress';
import { getBase64 } from '../../helpers/general';

import * as styles from './certificates.module.css';
import Loader from '../../components/atoms/Loader/Loader';
import { success } from '../../helpers/toast';

const CertificatesAccountPage = () => {

    const [showDialog, setShowDialog] = useState(false);
    const [showPrompt, setShowPrompt] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
	const [metaFileName, setMetaFileName] = useState(false);
    // const [certificateName, setCertificateName] = useState('');
    const [certificateList, setCertificateList] = useState();
    const [type, setType] = useState();
    const [loading, setLoading] = useState(false);
    const [typeList, setTypeList] = useState([]);

    const [certFocus, setCertFocus] = useState([]);

    const auth = useContext(AuthContext);
    const usu_member_number = (auth?.state?.usu?.MemberNumber) || false;
    const usu_details = (auth && auth.state?.usu) || undefined;

    const certTypeList = useStaticQuery(graphql`
    query {
        certificationType: allWpCertificationType {
                nodes {
                    name
                    id
                    databaseId
                }
            }
        }
    `)

    const mapCertType = (cTypeList, id) => {
        const typeIndex = cTypeList.findIndex((t) => t.value === parseInt(id));
        return cTypeList[typeIndex].label;
    }

    useEffect(() => {
        if(certTypeList) {
            const cTypeList = certTypeList?.certificationType?.nodes.map((type) => ({
                label: type.name,
                value: type.databaseId,
            }))
            setTypeList(cTypeList);
            setType(cTypeList[0].value);
            getCertificationList(cTypeList);
        }
    // eslint-disable-next-line
    }, [usu_member_number])
    

    const getCertificationList = (certTypeList) => {

        if(usu_member_number === false) return;

        wpApi(`certifications?status=draft,publish,private`).then((response) => {
            if(response?.status === 200) {
                // possible enhancement: filter in wordpress already
                const filteredCerts = response?.response.filter((cert) => usu_member_number === cert?.acf.usu_member_number);
                const cl = filteredCerts.map((cert) => {
                    return({
                        id: cert.id,
                        // certificate_name: cert?.acf.name,
                        certificate_name: mapCertType(certTypeList, cert?.certification_types[0]),
                        uploaded_date: cert.date,
                        expiring_date: cert.acf.expiring_date,
                        link: cert?.acf.file,
                        file_id: cert?.acf.file_id,
                        standing: cert?.acf.standing,
                        status: mapWordpressStatus(cert?.status),
                    })
                })
                setCertificateList(cl);
            }
        });
    }

    const handleCertificate = async () => {
        const data = {};

        const certName = `${usu_details.FirstName} ${usu_details.LastName} - ${mapCertType(typeList, type)}`;
        setLoading(true);
        data.status = 'draft';
        data.title = certName; // wordpress visibility
        // data.name = certificateName; // website visibility
        data.usu_member_number = usu_member_number;
        data.standing = 'Pending';
        data.expiring_date = '';
        data.student_name = `${usu_details?.FirstName} ${usu_details.LastName}`;
        data.certification_types = [type];

        const re = /(?:\.([^.]+))?$/;
        const ext = re.exec(selectedFile.name)[1] || undefined;
        
        const base64Image = await getBase64(selectedFile);
        const responseImage = await uploadMedia(`Certificate-${certName}.${ext}`, base64Image, selectedFile.type);

        if(responseImage.status === 200) {
            data.file = responseImage.response.id; // attachment id;
            data.file_id = responseImage.response.id;
            createCertificate(data).then(() => {
                refresh();
                success('File uploaded');  
            })
        }
    }

    const refresh = () => {
        setLoading(false);
        setShowDialog(false);
        setSelectedFile(undefined);
        setMetaFileName(undefined);
        getCertificationList(typeList);
    }

    const handleUpload = (e) => {
        if(e === undefined) return;
		setSelectedFile(e.target.files[0]);
        setMetaFileName(e.target.files[0]?.name);
    }
    
    const handlePreview = (data) => {
        window.open(data.link, '_blank');
    }

    const handleDelete = () => {
        setLoading(true);

        const promiseArray = [];
        for (let index = 0; index < certFocus.length; index++) {
            const deleteCertPromise = wpApi(`certifications/${certFocus[index]?.id}`, 'DELETE');
            const deleteMediaPromise = wpApi(`media/${certFocus[index]?.file_id}?force=true`, 'DELETE');
            promiseArray.push(deleteCertPromise);
            promiseArray.push(deleteMediaPromise);
        }

        Promise.all(promiseArray).then(() => {
            refresh();
            setShowPrompt(false);
            setLoading(false);
        })
    }

    const handleBulkAction = (e, certList) => {
        if(e === 'delete') {
            setCertFocus(certList);
            setShowPrompt(true);
        }
    }

    const headingKeys = [
        { label: 'Type', data_key: 'certificate_name', sortable: true },
        // { label: 'Student Name', data_key: 'student_name', sortable: true },
        { label: 'Status', data_key: 'status', sortable: false },
        { label: 'Uploaded Date', data_key: 'uploaded_date', sortable: false, format: 'date' },
        { label: 'Expiring Date', data_key: 'expiring_date', sortable: false, format: 'date' },
    ]

    // const test = () => {
    //     wpApi('certifications?status=draft,publish&meta_key=file_id&meta_value=1886').then((response) => {
    //         console.log(response);
    //     })
    // }

    return(
    <AccountPageWrapper bgRaw={true} metaTitle="Account - Certificates" title="Manage certificates"> 
        {/* <div onClick={test}>test</div>  */}
        <div className={styles.root} style={{opacity: loading ? '0.5' : '1'}}>
            <DataTable 
                handleBulkAction={handleBulkAction}
                tableData={certificateList} 
                bulkActions={[
                    // { label: 'Archive', key: 'archive' },
                    { label: 'Delete', key: 'delete' }
                ]}                
                topActions={[
                    { label: 'Upload certificate', icon: <Icon symbol="upload" />, event: () => setShowDialog(true) }
                ]}
                
                headingKeys={headingKeys}
                rowActions={[
                    { label: 'Preview', cta: (data) => handlePreview(data) },
                    { label: 'Delete', cta: (data) => {
                        // setDeleteId(data.id);
                        setCertFocus([data]);
                        setShowPrompt(true)
                    }},
                    // { label: 'Details', href: '/dummy' }
                ]}
                perPage={5}
                showSearch={false}
                emptyMessage={'You currently have no certificates'}
            />
            {loading && <div className={styles.loaderContainer}><Loader /></div>}
            <Dialog
                onCancel={() => setShowDialog(false)}
                onOk={() => handleCertificate()}
                open={showDialog}
                size={'sm'}
                hideBtnCancel
                okBtnText={'Upload'}
                title={'Upload certificate'}
                disabled={ !selectedFile ? true : false}
            >
                <div className={styles.dialogContainer}>
                    <DropDown
                        optionList={typeList}
                        action={(e) => setType(e.target.value)}
                    />
                    {/* <div className="formField">
                        <label htmlFor="certificateName">Certificate Name</label>
                        <input 
                            type="text" 
                            id="certificateName" 
                            value={certificateName} 
                            onChange={(e) => setCertificateName(e.target.value)
                        } />
                    </div> */}
                    <div className={styles.filePickerContainer}>
                        <div className={styles.fileHelperContainer}>
                            <span className={styles.fileName}>{metaFileName ? metaFileName : 'Certificate File'}</span>
                            <span className={styles.fileHelper}>Accepted filetypes PDF, DOC, JPEG or PNG and cannot exceed 1MB.</span>
                        </div>
                        <label className={styles.filePicker} htmlFor='file' >
                            <input id="file" type="file" name="file" accept='.pdf,.doc,.docx,.jpeg,.jpg,.png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpeg,image/pjpeg,image/png' onChange={handleUpload} />
                            Select File
                        </label>
                    </div>
                </div>
            </Dialog>

            <Dialog
                onCancel={() => setShowPrompt(false)}
                open={showPrompt}
                onOk={handleDelete}
                hideBtnClose
                okBtnText='Delete'
                size={'xs'}
            >
                <div className={styles.promptContainer}>
                    <span className={styles.promptTitle}> {certFocus.length === 1 ? 'Delete certificate?' : 'Delete certificates?'}</span>
                    <span className={styles.promptMessage}>This can't be undone and it will permanently delete {certFocus.length === 1 ? 'this document' :'these documents'}.</span>
                </div>
            </Dialog>


        </div>
    </AccountPageWrapper>

    )
}

export default CertificatesAccountPage