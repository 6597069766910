import React, { useContext, useMemo, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { get } from 'lodash';
import AuthContext from '../../../../../../context/AuthProvider';
import { persistLocation } from '../../../../../../helpers/general';
import { authClubExec } from '../../../../../../helpers/wordpress';
import AccountPageWrapper from '../../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import EventSubmissionForm from '../../../../../../components/molecules/EventSubmissionForm/EventSubmissionForm';

import * as styles from './event.module.css';
const EventSubmission = ({ location }) => {
  const auth = useContext(AuthContext);
  const memberNumber = auth && get(auth, 'state.usu.MemberNumber');
  const [isAuthed, setAuthed] = useState(null);

  const igmData =
    location.state.clubStatus === 'ign'
      ? {
          event_name: `${location.state.clubTitle} Inaugural General Meeting`,
          event_description: '',
          date_from: '',
          date_to: '',
          start_time: '',
          end_time: '',
          venue: '',
          outlet_venues: '',
          faculty_library: '',
          covid_safety: '',
          indoor_outdoor: '',
          covid_marshal: '',
          phone_number: '',
          email: '',
          overnight_event: '',
          food_available: '',
          alcohol_available: '',
          charity_event: '',
          event_funding: '',
          allocate_covid_safety: false,
          isGM: true
        }
      : undefined;

  const special = () => {
    if ('special' in location.state) {
      switch (location.state.special) {
        case 'agm':
          return {
            event_name: `${location.state.clubTitle} Annual General Meeting`,
            event_description: '',
            date_from: '',
            date_to: '',
            start_time: '',
            end_time: '',
            venue: '',
            outlet_venues: '',
            faculty_library: '',
            covid_safety: '',
            indoor_outdoor: '',
            covid_marshal: '',
            phone_number: '',
            email: '',
            overnight_event: '',
            food_available: '',
            alcohol_available: '',
            charity_event: '',
            event_funding: '',
            allocate_covid_safety: false,
            isGM: true
          };
        // break;

        default:
          return undefined;
        // break;
      }
    } else {
      return undefined;
    }
  };

  useMemo(() => {
    if (isAuthed === null) {
      authClubExec(memberNumber, get(location, 'state.clubId')).then(authed => {
        if (String(authed.status).startsWith('2') && authed.response) {
          setAuthed(true);
        } else {
          setAuthed(false);
        }
      });
    }
  }, [isAuthed, memberNumber, location]);

  return (
    <>
      {isAuthed ? (
        <>
          {location.state && get(location, 'state.clubId') && (
            <EventSubmissionForm
              clubId={get(location, 'state.clubId')}
              location={location}
              customData={igmData || special()}
            />
          )}
        </>
      ) : (
        <div className={styles.root}>
          {/* Checking authorisation */}
          {isAuthed === null && <div>Setting up form...</div>}
          {/* Not authorised */}
          {isAuthed === false && (
            <div>
              You are not authorised to create an event. Return back to{' '}
              <Link to='/account/my-usu/clubs'>
                <u>list</u>
              </Link>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const ClubManagementEventSubmission = ({ location }) => {
  const persistedLocation = persistLocation(location);
  
  const backToManagementPage = () => {
    navigate(`/account/my-usu/clubs/manage`, {
      state: persistedLocation.state,
    });
  };

  let title = `Submit Event`;
  if (get(persistedLocation, 'state.clubStatus') === 'ign') title = `Create IGM Event`;
  else if (get(persistedLocation, 'state.special') === 'agm') title = `Create AGM Event`;

  return (
    <AccountPageWrapper
      metaTitle='Account - Club Management'
      title={title}
      breadcrumbTitle={title}
      breadcrumbs={[
        { link: '/account/my-usu/clubs/', label: 'My USU' },
        {
          onClick: () => backToManagementPage(),
          label: get(persistedLocation, 'state.clubTitle', ''),
        },
      ]}
      menuType='clubManage'
      location={persistedLocation}>
      <EventSubmission location={persistedLocation} />
    </AccountPageWrapper>
  );
};

export default ClubManagementEventSubmission;
