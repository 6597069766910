import { Link, navigate, graphql, useStaticQuery } from 'gatsby';
import { get } from 'lodash';
// import parse from 'html-react-parser';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import AccountPageWrapper from '../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import AuthContext from '../../../../../context/AuthProvider';
import Icon from '../../../../../components/atoms/Icon/Icon';
import { authClubExec } from '../../../../../helpers/wordpress';
import { ucFirst, extractDate, persistLocation } from '../../../../../helpers/general';
import * as styles from '../../usu.module.css';

const Documents = ({ location }) => {
  const auth = useContext(AuthContext);
  const memberNumber = auth && get(auth, 'state.usu.MemberNumber');
  const [isAuthed, setAuthed] = useState(null);
  const [allDocuments, setAllDocuments] = useState(null);
  const [documents, setDocuments] = useState(null);
  // const [foldersData, setFoldersData] = useState(null);
  const [folders, setFolders] = useState([]);
  // const [tagsData, setTagsData] = useState(null);
  const [tags, setTags] = useState([]);
  const [activeFolder, setActiveFolder] = useState(null);
  const [activeTag, setActiveTag] = useState(null);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [searchInterval, setSearchInterval] = useState(null);
  const [club, setClub] = useState();

  const { 
    allWpDocumentFolder: {nodes: documentFolders},
    allWpDocumentTag: {nodes: documentTags},
    allWpDocumentLibrary: {nodes: documentLibrary},
  } = useStaticQuery(graphql`
    query {
      allWpDocumentFolder {
        nodes {
          id: databaseId
          name
        }
      }
      allWpDocumentTag {
        nodes {
          id: databaseId
          name
        }
      }
      allWpDocumentLibrary {
        nodes {
          documentLibraryFields {
            specificClubs {
              ... on WpClub {
                id: databaseId
              }
            }
            specificGroups {
              id: databaseId
            }
            file {
              url: mediaItemUrl
            }
          }
          documentFolders {
            nodes {
              id: databaseId
            }
          }
          documentTags {
            nodes {
              id: databaseId
            }
          }
          title
          modified
        }
      }
    }
  `);

  const fetchDocuments = useCallback(async (groups) => {
    // Fetch folder information
    const _foldersData = [];
        _foldersData.push(...documentFolders.sort());

    // Fetch tag information
    const _tagsData = [];
        _tagsData.push(...documentTags.sort());

    // Fetch document data
      const documentsData = documentLibrary.map(d => {
        return {
          ...d,
          document_folders: d.documentFolders.nodes.map(f => f.id),
          document_tags: d.documentTags.nodes.map(t => t.id),
          acf: {file: d.documentLibraryFields.file.url}
        }
      })
      setDocuments(documentsData);
      setAllDocuments(documentsData);
      const _folders = [];
      const _tags = []; 

      documentsData.map(d => {
        d.document_folders.map(df => {
          const exists = _folders.find(_f => _f.id === df);
          if (!exists) {
            const _folderData = _foldersData.find(_fd => _fd.id === df);
            _folders.push(_folderData);
          }

          return true;
        })

        d.document_tags.map(dt => {
          const exists = _tags.find(_t => _t.id === dt);
          if (!exists) {
            const _tagData = _tagsData.find(_td => _td.id === dt);
            _tags.push(_tagData);
          }

          return true;
        })

        return true;
      })

      setFolders(_folders);
      setTags(_tags);
      setClub(location.state.clubId)
  }, [location, documentFolders, documentLibrary, documentTags]);

  useMemo(() => {
    if (isAuthed === null || (isAuthed && club && club !== get(location, 'state.clubId'))) {
        setDocuments(null);
        authClubExec(memberNumber, get(location, 'state.clubId')).then(authed => {
            if (String(authed.status).startsWith("2") && authed.response) {
                setAuthed(true);
                fetchDocuments(authed.response.club_groups);
            } else {
                setAuthed(false);
            }
        });
    }
  }, [isAuthed, memberNumber, location, fetchDocuments, club]);

  const displayFolders = () => {
    if (activeFolder === null && folders.length > 0 && !searchEnabled) {
      const display = folders.map((f, fI) => (
        <div key={fI} className={styles.document} role="presentation" onClick={() => setActiveFolder(f)}>
          <Icon symbol={`folder`} />
          <span className={styles.fileName}>{f.name}</span>
        </div>
      ))

      return display;
    }

    return <></>
  }

  const displayDocuments = () => {
    const display = [];
    if (activeFolder) {
      display.push(<div key="back" className={styles.document} role="presentation" onClick={() => setActiveFolder(null)}>
      <Icon symbol={`folderUp`} />
      <span className={styles.fileName}>Back</span>
    </div>)
    }
    display.push(...documents.filter(doc => {
      if (
        (searchEnabled) || 
        (!activeTag && !activeFolder && doc.document_folders.length === 0) || 
        (!activeTag && activeFolder && doc.document_folders.indexOf(activeFolder.id) > -1) ||
        (!activeFolder && doc.document_folders.length === 0 && activeTag && doc.document_tags.indexOf(activeTag.id) > -1) || 
        (activeFolder && doc.document_folders.indexOf(activeFolder.id) > -1 && activeTag && doc.document_tags.indexOf(activeTag.id) > -1)
      ) {
        return true;
      }

      return false;
    }).map((doc, dIndex) => (
      <div key={dIndex} className={styles.document} role="presentation" onClick={() => openDocument(doc.acf.file)}>
          <Icon symbol={`file${ucFirst(doc.acf.file.split('.').pop())}`} />
          <span className={styles.fileName}>{doc.title}</span>
          <span className={styles.fileDetails}>
              Updated: {extractDate(doc.modified)}
          </span>
      </div>
    )));
    return display || <></>
  }

  const toggleActiveTag = (t) => {
    if (activeTag && activeTag.id === t.id) {
      setActiveTag(null);
    } else {
      setActiveTag(t);
    }
  }

  const handleSearch = (event) => {
    let key = event.target.value;

    if (searchInterval) {
      clearInterval(searchInterval);
    }

    setSearchInterval(setTimeout(() => {
      if (key.length > 0) {
        if (searchTimeout) clearTimeout(searchTimeout);
  
        setSearchTimeout(setTimeout(() => {
          doSearch(key.toLowerCase());  
        }, 500))
      } else {
        setDocuments(allDocuments);
        setSearchEnabled(false);
      }
    }, [
      500
    ]))
    
  }

  const doSearch = (s) => {
    const filter = allDocuments.filter((data) => {
      let found = false;

      if (data.title.toLowerCase().indexOf(s) > -1) {
        found = true;
      }

      return found;
    })
    
    setSearchEnabled(true);
    setDocuments(filter);
  }

  const openDocument = (file) => {
    window.open(file, 'blank');
  }

  return (
    <>
      {documents ? (
        <div className={`${styles.root}`}>
          <div className={styles.filterBar}>
            {tags.length > 0 && (
              <div className={styles.tags}>
                <span>Tags: </span>
                {tags.map((t, tI) => (
                  <div key={tI} className={`${styles.tag}${(activeTag && activeTag.id === t.id) ? ` ${styles.active}` : ''}`} role='presentation' onClick={() => toggleActiveTag(t)}>
                    {t.name}
                  </div>
                ))}
              </div>
            )}

            <div className={styles.search}>
              <input type="text" placeholder={'Search...'} onChange={handleSearch} />
              <Icon symbol="search" />
            </div>
          </div>
          {documents.length > 0 ? (
            <div className={styles.documents}>
                {displayFolders()}
                {displayDocuments()}
            </div>
          ) : (
            <p>No documents found</p>
          )}
        </div>
      ) : (
        <div className={styles.root}>
          {/* Fetching events */}
          {((isAuthed === null && documents === null) || (isAuthed === true && documents === null)) && (
              <div>Fetching your resources</div>
          )}
          {/* No clubs found */}
          {(isAuthed === true && documents === false) && (
              <div>An error occurred. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
          )}
          {/* No clubs found */}
          {(isAuthed === false) && (
              <div>You are not authorised to manage this club. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
          )}
        </div>
      )}
    </>
  );
};

const ClubManagementDocuments = ({ location }) => {
  const persistedLocation = persistLocation(location);
  
  const backToManagementPage = () => {
    navigate(`/account/my-usu/clubs/manage`, {
      state: persistedLocation.state,
    });
  };

  return (
    <AccountPageWrapper
      metaTitle='Account - Club Management'
      title={`Resource Library`}
      breadcrumbTitle='Resource Library'
      breadcrumbs={[
        { link: '/account/my-usu/clubs/', label: 'My USU' },
        {
          onClick: () => backToManagementPage(),
          label: get(persistedLocation, 'state.clubTitle', ''),
        },
      ]}
      menuType='clubManage'
      location={persistedLocation}>
      <Documents location={persistedLocation} />
    </AccountPageWrapper>
  );
};

export default ClubManagementDocuments;
