module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-30787840-2","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"enableWebVitalsTracking":true,"exclude":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://usu.edu.au","noQueryString":true,"noHash":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://usu.wpengine.com/graphql","schema":{"typePrefix":"Wp","perPage":50,"requestConcurrency":8,"previewRequestConcurrency":3,"timeout":100000,"queryDepth":15,"circularQueryLimit":5},"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"type":{"MediaItem":{"createFileNodes":false,"localFile":{"maxFileSizeBytes":0,"requestConcurrency":1000,"excludeByMimeTypes":[]},"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"Post":{"limit":5000},"Acquittal":{"exclude":true},"Certification":{"exclude":true},"ClubTransaction":{"exclude":true},"CommentAuthor":{"exclude":true},"Communication":{"exclude":true},"ContentType":{"exclude":true},"EventCheckin":{"exclude":true},"Grant":{"exclude":true},"IgmAgmHistory":{"exclude":true},"IgmAgmNomination":{"exclude":true},"IgnVote":{"exclude":true},"Menu":{"exclude":true},"MenuItem":{"exclude":true},"PostFormat":{"exclude":true},"Receipt":{"exclude":true},"Taxonomy":{"exclude":true},"UserRole":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
