import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { get } from 'lodash';
import Container from '../components/atoms/Container/Container';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import { bcApi } from '../helpers/bigcommerce';

import * as styles from './order-confirmation.module.css';

const OrderConfirmation = ({location}) => {
    const __uoi = get(location, 'state.__uoi', false);
    const [orderDetails, setOrderDetails] = useState(null);
    const [hasMembership, setHasMembership] = useState(false);
    const [manualOrderId, setManualOrderId] = useState(100);

    useEffect(() => {
        const checkMembership = (lineItems) => {
            let result = false;
            const membershipArray = ['20183', '13610'];
            lineItems.map(item => {
                if (membershipArray.indexOf(item.sku) > -1) {
                    result = true;
                }
            });
            return result;
        }

        const fetchOrderDetails = async (orderId) => {
            const order = await bcApi(`orders/${orderId}?include=consignments.line_items`, "GET", null, 2);
            if (String(order.status).startsWith('2') && order.response) {
                const lineItems = await bcApi(order.response.products.resource.substring(1), "GET", null, 2);
                if (String(lineItems.status).startsWith('2') && lineItems.response) {
                    // console.log(order.response);
                    // console.log(lineItems.response);
                    setOrderDetails(order.response);
                    setHasMembership(checkMembership(lineItems.response));
                } else {
                    // TODO: Something went wrong
                    setOrderDetails(false);
                }
            } else {
                // TODO: Something went wrong
                setOrderDetails(false);
            }
        }

        if (__uoi && orderDetails === null) {
            const orderId = atob(__uoi);
            fetchOrderDetails(orderId);
        } else {

        }
    }, [__uoi, orderDetails]);

    // For Development purposes
    if (!__uoi) {
        return (
            <Layout>
                <Seo title="Order Confirmation" />
                <Container size={'large'}>
                    <>Set order ID: <input type="text" onChange={(e) => setManualOrderId(e.target.value)} /><button onClick={() => navigate(`/order-confirmation`, {state: {__uoi: btoa(manualOrderId)}})}>Go</button></>
                </Container>
            </Layout>
        );
    }

    // if (typeof window !== 'undefined' && !orderId) {
    //     // User shouldn't be here without an Order ID - send em home
    //     window.location = '/';
    // }

    return (
        <Layout>
            <Seo title="Order Confirmation" />
            <Container size={'large'}>
                <div className={styles.orderConfirmationContainer}>
                    {orderDetails === null && (
                        <div>Loading...</div>
                    )}
                    {orderDetails === false && (
                        <div>Something went wrong fetching the order</div>
                    )}
                    {orderDetails && (
                        <>
                            {!hasMembership && (
                                <div id="orderConfirmationContent">
                                    <h1>Your Order is Complete</h1>
                                    <p>You will be receiving a confirmation email with order details</p>
                                    <a href="https://usu.edu.au/shop/">Continue Shopping</a>
                                </div>
                            )}
                        
                            {hasMembership && (
                                <div id="orderConfirmationMembership">
                                    <h1>Thank you for being a member.</h1>
                                    <a href="https://usu.edu.au/login/">Login to your USU Portal</a>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </Container>
        </Layout>
    )
}

export default OrderConfirmation