import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, navigate, useStaticQuery, graphql } from "gatsby";
import { renderToString } from 'react-dom/server';
import { get } from "lodash"
import { toast } from 'react-toastify';
import { decode } from 'he';
import AuthContext from "../../../../../../../context/AuthProvider";
import { authClubExec, uploadMedia, submitNewGrantRequest, wpAll, createNewReceipt, createNewAcquittal } from '../../../../../../../helpers/wordpress';
import { getBase64, transformToEventDate, extractDate, getGrantValue as _getGrantValue } from '../../../../../../../helpers/general';
import { getTags, getPerson, sendEmail } from "../../../../../../../helpers/ortto";
import AccountPageWrapper from '../../../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import DataTable from '../../../../../../../components/organisms/DataTable/DataTable';
import SelectField from '../../../../../../../components/atoms/SelectField/SelectField';
import FormInputField from '../../../../../../../components/atoms/FormInputField/FormInputField';
import DateTimeInput from '../../../../../../../components/atoms/DateTimeInput/DateTimeInput';
import Button from '../../../../../../../components/atoms/Button/Button';
import OrttoEmailTemplate from '../../../../../../../components/organisms/OrttoEmailTemplate/OrttoEmailTemplate';

import * as styles from './../details.module.css';
import Icon from '../../../../../../../components/atoms/Icon/Icon';

const NewGrant = ({ location }) => {
    const auth = useContext(AuthContext);
    const memberNumber = auth && get(auth, 'state.usu.MemberNumber');
    const memberDetails = auth && get(auth, 'state');
    const hasActiveSemester = get(location, 'state.hasActiveSemester', false);
    const [isSaving, setIsSaving] = useState(false);
    const [isAuthed, setAuthed] = useState(null);
    const [club, setClub] = useState(null);
    const [events, setEvents] = useState([]);
    const [fetchingEvents, setFetchingEvents] = useState(true);
    const [tempReceipt, setTempReceipt] = useState({});
    const [openReceiptForm, setOpenReceiptForm] = useState(false);
    const [clubMembers, setClubMembers] = useState(null);
    const defaultValues = { 
        type: null, 
        typeName: '',
        funding_type: null,
        joint_club_list: '',
        value_granted: '',
        event_name: '',
        event_date: '',
        event_type: '',
        event_description: '',
        detail_differences: '',
        member_benefits: '',
        cs_value_add: '',
        spend_split: {},
        associated_event: null,
        receipts: []
    };
    const _spendSplitCategories = [
        {label: 'Catering', key: 'catering'},
        {label: 'Equipment', key: 'equipment'},
        {label: 'Admin', key: 'admin'},
        {label: 'Merchandise (e.g. T-Shirts)', key: 'merchandise'},
        {label: 'Promotional Materials (e.g. Banners)', key: 'promotional'},
        {label: 'Capital Expenses (e.g. Table)', key: 'capital'},
        {label: 'Subscription (e.g. Website Domain)', key: 'subscription'},
        {label: 'Contractors', key: 'contractors'},
    ];
    const [spendSplitCategories, setSpendSplitCategories] = useState(_spendSplitCategories);
    const [values, setValues] = useState(defaultValues);
    const [selectedFile, setSelectedFile] = useState();
    const [metaFileName, setMetaFileName] = useState(false);
    const [addSpendRow, setSpendRow] = useState(false);
    const [newSpendCategory, setSpendCategory] = useState('');
    const {
        wp: {
          acfOptionsGeneral: { clubFundTiers },
        },
        allWpGrantType: { nodes: grantTypes },
    } = useStaticQuery(graphql`
        query {
          wp {
            acfOptionsGeneral {
              clubFundTiers {
                clubFundingTiers {
                  max
                  min
                  value
                }
                newClubStarterFund
                nonStandardClubFundingTiers {
                  fieldGroupName
                  name
                  value
                }
              }
            }
          }
          allWpGrantType {
            nodes {
              name
              slug
              id: databaseId
              controls: grantDisplayControl {
                enabled
                displayWithinDates {
                  to
                  from
                }
              }
            }
          }
        }
    `);

    const dateOfPurchaseRef = useRef();
    const eventDateRef = useRef();

    const pendingEmailBody = `
        Dear ##EXEC##,<br />
        <br />
        Thank you for submitting a grant request for ##GRANTTYPE## on behalf of ##CLUBNAME##.<br />
        This is an email to inform you that your grant request has been received and is waiting to be approved by a member of the Clubs team.<br />
        <br />
        This pending grant will display on your Clubs main dashboard.<br />
        You will be notified once your grant has been approved.<br />
        <br />
        If you have any questions, please <a href="https://usu.edu.au/contact">contact the Clubs office<a/>.
    `;

    useEffect(() => {
        const fetchClubMembers = async () => {
            const tags = await getTags(`club-${location.state.clubSlug}`);
            if ((String(tags.status).startsWith('2') && Array.isArray(tags.response))) {
              const tagId = tags.response.find(tag => `club-${location.state.clubSlug}` === tag.name);
              const orttoMemberList = await getPerson(
                ['first', 'last', 'email', 'Tags', 'MemberNumber'],
                tagId.id,
                'tag_id'
              );
  
              if (
                orttoMemberList.status === 200 &&
                'contacts' in orttoMemberList.response &&
                Array.isArray(orttoMemberList.response.contacts)
              ) {
                setClubMembers(
                  orttoMemberList.response.contacts.map(member => {
                    const name = `${member.fields['str::first'] || ''} ${member
                      .fields['str::last'] || ''}`.trim();
                    return {
                      id: member.id,
                      member_name: name || '(No name set)',
                      email: member.fields['str::email'],
                    };
                  })
                );
              }
            }
        }

        if (!('state' in location) && typeof window !== 'undefined') window.location = '/account/';

        if (isAuthed === null || (isAuthed && club  && club.id !== location.state.clubId)) {
            authClubExec(memberNumber, location.state.clubId).then(authed => {
                if (String(authed.status).startsWith("2") && authed.response) {
                    setAuthed(true);
                    setClub(authed.response);
                    fetchClubMembers();
                } else {
                    setAuthed(false);
                }
            });
        }
    }, [isAuthed, memberNumber, location, club]);

    const onHandleChange = (id, value) => {
        let tempValues = Object.assign({}, values);
        tempValues[id] = value;
    
        if (id === 'type') {
          tempValues.typeName = grantTypes.find(
            grantType => Number(grantType.id) === Number(value)
          ).name;

          if (tempValues.typeName === 'Camp') {
            setFetchingEvents(true);
            wpAll(`events?status=draft,publish&club=${location.state.clubId}`).then(response => {
                if (String(response.status).startsWith("2") && response.response) {
                    setFetchingEvents(false);
                    const _events = response.response.map(evt => {
                        const dates = transformToEventDate(evt.acf.start_date, evt.acf.start_time, evt.acf.end_date, evt.acf.end_time, true);
                        return {
                            text: `${decode(evt.title.rendered)} (${dates.join(' - ')})`,
                            value: evt.id
                        }
                    });
                    setEvents(_events);
                } else {
                    setAuthed(false);
                }
            })
          }
        }

        setValues(tempValues);
    };

    const onHandleSpendSplit = (id, value) => {
        const key = id.split('_');
        let tempValues = Object.assign({}, values);
        tempValues.spend_split[key[1].replace(' ', '_')] = value;
        setValues(tempValues);
    }

    const onAddSpendRow = (id, value) => {
        setSpendCategory(value);
    }

    const addSpendCategory = () => {
        if (newSpendCategory !== '') {
            const newSpendSplitCategories = [...spendSplitCategories];
            newSpendSplitCategories.push({label: newSpendCategory, key: newSpendCategory, custom: true});
            setSpendSplitCategories(newSpendSplitCategories);
            setSpendCategory('');
            setSpendRow(false)
        }
    }

    const removeSpendRow = (index) => {
        const newSpendSplitCategories = [...spendSplitCategories];
        newSpendSplitCategories.splice(index, 1);
        setSpendSplitCategories(newSpendSplitCategories);
    }

    const getGrantValue = ({ requestType }) => {
        return _getGrantValue(requestType, club, clubMembers, clubFundTiers, values);
    };

    const handleUpload = e => {
        if (e === undefined) return;
        setSelectedFile(e.target.files[0]);
        setMetaFileName(e.target.files[0]?.name);
    };

    const onHandleAddReceipt = (id, value) => {
        let tempValues = Object.assign({}, tempReceipt);
        tempValues[id] = value;
        setTempReceipt(tempValues);
    };
    
    const handleReceiptUpload = e => {
        if (e === undefined) return;
        let tempValues = Object.assign({}, tempReceipt);
        tempValues['file'] = e.target.files[0];
        tempValues['fileName'] = e.target.files[0]?.name;
        setTempReceipt(tempValues);
    };

    const handleReceiptAdd = () => {
        setOpenReceiptForm(false);
        const receiptFields = {...tempReceipt};
        let tempValues = Object.assign({}, values);
        tempValues['receipts'].push(receiptFields);
        setValues(tempValues);
        document.getElementById('receiptFile').value = '';
        setTempReceipt({});
    }

    const handleReceiptUpdate = () => {
        setOpenReceiptForm(false);
        const receiptFields = {...tempReceipt};
        delete receiptFields.editIndex;
        let tempValues = Object.assign({}, values);
        console.log(tempReceipt);
        console.log(receiptFields);
        console.log([...tempValues['receipts']]);
        tempValues['receipts'][tempReceipt.editIndex] = receiptFields;
        console.log([...tempValues['receipts']]);
        setValues(tempValues);
        document.getElementById('receiptFile').value = '';
        setTempReceipt({});
    }

    const handleReceiptDelete = (index) => {
        let tempValues = Object.assign({}, values);
        tempValues['receipts'].splice(index, 1);
        setValues(tempValues);
    }

    const handleReceiptCancel = () => {
        setOpenReceiptForm(false);
        setTempReceipt({});
    }

    const handleSubmit = async () => {
        setIsSaving(true);
        
        const value = values.value_granted
          ? values.value_granted
          : getGrantValue({ requestType: values.typeName.toLowerCase() });
    
        const { usu: user } = memberDetails;
    
        try {
            if (value === "" || typeof value === "undefined" || String(value) === '0') {
                throw { customMessage: "No grant value requested" };
            }

            let raDoc = null;

            // upload risk assessment file
            const file = selectedFile;

            // Force RAF if filling out discretionary form and its not a capex event type.
            if (!file && values.typeName.toLowerCase() === 'discretionary' && values.event_type.toLowerCase() !== 'capital') {
                throw { customMessage: "No risk assessment file provided" };
            }

            // Only process upload if file is present
            if (file) {
                const { type } = file;
                const fileType = type.substring(type.indexOf('/') + 1, type.length);
                const fileName = `risk-assessment-${location.state.clubId}-${Date.now()}`;
        
                const base64Doc = await getBase64(selectedFile);
                const { response: doc } = await uploadMedia(
                    `${fileName}.${fileType}`,
                    base64Doc,
                    type
                );

                raDoc = doc.id;
            }

            const newRequestData = {
                club: {
                    clubId: club.id,
                    clubTitle: club.acf.full_title,
                    // clubStatus: club.acf.status.label,
                },
                submittedBy: `${user.FirstName} ${user.LastName} (${user.MemberNumber})`,
                type: values.type,
                typeName: values.typeName,
                value,
                funding_type: values.funding_type,
                joint_club_list: values.joint_club_list,
                event_name: values.event_name,
                event_date: values.event_date,
                event_type: values.event_type,
                event_description: values.event_description,
                detail_differences: values.detail_differences,
                member_benefits: values.member_benefits,
                cs_value_add: values.cs_value_add,
                spend_split: JSON.stringify(values.spend_split),
                risk_assessment: raDoc,
                associated_event: Number(values.associated_event),
                reason: values.summary
            };
            
            const { response: grant } = await submitNewGrantRequest(
                newRequestData
            );

            if (['camp', 'welcome fest'].indexOf(values.typeName.toLowerCase()) > -1) {
                // upload receipt images
                const uploads = values.receipts.map(async receipt => {
                    const file = receipt.file;
                    const { type } = file;
                    const fileType = type.substring(type.indexOf('/') + 1, type.length);
                    const fileName = `Grant-Receipt-${location.state.clubId}-${grant.id}-${extractDate(receipt.date)}`;
            
                    const base64Doc = await getBase64(file);
                    const { response: doc } = await uploadMedia(
                        `${fileName}.${fileType}`,
                        base64Doc,
                        type
                    );
            
                    const receiptData = {
                        grant: grant.id,
                        club: location.state.clubId,
                        dateOfPurchase: receipt.date,
                        description: receipt.description,
                        value: receipt.value,
                        receipt: doc.id,
                        submittedBy: `${user.FirstName} ${user.LastName} (${user.MemberNumber})`,
                        receiptName: fileName,
                    };

                    const { response: receiptResult } = await createNewReceipt(receiptData);

                    return receiptResult;
                });

                Promise.all(uploads).then(async uploadedReceipts => {
                    // Generate acquittal
                    // *** MOVED to approval of grant
                    // const acquittalFields = {
                    //     summary: values.summary,
                    //     explanation: '',
                    //     club: location.state.clubId,
                    //     status: 'pending',
                    //     grants: [{grant: grant.id}],
                    //     receipts: uploadedReceipts.map(r => ({receipt: r.id})),
                    //     no_amount_spent: false,
                    //     calculated_received: value,
                    //     calculated_spent: value,
                    //     submitted: `${new Date().getFullYear()}-${String(Number(new Date().getMonth()) + 1).padStart(2, '0')}-${new Date().getDate()}`,
                    //     submitted_by: `${user.FirstName} ${user.LastName} (${user.MemberNumber})`,
                    // };

                    // const newAcquittalData = {
                    //     club: {
                    //       clubId: club.id,
                    //       clubTitle: club.acf.full_title,
                    //     //   clubStatus: club.acf.status.label,
                    //     },
                    //     fields: acquittalFields
                    // };
            
                    // const { response: newAcquittal } = await createNewAcquittal(
                    //     newAcquittalData
                    // );
            
                    // // console.log(newAcquittal);
                    // return newAcquittal;
                    return uploadedReceipts;
                });
            }

            // console.log("Club data: ", club);
            const personaliseContent = pendingEmailBody
                .replace('##EXEC##', memberDetails.usu.FirstName)
                .replace('##GRANTTYPE##', values.typeName)
                .replace('##CLUBNAME##', decode(club.title.rendered));
            const emailBody = renderToString(<OrttoEmailTemplate logo={null} clubName={null} content={personaliseContent} />);
            // console.log("Email Body:", emailBody);

            const emailOptions = {
                html_body: emailBody,
                subject: `Grant received for ${decode(club.title.rendered)}`,
                email_name: `${decode(club.title.rendered)} grant pending - ${values.typeName}`,
                from_name: 'USU Staff',
                reply_to: 'staff@usu.edu.au'
            };

            const emailRecipients = [{
                email: memberDetails.email,
                first: memberDetails.object.first_name,
                last: memberDetails.object.last_name
            }]
            
            sendEmail(emailOptions, emailRecipients).then(postResponse => {
                // console.log(postResponse);

                setIsSaving(false);
                toast.success('Grant request submitted successfully!')
                navigate(`/account/my-usu/clubs/manage/finances/grants`, {
                    state: { ...location.state },
                })

                return postResponse;
            });
        } catch (e) {
          console.error(e);
          setIsSaving(false);
          if (get(e, 'customMessage', false)) {
            toast.error(e.customMessage);
          } else {
            toast.error('An unexpected error occurred during submission. Please try again.');
          }
        }
    };

    return (isAuthed && club) ? (
        <>
            <SelectField
              id='type'
              label='Grant Type'
              placeholder='Select a grant type'
              value={values.type}
              handleChange={onHandleChange}
              data={grantTypes.filter(grantType => {
                if (grantType.controls.enabled) {
                  if ((grantType.slug === 'semester' || grantType.slug === 'semester2') && hasActiveSemester) {
                    return false;
                  }
                  
                  if (grantType.controls.displayWithinDates.from && grantType.controls.displayWithinDates.to) {
                    const currentDate = new Date();
                    const fromDate = new Date(grantType.controls.displayWithinDates.from);
                    const toDate = new Date(grantType.controls.displayWithinDates.to);

                    if (fromDate < currentDate && toDate > currentDate) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }

                return false;
              }).map(grantType => {
                const label = ((grantType.slug === 'semester') || (grantType.slug === 'semester2')) ? `${grantType.name} ($${getGrantValue({ requestType: grantType.name.toLowerCase() })})` : grantType.name;
                return {
                    text: label,
                    value: grantType.id,
                }
              })}
              disabled={isSaving}
            />

            {values && values.typeName.toLowerCase() === 'camp' && (
                <>
                    <SelectField
                        id='associated_event'
                        label="Select the camp event this grant is for"
                        placeholder='Select an event'
                        value={values.associated_event}
                        handleChange={onHandleChange}
                        data={events}
                        disabled={isSaving || fetchingEvents}
                    />
                </>
            )}

            {values && ['camp', 'welcome fest'].indexOf(values.typeName.toLowerCase()) > -1 && (
                <>
                    <div className={styles.block}>
                        <h6>Receipts for what was spent</h6>
                        {['camp'].indexOf(values.typeName.toLowerCase()) > -1 && (
                            <span style={{fontSize: "16px", lineHeight: "1.2", display: "block", marginBottom: "20px"}}><span className='bold highlight-red'>NOTE:</span> The USU will reimburse expenditures for Club Camps up to $1200. We cannot guarantee that you will be reimbursed for the total amount you have requested, or for the maximum grant amount of $1200.</span>
                        )}
                        <DataTable
                            tableData={values.receipts}
                            headingKeys={[
                                {
                                    label: 'Value',
                                    data_key: 'value',
                                    sortable: false,
                                    format: 'currency',
                                },
                                {
                                    label: 'Submitted date',
                                    data_key: 'date',
                                    sortable: false,
                                },
                                {
                                    label: 'Receipt file',
                                    data_key: 'fileName',
                                    sortable: false,
                                }
                            ]}
                            rowActions={[
                                {
                                    label: 'Edit',
                                    cta: (data, index) => {
                                        console.log(data);
                                        console.log(index);
                                        setTempReceipt({...data, editIndex: index});
                                        setOpenReceiptForm(true);
                                    },
                                },
                                {
                                    label: 'Delete',
                                    withWarning: true,
                                    cta: (data, index) => {
                                        console.log(data);
                                        console.log(index);
                                        handleReceiptDelete(index)
                                    },
                                }
                            ]}
                        />

                        {!openReceiptForm && (
                            <Button level="primary" type="button" size='tinier' onClick={() => setOpenReceiptForm(true)}>+ Add receipt</Button>
                        )}

                        {openReceiptForm && (
                            <div>
                                <DateTimeInput
                                id='date'
                                type='date'
                                label='Date of Purchase'
                                placeholder='Date of Purchase'
                                handleChange={onHandleAddReceipt}
                                value={tempReceipt.date}
                                allowPastDate={true}
                                ref={dateOfPurchaseRef}
                                />

                                <FormInputField
                                id='value'
                                type='number'
                                labelName='Amount'
                                handleChange={onHandleAddReceipt}
                                value={tempReceipt.value}
                                min={0}
                                />

                                <FormInputField
                                id='description'
                                type='textarea'
                                labelName='Description'
                                handleChange={onHandleAddReceipt}
                                value={tempReceipt.description}
                                />

                                <div className={styles.filePickerContainer}>
                                <div className={styles.fileHelperContainer}>
                                    <span className={styles.fileName}>
                                    {tempReceipt.fileName ? tempReceipt.fileName : 'Photo of Receipt'}
                                    </span>
                                    <span className={styles.fileHelper}>File cannot exceed 1MB.</span>
                                </div>
                                <label className={styles.filePicker} htmlFor='receiptFile'>
                                    <input
                                    id='receiptFile'
                                    type='file'
                                    name='receiptFile'
                                    onChange={handleReceiptUpload}></input>
                                    Select Photo
                                </label>
                                </div>

                                <div className={styles.buttonWrap}>
                                    {'editIndex' in tempReceipt ? (
                                        <Button level="primary" type="button" size='tinier' onClick={handleReceiptUpdate}>Update</Button>
                                    ) : (
                                        <Button level="primary" type="button" size='tinier' onClick={handleReceiptAdd}>Add</Button>
                                    )}
                                    <Button level="secondary" type="button" size='tinier' onClick={handleReceiptCancel}>Cancel</Button>
                                </div>
                            </div>
                        )}
                    </div>

                    <FormInputField
                        id='summary'
                        type='textarea'
                        labelName="Please provide a brief summary on your club's activities and how the money was spent"
                        placeholder='Your brief summary...'
                        value={values['summary']}
                        handleChange={onHandleChange}
                    />
                </>
            )}

            {values && values.typeName.toLowerCase() === 'discretionary' && (
                <>
                    <SelectField
                        id='funding_type'
                        label="What funding are you applying for?"
                        placeholder='Select a funding type'
                        value={values.funding_type}
                        handleChange={onHandleChange}
                        data={[
                            {text: "Individual USU Discretionary Club Grant", value: 'individual'},
                            {text: "USU Discretionary Group Application", value: 'group'}
                        ]}
                        disabled={isSaving}
                    />
                    {(values && values.funding_type !== '' && values.funding_type !== null) && (
                        <>
                            {values.funding_type === 'group' && (
                                <FormInputField
                                    id='joint_club_list'
                                    type='textarea'
                                    labelName='List all Clubs involved in your joint application'
                                    value={values.joint_club_list}
                                    handleChange={onHandleChange}
                                    disabled={isSaving}
                                />
                            )}
                            <div className='grid grid-50'>
                                <FormInputField
                                    id='event_name'
                                    labelName='Event name'
                                    value={values.event_name}
                                    handleChange={onHandleChange}
                                    disabled={isSaving}
                                />
                                <DateTimeInput
                                    id='event_date'
                                    type='date'
                                    label='Event date'
                                    placeholder='dd/mm/yyyy'
                                    value={values.event_date}
                                    handleChange={onHandleChange}
                                    disabled={isSaving}
                                    ref={eventDateRef}
                                />
                            </div>
                            <SelectField
                                id='event_type'
                                label="Type of event"
                                placeholder='Select an event type'
                                value={values.event_type}
                                handleChange={onHandleChange}
                                data={[
                                    {text: "Off Campus USU Club event", value: 'offcampus'},
                                    {text: "On Campus USU Club event", value: 'oncampus'},
                                    {text: "Capital Expenditure", value: 'capital'}
                                ]}
                                disabled={isSaving}
                            />
                            <FormInputField
                                id='event_description'
                                type='textarea'
                                labelName='Event description'
                                value={values.event_description}
                                handleChange={onHandleChange}
                                disabled={isSaving}
                            />
                            <FormInputField
                                id='detail_differences'
                                type='textarea'
                                labelName='Provide detail on how this event and/or activity is different to what your club usually produces?'
                                value={values.detail_differences}
                                handleChange={onHandleChange}
                                disabled={isSaving}
                            />
                            <FormInputField
                                id='member_benefits'
                                type='textarea'
                                labelName='If successful, how will your members benefit from this additional funding?'
                                value={values.member_benefits}
                                handleChange={onHandleChange}
                                disabled={isSaving}
                            />
                            <FormInputField
                                id='cs_value_add'
                                type='textarea'
                                labelName='What value is this event and/or activity going to add to the C&S Program?'
                                value={values.cs_value_add}
                                handleChange={onHandleChange}
                                disabled={isSaving}
                            />
                            <FormInputField
                                id='value_granted'
                                type='number'
                                labelName={values.funding_type === 'group' ? 'Total funding request for group application (up to $6,000)' : 'Total funding request (up to $2,000)'}
                                value={values.value_granted}
                                handleChange={onHandleChange}
                                disabled={isSaving}
                                min={0}
                            />
                            <div className={styles.spendSplit}>
                                <h6 className={styles.label}>What will your grant be spent on?</h6>
                                <div className='grid grid-50'>
                                    <div className='blank'></div>
                                    <div className={styles.columnTitle}>Estimated Budget</div>

                                    {spendSplitCategories.map((row, rId) => (
                                        <React.Fragment key={row.key}>
                                            <div className={`${styles.rowTitle}${rId === 0 ? ` ${styles.first}` : ''}${(rId === (spendSplitCategories.length - 1) && !addSpendRow) ? ` ${styles.last}` : ''}`}>
                                                {row.label}
                                                {row.custom && (
                                                    <span className={styles.rowDelete} onClick={() => removeSpendRow(rId)}><Icon symbol='bin' /></span>
                                                )}
                                            </div>
                                            <FormInputField
                                                id={`spendSplit_${row.key}`}
                                                type='number'
                                                value={values.spend_split[row.key]}
                                                handleChange={onHandleSpendSplit}
                                                disabled={isSaving}
                                                min={0}
                                            />
                                        </React.Fragment>
                                    ))}

                                    {addSpendRow && (
                                        <>
                                            <FormInputField
                                                id={`spendSplit_new`}
                                                handleChange={onAddSpendRow}
                                                disabled={isSaving}
                                            />
                                            <div className={styles.addCategory}>
                                                <span role='presentation' className={styles.addRow} onKeyDown={addSpendCategory} onClick={addSpendCategory}>+ Add</span>
                                            </div>
                                        </>
                                    )}
                                    
                                    <div role='presentation' className={styles.addRow} onClick={() => setSpendRow(true)} onKeyDown={() => setSpendRow(true)}>+ Add spend category</div>
                                    <div className='blank'></div>
                                </div>
                            </div>
                            <div className={styles.filePickerContainer}>
                                <div className={styles.fileHelperContainer}>
                                    <span className={styles.fileName}>
                                    {metaFileName ? metaFileName : 'Risk assessment file'}
                                    </span>
                                    <span className={styles.fileHelper}>PDF only. File cannot exceed 1MB.</span>
                                </div>
                                <label className={styles.filePicker} htmlFor='risk_assessment'>
                                    <input
                                        id='risk_assessment'
                                        type='file'
                                        name='risk_assessment'
                                        onChange={handleUpload} />
                                    Select file
                                </label>
                            </div>
                        </>
                    )}
                </>
            )}

            {(!openReceiptForm && (
                (values && get(values, 'typeName', '').toLowerCase() === 'semester 1') || // Semester 1
                (values && get(values, 'typeName', '').toLowerCase() === 'semester 2') || // Semester 2
                (values && get(values, 'typeName', '').toLowerCase() === 'discretionary' && values.funding_type !== '' && values.funding_type !== null) || // Discretionary
                (values && get(values, 'typeName', '').toLowerCase() === 'camp' && values.associated_event !== '' && values.associated_event !== null && values.receipts.length > 0) || // Camp
                (values && get(values, 'typeName', '').toLowerCase() === 'welcome fest' && values.receipts.length > 0) // Welcome Fest
            )) && (
                <div style={{marginTop: '40px'}}>
                    <Button level="primary" type="button" disabled={isSaving} onClick={() => handleSubmit()}>{isSaving ? 'Submitting...' : 'Submit'}</Button>
                </div>
            )}
        </>
    ) : (
        <div className={styles.root}>
            {/* Fetching club preferences */}
            {(isAuthed === null) && (
                <div>Authenticating...</div>
            )}
            {/* No clubs found */}
            {(isAuthed === true && club === false) && (
                <div>An error occurred. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
            )}
            {/* No clubs found */}
            {(isAuthed === false) && (
                <div>You are not authorised to manage this club. Return back to <Link to="/account/my-usu/clubs"><u>list</u></Link></div>
            )}
        </div>
    );
};

const ClubManagementOutput = ({location}) => {
    const backToManagementPage = () =>{
        navigate(`/account/my-usu/clubs/manage`,{
            state: location.state
        })
    }

    const backToFinancesPage = () =>{
        navigate(`/account/my-usu/clubs/manage/finances`,{
            state: location.state
        })
    }

    const backToGrantsPage = () =>{
        navigate(`/account/my-usu/clubs/manage/finances/grants`,{
            state: location.state
        })
    }

    return (
        <AccountPageWrapper bgRaw metaTitle="Account - Club Management" title="Request new grant" breadcrumbTitle="Request new grant" breadcrumbs={[{ link: '/account/my-usu/clubs/', label: 'My USU' }, { onClick: () => backToManagementPage(), label: get(location, 'state.clubTitle', '')}, { onClick: () => backToFinancesPage(), label: "Club Finances"}, { onClick: () => backToGrantsPage(), label: "Manage Grants"}]} menuType="clubManage" location={location}>
            <NewGrant location={location} />
        </AccountPageWrapper>

    )
}

export default ClubManagementOutput

