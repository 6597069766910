import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';
import { get } from 'lodash';
import { decode } from 'he';
import { CSVLink } from "react-csv";
import AuthContext from '../../../context/AuthProvider';
import { dateFormat, hasStaffPermissions, persistLocation } from '../../../helpers/general';
import AccountPageWrapper from '../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import DataTable from '../../../components/organisms/DataTable/DataTable';
import DialogMatter from '../../../components/atoms/Dialog/Dialog';
import Loader from "../../../components/atoms/Loader/Loader";
import Club from '../../../components/organisms/Club/Club';
import FormInputField from '../../../components/atoms/FormInputField/FormInputField';
import { transformWpClub, wpApi, getHeader, wpAll } from '../../../helpers/wordpress';
import { getTags, getPerson } from '../../../helpers/ortto';
// import * as styles from '../my-usu/dashboard.module.css';
import * as styles from '../manage-screens.module.css';
import Button from '../../../components/atoms/Button/Button';

const Manage = () => {
  const auth = useContext(AuthContext);
  const [authed, setAuthed] = useState(null);
  const [hasPermission, setPermission] = useState(false);
  const [clubList, setClubList] = useState(null);
  const [clubListExport, setClubListExport] = useState(null);
  const [totalClubs, setTotalClubs] = useState(0);
  const [totalMemberCount, setTotalMemberCount] = useState(0);
  const [clubMemberCounts, setClubMemberCounts] = useState([]);
  const [clubMemberDetail, setClubMemberDetail] = useState([]);
  const [clubMemberDetailReady, setClubMemberDetailReady] = useState(false);
  const [clubMemberDetailProgress, setClubMemberDetailProgress] = useState(0);
  const [clubMemberDetailTotal, setClubMemberDetailTotal] = useState(0);
  const [csvLoader, setCSVLoader] = useState(false);
  
  const [previewClub, setPreviewClub] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [quickSearchList, setQuickSearchList] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteData, setDeleteData] = useState([]);
  const [bulkActions, setBulkActions] = useState([]);
  const [rowActions, setRowActions] = useState([
    {
      label: 'Edit',
      cta: data =>
        navigate('/account/manage-clubs/edit', {
          state: {
            clubId: data.id,
            clubName: `${data.club_name} (${data.status})`,
            forApproval: true,
          },
        }),
    },
    { label: 'Preview', cta: data => preview(data) },
  ]);

  const clubsQuickSearchData = useStaticQuery(graphql`
    query {
      clubs: allWpClub {
        nodes {
          club_name: title
          id: databaseId
          slug
          clubFieldsMain {
            abbr
          }
        }
      }

      pendingClubs: allWpPendingClub {
        nodes {
          club_name: title
          id: databaseId
          slug,
          status
        }
      }
    }
  `)

  useMemo(() => {
    if (authed === null && get(auth, 'state.userChecked')) {
      setAuthed(hasStaffPermissions(auth, [2, 3, 4]));
      const permission = hasStaffPermissions(auth, [3, 4]);
      setPermission(permission);

      if (permission) {
        setBulkActions([{ label: 'Bulk delete', key: 'delete' }]);
        const _rowActions = [...rowActions , {
          label: 'Manage Members', cta: data => navigate('/account/manage-clubs/members', {
            state: {
              clubId: data.id,
              clubSlug: data.slug,
              clubName: `${data.club_name}`
            },
          }),
        },
        {
          label: 'Delete', cta: data => {setDeleteData([data]); setOpenConfirmDelete(true)}
        }
        ];

        setRowActions(_rowActions);
      }
    }
  }, [auth, authed, setAuthed, rowActions]);

  useEffect(() => {
    if (!quickSearchList && clubsQuickSearchData) {
        const qsClubList = clubsQuickSearchData?.clubs?.nodes.map((club) => club);
        const qsPendingClubList = clubsQuickSearchData?.pendingClubs?.nodes.map((club) => club).filter((club) => club.status !== 'ign');
        const qsList = [...qsClubList, ...qsPendingClubList];
        setQuickSearchList(qsList);
    }
  }, [clubsQuickSearchData, quickSearchList])

  const fetchClubList = useCallback(async () => {
    setLoading(true);
    // const clubsModified = await wpApi(`clubs?staff_display`);
    const clubsPendingApproval = await wpApi(`clubs?pending_approval&per_page=100`);
    // console.log("Pending", clubsPendingApproval);
    const clubsCurrentIgmAgm = await wpApi(`clubs?current_igmagm&per_page=100`);
    // console.log("AGM/IGM", clubsCurrentIgmAgm);

    /* Get total running club count */
    const clubsCount = await wpApi(`clubs?per_page=1`);
    const _totalClubs = getHeader(clubsCount.headers, 'x-wp-total');
    setTotalClubs(_totalClubs || 0);

    /* Get list of club tags with counts */
    /*** Disable club member counts temporarily while endpoint call takes longer than 10s to complete
    const _memberCounts = await getTags('club-');
    const memberCounts = _memberCounts.response.filter(t => t.name.startsWith('club-'));
    const breakdownData = memberCounts.map(t => ({
      name: t.name.replace(/club-(.*)/, '$1').split('-').map(w => w[0].toUpperCase() + w.substring(1)).join(' '),
      members: t.count
    }))
    setClubMemberCounts(breakdownData);
    const _totalMemberCount = memberCounts.map(t => t.count || 0).reduce((sum, a) => sum + a, 0);
    setTotalMemberCount(_totalMemberCount || 0);
    ***/
    setClubMemberCounts([]);
    setTotalMemberCount('-');

    let clubsStarting = false;
    if (hasPermission) {
      clubsStarting = await wpApi(`clubs?status=applied,compliant,engaged&per_page=100`);
      // console.log("Starting", clubsStarting);
    }

    const clubs = [];
    if (clubsPendingApproval.status === 200) {
      clubs.push(...clubsPendingApproval.response);
    }
    if (clubsCurrentIgmAgm.status === 200) {
      clubs.push(...clubsCurrentIgmAgm.response);
    }
    if (clubsStarting && clubsStarting.status === 200) {
      clubs.push(...clubsStarting.response);
    }

    // Due to dual API above there is an instance when a club are fetch for both and results to duplicate in final club list - JR
    const uniqClubs = clubs.reduce((accumulator, current) => {
      if (!accumulator.find((item) => item.id === current.id)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);

    if (uniqClubs.length > 0) {
      const _clubList = uniqClubs.map(async club => {
        const statuses = {
          publish: "Pending changes",
          applied: "New application",
          compliant: "Ready for IGM",
          engaged: "IGM completed",
          agm: "AGM completed",
          inprogress: "AGM running",
          error: "AGM misconfigured"
        }
        if (club.status === 'publish' && club.acf.current_igmagm_history_record && club.acf.current_igmagm_history_record !== null) {
          club.status = 'agm';
          const history = await wpApi(`igm_agm_history/${club.acf.current_igmagm_history_record.ID}`);
          // console.log("history: ", history);
          if (String(history.status).startsWith('2')) {
            if (history.response.acf.status === 'running') {
              club.status = 'inprogress';
            } else if (history.response.acf.status === 'finished') {
              club.status = 'error';
            }
          }
        }
        
        return {
          ...club,
          club_name: decode(club.title.rendered),
          status: statuses[club.status]
        }
      });

      Promise.all(_clubList).then(_list => {
        setClubList(_list);

        const clubExport = [];
        _list.map((list) => {
          clubExport.push({
            'title': list.acf.full_title,
            'status': list.status,
            'club_updated': dateFormat(list.modified),
            'club_execs': JSON.stringify(list.acf.club_execs),
            'new_club_roles': JSON.stringify(list.acf.club_roles),
            'fee': JSON.stringify(list.acf.fees),
            'submitted': list.acf.submitted,
            'submitted_by': list.acf.submitted_by,
            'vendor_code': list.acf.vendor_code,
            'verified': list.acf.verified,
            'verified_by': list.acf.verified_by,
          });
          
          return list;
        })
        setClubListExport(clubExport);
      })
    } else {
      // Failed fetch of club data
      setClubList(false);
    }
    setLoading(false);
  }, [hasPermission]);

  useMemo(() => {
    if (clubList === null) {
      fetchClubList();
    }
  }, [clubList, fetchClubList]);

  const preview = async data => {
    const previewData = await wpApi(`clubs/${data.id}?`);
    if (String(previewData.status).startsWith('2') && previewData.response) {
      const event = transformWpClub(previewData.response);
      setPreviewClub(event);
    }
  };

  const deleteClubs = async () => {
    setOpenConfirmDelete(false);
    const _deleteData = [...deleteData];
    setDeleteData([]);
    const promises = _deleteData.map(async club => {
      return await wpApi(`clubs/${club.id}`, 'DELETE');
    });

    await Promise.all(promises);

    setClubList(null);
    fetchClubList();
  }

  const handleBulkActions = (key, data) => {
    switch (key) {
      case "delete":
        setDeleteData(data); 
        setOpenConfirmDelete(true);
        break;

      default:
        // do nothing
        break;
    }
  }

  const handleClubSearch = (id, value) => {
    setSearchQuery(value);
    actionQuickSearch(value);
  }

  const actionQuickSearch = (value) => {
    if (value.length >= 3) {
      const results = quickSearchList.filter(club => {
        const name = decode(`${club.club_name} (${club.clubFieldsMain?.abbr || 'PENDING'})`);
        return name.toLowerCase().indexOf(value.toLowerCase()) > -1
      });
      setSearchResults(results);
    }
  }

  const fetchDetailList = () => {
    setCSVLoader(true);
    let progress = 0;

    wpAll('clubs', 100).then(clubs => {
    // wpApi(`clubs?per_page=20`).then(clubs => {
      if (String(clubs.status).startsWith('2') && clubs.response) {
        setClubMemberDetailTotal(clubs.response.length);
        const membersList = [];
        const loop = clubs.response.map(club => {
          return getTags(`club-${club.slug}`).then(result => {
            if (
              result.status === 200 &&
              result.response &&
              result.response.length > 0
            ) {
              const findClub = result.response.find(
                fclub => fclub.name === `club-${club.slug}`
              );
              // Successfully found
              if (findClub) {
                return getPerson(
                  ['first', 'last', 'email', 'Tags', 'MemberNumber'],
                  findClub.id,
                  'tag_id'
                ).then(memberList => {
                  if (
                    memberList.status === 200 &&
                    'contacts' in memberList.response &&
                    Array.isArray(memberList.response.contacts)
                  ) {
                    progress = progress + 1;
                    // console.log(progress);
                    setClubMemberDetailProgress(progress);
                    return memberList.response.contacts.map(member => {
                      membersList.push({
                        member_number: member.fields['str:cm:membernumber'] || '0',
                        club_id: club.id,
                        club_tag: `club-${club.slug}`
                      });
                      return true;
                    });
                  } else {
                    console.log("Person fetch worked with error");
                    return false;
                  }
                }).catch(() => {
                  console.log("Catch person fetch");
                  return false
                });
              } else {
                console.log("Club not found", `club-${club.slug}`, result.response);
                return true;
              }
            } else {
              console.log("Tags fetch worked with error");
              return false;
            }
          }).catch(() => {
            console.log("Catch tags fetch");
            return false
          });
        });
        
        Promise.all(loop).then(() => {
          // console.log(membersList);
          setCSVLoader(false);
          setClubMemberDetailReady(true);
          // createJSON(membersList, 'club-members-detail');
          JSONDownload(membersList, 'club-members-detail');
        });
      } else {
        console.log("Club fetch worked with error");
        setCSVLoader(false);
      }
    }).catch(() => {
      console.log("Catch club fetch");
      setCSVLoader(false);
    });
  }

  const JSONDownload = (data) => {
    const jsonData = new Blob([JSON.stringify(data)], {type: 'text/json'});
    const href = URL.createObjectURL(jsonData);
    setClubMemberDetail(href);
  }

  return (
    <>
      {authed ? (
        <div className={`${styles.root}`} style={{opacity: loading ? '0.5' : '1'}}>
          {loading ? (
            <>Fetching clubs. Please wait...</>
          ) : (
            <>
              {/**
               * Clubs withs statuses or flags that require staff attention 
               **/}
              <h6 className='mb-4'>Clubs requiring attention</h6>
              <DataTable
                tableData={clubList}
                exportData={clubListExport}
                bulkActions={bulkActions}
                allowExport={true}
                handleBulkAction={handleBulkActions}
                headingKeys={[
                  { label: 'Club name', data_key: 'club_name', sortable: true },
                  { label: 'Status', data_key: 'status', filterable: true },
                  { label: 'Updated', data_key: 'modified', sortable: true, format: 'dateTime' },
                ]}
                rowActions={rowActions}
                bulkAction={true}
              />
              {loading && <div className={styles.loaderContainer}><Loader /></div>}
              <DialogMatter
                size='lg'
                title='Preview Club'
                hideBtnCancel={true}
                onOk={() => setPreviewClub(false)}
                open={typeof previewClub === 'object'}>
                {previewClub && <Club club={previewClub} events={[]} />}
              </DialogMatter>
              <DialogMatter size='xs' open={openConfirmDelete} okBtnText="Confirm" onOk={() => deleteClubs()} onBtnCancel={() => setOpenConfirmDelete(false)}>
                Are you sure you want to delete {deleteData.length} club{deleteData.length > 1 ? 's' : ''}?
              </DialogMatter>

              {/**
               * Club search
               **/}
              <h6 className='mb-4 mt-6'>Search for a club</h6>
              <div className={`${styles.root} ${styles.withBg}`}>
                <FormInputField placeholder="Search club name" handleChange={handleClubSearch} />
                <div>
                  {(searchQuery && searchQuery.length >= 3) && (
                    <>
                      {searchResults.length === 0 && (
                        <p>No clubs found</p>
                      )}

                      {searchResults.length > 0 && (
                        <DataTable
                          tableData={searchResults}
                          headingKeys={[
                            { label: 'Club name', data_key: 'club_name', sortable: true },
                          ]}
                          rowActions={[
                            {
                              label: 'Edit',
                              cta: data =>
                                navigate('/account/manage-clubs/edit', {
                                  state: {
                                    clubId: data.id,
                                    clubName: `${data.club_name}`,
                                    forApproval: true,
                                  },
                                }),
                            },
                            {
                              label: 'Manage members',
                              cta: data =>
                                navigate('/account/manage-clubs/members', {
                                  state: {
                                    clubId: data.id,
                                    clubSlug: data.slug,
                                    clubName: `${data.club_name}`
                                  },
                                }),
                            },
                          ]}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>

              {/**
               * Clubs analytics
               **/}
              <h6 className='mb-4 mt-6'>Analytics</h6>
              <div className={`${styles.subroots} grid grid-50`}>
                  <div className={`${styles.root} ${styles.withBg}`}>
                    <div className={styles.displayNumberContainer} >
                      <span className={styles.displayNumber}>
                        {totalClubs} &nbsp;
                      </span>
                      <strong>Total running club{totalClubs > 1 && 's'}</strong>
                    </div>
                  </div>

                  <div className={`${styles.root} ${styles.withBg}`}>
                    <div className={styles.displayNumberContainer} >
                      <span className={styles.displayNumber}>
                        {totalMemberCount} &nbsp;
                      </span>
                      <strong>Total club member{totalMemberCount > 1 && 's'}</strong>
                    </div>
                    <span className={styles.analyticActionItem}>
                      Download:&nbsp;
                      <CSVLink data={clubMemberCounts} filename={`club-count-summary`}>
                        Summary per club
                      </CSVLink>
                      &nbsp;|&nbsp;
                      <Button type="span" link={true} onClick={fetchDetailList}>Detailed per member</Button>
                    </span>
                    {csvLoader && (
                      <div className={styles.loaderCover}><span><Loader /><strong>Please wait - fetching {clubMemberDetailProgress} of {clubMemberDetailTotal}</strong></span></div>
                    )}
                    {clubMemberDetailReady && (
                        <div className={styles.loaderCover}><a href={clubMemberDetail} download="club-members-detail.json">View result</a></div>
                    )}
                  </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className={styles.root}>
          {/* Fetching data */}
          {authed === null && <div>Fetching data</div>}
          {/* No data found */}
          {authed === false && (
            <div>
              An error occurred. Return back to{' '}
              <Link to='/account/'>
                <u>dashboard</u>
              </Link>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const USUClubManagementOutput = ({ location }) => {
  const persistedLocation = persistLocation(location);
  return (
    <AccountPageWrapper
      metaTitle='Account - USU Management'
      bgRaw
      title='Manage Clubs'
      breadcrumbTitle='Manage Clubs'>
      <Manage location={persistedLocation} />
    </AccountPageWrapper>
  )
};

export default USUClubManagementOutput;

