import React, { useContext, useMemo, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { get } from 'lodash';

import AuthContext from '../../../context/AuthProvider'

import Button from '../Button/Button'
import CurrencyFormatter from '../CurrencyFormatter/CurrencyFormatter'
import DialogMatter from '../Dialog/Dialog';
import FormInputField from '../FormInputField/FormInputField';
import SelectField from '../SelectField/SelectField';
import OrttoEmailTemplate from '../../organisms/OrttoEmailTemplate/OrttoEmailTemplate';
import StripePayment from '../../molecules/StripePayment/StripePayment';
import { paymentIntent, upsertCustomer } from '../../../helpers/stripe';
import { getPerson, getTags, updatePerson, sendEmail } from '../../../helpers/ortto'
import { transformToEventDate, generateQR, formatEventDateTime } from '../../../helpers/general'
import { success, error } from '../../../helpers/toast';
import { wpApi } from '../../../helpers/wordpress';

import * as styles from './JoinEvent.module.css';


const JoinEvent = props => {
  const { event } = props;
  // console.log(event);
  const regoQuestions = Object.keys(event.regoQuestions).filter(question => event.regoQuestions[question] === true);
  const auth = useContext(AuthContext);
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const userChecked = auth && auth.state.userChecked;
  const userEmail = (auth && auth.state.email) || false;
  const userName = (auth && {firstName: get(auth, 'state.usu.FirstName', ''), lastName: get(auth, 'state.usu.LastName', '')}) || false;
  const orttoKey = `event-${event.slug}`;
  const orttoJoinedKey = `eventJoined-${event.slug}`;
  const [displayQuestions, setDisplayQuestions] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [showPayment, setShowPayment] = useState(false);
  const [stripeClientSecret, setClientSecret] = useState(null);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const [returnUrl, setReturnUrl] = useState(null);

  const dates = [];
  if (event.startDate) dates.push(event.startDate);
  if (event.endDate && event.endDate !== event.startDate) dates.push(event.endDate);
  const [status, setStatus] = useState(null);
  const [registrationsAvailable, setRegistrationsAvailable] = useState(true);

  // event.recurringEvent = "yes";
  // event.recurringInfo.repeatType = "repeat_times";
  // event.recurringInfo.repeatDays = '["tue"]';
  // event.recurringInfo.repeatTimes = '2';
  // event.roomName = "Example room";
  // event.timeSlot = "2pm - 4pm";
  // console.log(event, dates);

  // Ortto -> WP
  const fieldTranslator = useMemo(() => ({
    'daytriplength': 'dayTripLength',
    'daytriptimes': 'dayTripTimes',
    'dietaryrequirements': 'dietaryRequirements',
    'domesticinternational': 'domesticInternational',
    'findoutdaytrips': 'findOutDayTrips',
    'futuredaytrips': 'futureDayTrips'
  }), []);

  const dayTranslate = {
    'mon': "Monday",
    'tue': "Tuesday",
    'wed': "Wednesday",
    'thu': "Thursday",
    'fri': "Friday",
    'sat': "Saturday",
    'sun': "Sunday",
  }

  useMemo(async () => {
      // If user is logged in, check if they are already a member of the club
      if (userChecked && isLoggedIn && status === null) {
          const fields = [];
          fields.push(...regoQuestions.map(question => `str:cm:eq${question.toLowerCase()}`));
          const result = await getPerson(fields, userEmail);
          const tags = await getTags(orttoJoinedKey);
          if (String(result.status).startsWith('2') && "contacts" in result.response && result.response.contacts.length > 0) {
              // Successfully found
              const rUrl = `${(typeof window !== 'undefined' ? window.location.href : '/')}?`
              setReturnUrl(rUrl);

              // Check if registrations are limited and check if spots are available
              let _registrationsAvailable = true;
              if (event.limitRegistrationAmount !== null && event.limitRegistrationAmount !== '') {
                if ((String(tags.status).startsWith('2') && Array.isArray(tags.response))) {
                  const tagsMatched = tags.response.filter(tag => tag.name.startsWith(orttoJoinedKey));
                  _registrationsAvailable = Number(tagsMatched.length) < Number(event.limitRegistrationAmount);
                } else {
                  // Can't define tags, so prevent registrations until we can
                  _registrationsAvailable = false;
                }

                setRegistrationsAvailable(_registrationsAvailable);
              }

              if (result.response.contacts[0].fields && result.response.contacts[0].fields.tags && result.response.contacts[0].fields.tags.indexOf(orttoKey) > -1) {
                  setStatus(true);
              } else {
                  setStatus(false);

                  if (result.response.contacts[0].fields) {
                    const storedAnswers = {};
                    Object.keys(result.response.contacts[0].fields).map(field => {
                      if (field.indexOf('str:cm:eq') > -1) {
                        const key = field.replace('str:cm:eq', '');
                        storedAnswers[fieldTranslator[key]] = result.response.contacts[0].fields[field];
                      }

                      return true;
                    });

                    if (Object.keys(storedAnswers).length > 0) {
                      setAnswers({...storedAnswers});
                    }
                  }
              }
          } else {
              setStatus(false);
          }
      } else if (userChecked && status === null) {
        setStatus(false);
      }
  }, [isLoggedIn, userEmail, status, orttoKey, regoQuestions, fieldTranslator, event]);

  const processEmail = async (recipients) => {
    /* Event will not have club data, even though the club is actually attached to the event. We need to fetch the club directly using the slug */
    const clubSlug = event.slug.replace('-igm-meeting', '').replace('-agm-meeting', '');
    const club = await wpApi(`clubs?status=ign,publish&slug=${clubSlug}`);
    
    let extraBody = '';
    if (event.enableTickets) {
      const qrCode = await generateQR({memberId: auth.state.usu.MemberNumber, eventId: event.databaseId});
      extraBody = `
        <p>Use the below to sign into the event on the day:</p>
        <p><img src="${qrCode}" alt="QR Code" /></p>
      `;
    }
    
    if (String(club.status).startsWith('2') && club.response) {
        const clubData = club.response[0];
        const body = `
          <p>Hi ${recipients[0].first},</p>
          <p>Thank you for registering for the ${event.title} on ${transformToEventDate(event.startDate, event.startTime, event.endDate, event.endTime, true).join(' - ')}!</p>
          ${extraBody}
          <p>See you there!</p>
        `;
        // console.log("Club data: ", club);
        const emailBody = renderToString(<OrttoEmailTemplate logo={clubData.acf.logo} clubName={clubData.title.rendered} content={body} />);
        // console.log("Email Body:", emailBody);

        const emailOptions = {
            html_body: emailBody,
            subject: `${clubData.title.rendered} - ${event.title} registration`,
            email_name: `${clubData.title.rendered} - ${event.title} registration`,
            from_name: get(clubData, 'acf.club_preferences.sender_name', 'USU'),
            reply_to: get(clubData, 'acf.club_preferences.sender_email', 'no-reply@usu.edu.au')
        };
        
        return sendEmail(emailOptions, recipients).then(postResponse => {
            // console.log(postResponse);

            return postResponse;
        });
    } else {
        return false;
    }
}

  const joinEvent = async (answers = {}) => {
    /** 
     * Check if rego questions are required to be asked
     */
    if ((regoQuestions.length > 0 && Object.keys(answers).length === 0)) {
      setDisplayQuestions(true);
      setQuestions([...regoQuestions]);
    }

    if (status === false && regoQuestions.length === Object.keys(answers).length) {
      setDisplayQuestions(false);
      
      if (event.eventFee !== null || Number(event.eventFee) !== 0) {
        setShowPayment(true);
        const customerMetaData = {
            'USU Member Number': auth.state.usu.MemberNumber,
        };
        const paymentMetaData = {
            'USU Member Number': auth.state.usu.MemberNumber,
            'Event Name': event.title,
            'Event ID': event.databaseId
        };
        if (event.club) {
          paymentMetaData['USU Club'] = event.club.title;
          paymentMetaData['USU Club ID'] = event.club.id;
        }
        upsertCustomer(auth.state.email, `${auth.state.usu.FirstName} ${auth.state.usu.LastName}`, customerMetaData, event.club?.clubFieldsSide.stripeConnectId || process.env.GATSBY_STRIPE_DEFAULT_CONNECT).then(customer => {
            // console.log(customer);
            if (String(customer.status).startsWith("2")) {
                paymentIntent(event.eventFee, event.club?.clubFieldsSide.stripeConnectId || process.env.GATSBY_STRIPE_DEFAULT_CONNECT, customer.response.id, paymentMetaData).then(response => {
                    if (String(response.status).startsWith('2') && 'client_secret' in response.response) {
                        setClientSecret(response.response.client_secret);
                    } else {
                        // TODO: Handle stripe errors
                    }
                });
            }
        })
      } else {
        registerUser();
      }
    }
  }

  const handlePayment = (object) => {
    if (object && object.status === 'succeeded') {
        setClientSecret(null);
        setTransactionSuccess(true);
        registerUser();
    } else {
        // This should never happen because the check for the error is done in the StripePayment component.
        console.log(object, object.error);
    }
}

  const registerUser = async () => {
    const clubKey = orttoKey;
    const userKey = userEmail;
    const eventJoinedDate = `eventJoined-${event.slug}-${Date.now()}`;

    const person = [
        {
            email: userKey,
            tags: [clubKey, eventJoinedDate],
        }
    ];
    person[0].customFields = {};
    Object.keys(answers).map(question => {
      person[0].customFields[`eq${question.toLowerCase()}`] = answers[question];
      
      return true;
    });
    
    const updateResponse = await updatePerson(person);

    if (updateResponse.status === 200 && "people" in updateResponse.response) {
        // Successfully added
        if (event.slug.indexOf('-igm-meeting') > -1 || event.slug.indexOf('-agm-meeting') > -1) {
          // Send email with QR
          const emailRecipient = [{
              email: userEmail,
              first: userName.firstName || '',
              last: userName.lastName || ''
          }];
          // const emailRecipient = [{
          //   email: "ash@matterdesign.com.au",
          //   first: "Ash",
          //   last: "Durham"
          // }];
          processEmail(emailRecipient).then(() => {
            // console.log('Result:', result);

            setStatus(true);
            success('Event registered');
          });
        } else {
          setStatus(true);
          success('Event registered');
        }
    } else {
        // Error occurred
        error('There was an issue registering. Please try again');
    }
    setStatus(true);
    success('Event registered');
  }

  const dateTime = formatEventDateTime(event.startDate, event.endDate, event.startTime, event.endTime, true);
  const formattedDateTime = transformToEventDate(event.startDate, event.startTime, event.endDate, event.endTime, true, false);
  const logo = get(event, 'club.clubFieldsSide.logo.localFile.childImageSharp.fluid.src', get(event, 'club.clubFieldsSide.logo.sourceUrl', get(event, 'club.logo', false)));

  // console.log('>>>>>>>>>>>>>', event, event.eventFee);
  return (
    <>
      {(event.club && logo) && (
        <div className={`${styles.clubLogo}`}>
            <img alt='club logo' src={logo} />
        </div>
      )}
      <div>
        <div className={`${styles.contentBlock} joinButton`}>
          {event.registrationOverride ? (
            <Button className={styles.submit} level="primary" type="a" disabled={status !== false || !registrationsAvailable} href={event.registrationOverride} target="_blank">{status === true ? 'Registered' : (!registrationsAvailable ? 'Sold Out' : (event.ctaLabel || 'Register'))}</Button>
          ) : (
            <>
              {(event.recurringEvent !== 'yes' || (event.recurringEvent === 'yes' && !event.recurringInfo.repeatDays)) && (
                <>
                  {isLoggedIn ? (
                      <Button className={styles.submit} level="primary" type="button" disabled={status !== false || !registrationsAvailable} onClick={() => joinEvent()}>{status === true ? 'Registered' : (!registrationsAvailable ? 'Sold Out' : (event.ctaLabel || 'Register'))}</Button>
                  ) : (
                      <Button className={styles.submit} level="primary" type="a" href="/login/">Log in</Button>
                  )}
                </>
              )}
            </>
          )}
        </div>
        {((event.recurringEvent === 'no' && dates.length > 0) || (event.recurringEvent === 'yes' && !event.recurringInfo.repeatDays)) && (
            <div className={`${styles.contentBlock} dates`}>
                <h6>DATE:</h6>
                <div className='date-list'>
                    {dateTime.formatDate} <br/>
                    {dateTime.formatTime} 
                    
                </div>
            </div>
        )}
        {(event.recurringEvent === 'yes' && event.recurringInfo.repeatDays) && (
          <div className={`${styles.contentBlock} dates`}>
              {/* <h6>DATES:</h6> */}
              <div className='date-list'>
                  Every {JSON.parse(event.recurringInfo.repeatDays).map(d => dayTranslate[d]).join(',')} in {event.roomName} between {event.timeSlot} until {formattedDateTime.pop()}
              </div>
          </div>
        )}
        {((event.club === null && event.eventFee !== null) || event.club) && (
          <div className={`${styles.contentBlock} fee`}>
              <h6>EVENT FEE:</h6>
              {event.eventFee ? (
                  <div className='fee'><CurrencyFormatter amount={event.eventFee} /></div>
              ) : (<div className='fee free'>FREE</div>)}
          </div>
        )}
        {event.location && (
            <div className={`${styles.contentBlock} location`}>
                <h6>LOCATION:</h6>
                <div className='location-display'>
                    {event.location}
                </div>
            </div>
        )}
        {event.club && (
            <div className={`${styles.contentBlock} host`}>
                <h6>HOST:</h6>
                <a className='club' href={event.club.uri}>
                    {event.club.title}
                </a>
            </div>
        )}
      </div>
      <DialogMatter open={displayQuestions} title={event.title} onOk={() => joinEvent(answers)} okBtnText="Register" hideBtnCancel={true}>
        <div className={styles.questions}>
          {questions.indexOf('dietaryRequirements') > -1 && (
            <FormInputField type="textarea" id='dietaryRequirements' value={answers['dietaryRequirements'] || ''} labelName="Do you have any dietary requirements?" handleChange={(id, value) => setAnswers({ ...answers, 'dietaryRequirements': value})} />
          )}

          {questions.indexOf('domesticInternational') > -1 && (
            <SelectField label="Are you a domestic or an international student?" id='domesticInternational' value={answers['domesticInternational'] || ''} placeholder='Please select...' handleChange={(id, value) => setAnswers({ ...answers, 'domesticInternational': value})} data={[{value: 'domestic', text: 'Domestic'}, {value: 'international', text: 'International'}]} />
          )}

          {questions.indexOf('findOutDayTrips') > -1 && (
            <FormInputField type="textarea" id='findOutDayTrips' value={answers['findOutDayTrips'] || ''} labelName="How did you find out about USU Day Trips?" handleChange={(id, value) => setAnswers({ ...answers, 'findOutDayTrips': value})} />
          )}

          {questions.indexOf('dayTripTimes') > -1 && (
            <FormInputField type="textarea" id='dayTripTimes' value={answers['dayTripTimes'] || ''} labelName="During what times would you attend a Day Trip?" handleChange={(id, value) => setAnswers({ ...answers, 'dayTripTimes': value})} />
          )}

          {questions.indexOf('dayTripLength') > -1 && (
            <FormInputField type="textarea" id='dayTripLength' value={answers['dayTripLength'] || ''} labelName="How long of a Day Trip would you prefer to attend?" handleChange={(id, value) => setAnswers({ ...answers, 'dayTripLength': value})} />
          )}

          {questions.indexOf('futureDayTrips') > -1 && (
            <FormInputField type="textarea" id='futureDayTrips' value={answers['futureDayTrips'] || ''} labelName="Where would you like to see Day Trips go in the future?" handleChange={(id, value) => setAnswers({ ...answers, 'futureDayTrips': value})} />
          )}
        </div>
      </DialogMatter>
      <DialogMatter open={showPayment} bindState={setShowPayment} size="xs" hideBtnOk={true} hideBtnCancel={true} onCancel={() => {setClientSecret(null)}}>
          {(stripeClientSecret && returnUrl) && (
              <>
                  <h6>Make your payment</h6>
                  <StripePayment clientSecret={stripeClientSecret} connectId={event.club?.clubFieldsSide.stripeConnectId || null} buttonLabel='Pay now' returnUrl={returnUrl} callback={handlePayment} cancel={() => setShowPayment(false)} />
              </>
          )}

          {(transactionSuccess && !status) && (
              <>
                  <h6 className='mb-4'>Your payment is successful</h6>
                  <p className={styles.message}>Please wait while we register you to the event...</p>
              </>
          )}
      </DialogMatter>
    </>
  );
};

export default JoinEvent;
